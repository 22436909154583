import { Button } from "react-bootstrap";
import styled from "styled-components";

interface StyledProps {
  background?: string;
  hoverBackground?: string;
  color?: string;
  border?: string;
  hoverColor?: string;
  fontSize?: string;
  borderColor?: string;
  margin?: string;
}

const StyledButton = styled(Button)<StyledProps>`
  color: ${({ color }) => (color ? color : "#242864")};
  border: ${({ border }) => (border ? border : "2px solid currentColor")};
  border-radius: 2rem;
  display: inline-block;
  font-family: "Lato", sans-serif;
  vertical-align: middle;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "13px")};
  line-height: 1;
  padding: 15px 30px 14px;
  background: ${({ background }) => (background ? background : "transparent")};
  margin: ${({ margin }) => (margin ? margin : null)};
  overflow: hidden;
  text-align: center;
  font-weight: 700;
  &:hover,
  &:active {
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : "#ffffff")};
    background: ${({ hoverBackground }) =>
      hoverBackground ? hoverBackground : "#ff4880"};
    border-color: ${({ borderColor }) =>
      borderColor ? borderColor : "#ff4880"};
  }
`;

export default StyledButton;
