import { Card } from "react-bootstrap";
import Slider from "react-slick";
import styled from "styled-components";
import banner_image from "../../../assests/more/aboutus_banner.png";

export const AboutusFirstSection = styled.section`
  background-image: url(${banner_image});
  width: 100%;
  height: 250px;
  position: relative;
  background-position: 10% 20%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
`;

export const ImageLeftWrapper = styled.div`
  position: relative;
  top: -55px;
  left: 10px;
  z-index: 1;
`;

export const FirstCardWrapper = styled(Card)`
  background: transparent;
  border: none;
  border-radius: none;
  width: 97%;
`;
export const FirstCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  .icon {
    position: absolute;
    top: 40px;
    left: 35px;
    i {
      color: #ff4880;
      font-size: 60px;
    }
  }

  .background {
    position: relative;
    width: 180px;
    height: 103px;
    background-color: #313568;
  }
`;

export const AboutusCardSlider = styled(Slider)`
  height: auto;
  width: 100%;
  .slick-prev {
    left: 19px;
    height: 128px;
    width: 30px;
    background-color: #ff4880;
  }
  .slick-next {
    right: 20px;
    background-color: black;
    height: 128px;
    width: 30px;
    background-color: #ff4880;
  }
`;

export const MemberCardSliderWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  border: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 500px;
  .Borders {
    background-color: #242864;
    position: absolute;
    width: 70px;
    height: 70px;
    top: -14px;
    left: 130px;
    transition: all 0.3s ease;
    z-index: 1;
    @media (max-width: 550px) {
      top: -14px;
      left: 55px;
    }
  }
  &:hover {
    .Borders {
      background-color: #ff4880;
    }
    .title_borders {
      color: #ff4880;
    }
  }
  @media (max-width: 550px) {
    width: auto;
  }
`;

export const SlideImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  width: 300px;
  display: inline-block;
  cursor: pointer;
`;
