import { ChangeEvent, ReactElement, useState } from "react";
import {
  FooterColumn,
  FooterColumn1,
  FooterContainer,
  FooterSecondWrapper,
  FooterFirstWrapper,
  FooterColumnOneContainer,
  FooterBottom,
  FooterBottomContainer,
  FormInput,
  FooterColomnWrappper,
  EmailButton,
} from "./styledcomponents";
import { Images, Subtitles, Title } from "../../pages/styledComponents";
import BabyImages from "../../assests/Footer/footer.jpg";
import { useNavigate } from "react-router";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addEmail } from "../../state/services/api";
import { AppDispatch, RootState } from "../../state/store";
import { IconWrapper } from "../Navbar/styledcomponent";

const Footer = (): ReactElement => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [email, setEmail] = useState("");

  const { forSeniors, forFamilies, forCompanies, more, contactus } =
    language?.header;
  const {
    ourservices,
    ourprices,
    aboutus,
    faq,
    privacypolicy,
    mail1,
    mail2,
    copyr1,
    copyr2,
    h1,
    h2,
    applynow,
    h3,
    subscribe,
  } = language.footer;
  return (
    <>
      <FooterContainer>
        <FooterFirstWrapper>
          <Row>
            <FooterColumn>
              <Title
                fontWeight={500}
                font="22px"
                color="#ffffff"
                lineHeight="36px"
                fontStyle="normal"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {ourservices}
              </Title>
              <Subtitles
                fontFamily="Lato, sans-serif"
                fontWeight={400}
                font="16px"
                color="#ffffff"
                lineHeight="30px"
                fontStyle="normal"
              >
                <span
                  onClick={() => {
                    navigate("/pricesSeniors");
                  }}
                >
                  {forSeniors}
                </span>
                <br />
                <span
                  onClick={() => {
                    navigate("/pricesFamilies");
                  }}
                >
                  {" "}
                  {forFamilies}
                </span>
                <br />
                <span
                  onClick={() => {
                    navigate("/pricesCompanies");
                  }}
                >
                  {forCompanies}
                </span>
                <br />
              </Subtitles>
            </FooterColumn>
            <FooterColumn>
              <Title
                fontWeight={600}
                font="1.3rem"
                color="#ffffff"
                lineHeight="30px"
                fontStyle="normal"
              >
                {ourprices}
              </Title>
              <Subtitles
                fontFamily="Lato, sans-serif"
                fontWeight={400}
                font="16px"
                color="#ffffff"
                lineHeight="30px"
                fontStyle="normal"
              >
                <span
                  onClick={() => {
                    navigate("/pricesSeniors");
                  }}
                >
                  {forSeniors}
                </span>
                <br />
                <span
                  onClick={() => {
                    navigate("/pricesFamilies");
                  }}
                >
                  {" "}
                  {forFamilies}
                </span>
                <br />
                <span
                  onClick={() => {
                    navigate("/pricesCompanies");
                  }}
                >
                  {forCompanies}
                </span>
                <br />
              </Subtitles>
            </FooterColumn>
            <FooterColumn>
              <Title
                fontWeight={600}
                font="1.3rem"
                color="#ffffff"
                lineHeight="30px"
                fontStyle="normal"
              >
                {more}
              </Title>
              <Subtitles
                fontFamily="Lato, sans-serif"
                fontWeight={400}
                font="16px"
                color="#ffffff"
                lineHeight="30px"
                fontStyle="normal"
              >
                <span
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  {aboutus}
                </span>
                <br />
                {/* <span
                  onClick={() => {
                    navigate("/moreNews");
                  }}
                >
                  {" "}
                  {news}
                </span>
                <br /> */}
                <span
                  onClick={() => {
                    navigate("/moreFaq");
                  }}
                >
                  {faq}
                </span>
                <br />
                <span
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                >
                  {privacypolicy}
                </span>
                <br />
                <span
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  {contactus}
                </span>
              </Subtitles>
            </FooterColumn>{" "}
          </Row>
          <Row>
            <FooterColomnWrappper lg={12}>
              <Title
                fontWeight={600}
                font="1.3rem"
                color="#ffffff"
                lineHeight="30px"
                fontStyle="normal"
              >
                {mail1}
              </Title>

              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <FormInput
                  placeholder={mail2}
                  value={email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEmail(e?.target.value);
                  }}
                />
                <EmailButton
                  onClick={() => {
                    dispatch(
                      addEmail({
                        email_id: email,
                      })
                    ).then((f) => {
                      if (f?.payload === "Added") {
                        alert("Thank you for subscribing!");
                      }
                    });
                  }}
                >
                  {subscribe}
                </EmailButton>
              </div>
            </FooterColomnWrappper>
            <FooterColumn> </FooterColumn>
          </Row>
        </FooterFirstWrapper>
        <FooterSecondWrapper>
          <FooterColumn1 lg={4}>
            <FooterColumnOneContainer>
              <Title
                fontWeight={600}
                font="1.3rem"
                color="#ffffff"
                lineHeight="30px"
                fontStyle="normal"
              >
                {h1}
              </Title>
              <Subtitles
                fontFamily=" Lato, sans-serif"
                fontWeight={400}
                font="16px"
                color="#ffffff"
                lineHeight="26px"
                fontStyle="normal"
                paddingTop="15px"
              >
                {h2}{" "}
                <Link style={{ color: "#ff4880" }} to={"/workwithus"}>
                  {applynow}
                </Link>{" "}
                {h3}
              </Subtitles>
              <Images
                src={BabyImages}
                width="90%"
                height="auto"
                className="mt-3"
              />
            </FooterColumnOneContainer>
          </FooterColumn1>{" "}
        </FooterSecondWrapper>
      </FooterContainer>
      <FooterBottom>
        <FooterBottomContainer>
          <div className="copy">kontakt@docare.nu</div>
          <div>
            <IconWrapper>
              <i
                id="twi"
                className="bx bxl-facebook"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/profile.php?id=61557114046934"
                  );
                }}
              ></i>{" "}
              <i
                id="insta"
                className="bx bxl-instagram"
                onClick={() => {
                  window.open("https://www.instagram.com/docare.nu/");
                }}
              ></i>
            </IconWrapper>
          </div>
          <div className="copy">
            {copyr1}
            {copyr2}
          </div>
          {/* <IconWrapper className="mt-3">
            <a href="1#">
              <i
                id="twi"
                className="bx bxl-facebook"
                style={{ marginLeft: "5px" }}
              ></i>
            </a>

            <a href="1#">
              {" "}
              <i id="insta" className="bx bxl-instagram"></i>
            </a>
          </IconWrapper> */}
        </FooterBottomContainer>
      </FooterBottom>
    </>
  );
};

export default Footer;
