import React, { ReactElement } from "react";
import {
  AnchorText,
  ContainerWrapper,
  DetailWrapper,
  NewsCardWrapper,
} from "./styledcomponent";
import {
  Images,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../../styledComponents";
import Imagess from "../../../../assests/News/04.jpg";

const NewsCard = (): ReactElement => {
  return (
    <NewsCardWrapper>
      <Images src={Imagess} width="50%" height="auto" noRadius />
      <ContainerWrapper>
        <DetailWrapper>
          <TitleWrapper>
            <Title
              font="24px"
              fontWeight={400}
              lineHeight="35px"
              color="#242864"
            >
              Get 4 great reasons to use a nanny agency.
            </Title>
          </TitleWrapper>
          <hr />
          <TitleWrapper>
            <Subtitles
              font="15px"
              fontWeight={400}
              lineHeight="24px"
              letterSpacing="0.2px"
              color="#686976"
              fontFamily="Lato sans-serif"
            >
              If a babysitter has a significant amount of child care experience
              and/or has any skills.
            </Subtitles>
          </TitleWrapper>
          <AnchorText>EXPLORE NOW</AnchorText>
        </DetailWrapper>{" "}
      </ContainerWrapper>
    </NewsCardWrapper>
  );
};

export default NewsCard;
