import { ReactElement, useState } from "react";
import {
  FlexWrapper,
  Footer,
  Movetop,
  PageWrapper,
  StyledButton,
} from "../../components";
import { WorkwithusBaner } from "./subcomponent";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../styledComponents";
import Personal from "./Details/personalDetails";
import WorkExperience from "./Details/workExperience";
import EducationDetails from "./Details/educationDetails";
import Availability from "./Details/availability";
import Languages from "./Details/languages";
import OtherDetials from "./Details/others";
import { AccordianStyle } from "../../components/Accordian/styledComponent";
import { Form, Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addWorkwithus } from "../../state/services/api";
import { AppDispatch, RootState } from "../../state/store";
import Loader from "../../components/Loader";
import { updateWorkWithInformationAfterSuccess } from "../../state/services/actions";
import PrivacyPolicy from "../policies/privacy";

const WorkDeatils = (): ReactElement => {
  const { isLoading, language } = useSelector(
    (state: RootState) => ({
      isLoading: state.services.workwithUsLoader,
      language: state.language.language,
    }),
    shallowEqual
  );

  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [privacy, setPrivacy] = useState({
    one: false,
    two: false,
    three: false,
  });
  Movetop();
  const preivacyPolicy = !!privacy?.one && !!privacy?.two && !!privacy?.three;
  const {
    successmsg,
    alertmsg,
    para,
    mypersonalDetials,
    myWorkExperience,
    myEducation,
    myAvailability,
    myLanguageSkills,
    otherInformation,
    workWithUsPara,
    policy,
    remainingPara,
    tickboxOne,
    tickboxTwo,
    tickboxThere,
    submitButton,
  } = language?.workwithus;
  const { workWithUs } = language.header;

  return (
    <PageWrapper>
      <WorkwithusBaner>
        <TitleWrapper margin="0">
          <Title color="#fff" fontWeight={500} font="6rem">
            Do<span>Care</span>
          </Title>
          <Subtitles color="#fff" fontWeight={500} font="2rem">
            {workWithUs}
          </Subtitles>
        </TitleWrapper>
      </WorkwithusBaner>{" "}
      <SectionContainer>
        <SectionWrapper width={"70%"}>
          <TitleWrapper>
            <Subtitles
              font="17px"
              lineHeight="35px"
              color="#002a5e"
              fontFamily="Lato, sans-serif;"
            >
              {para}
            </Subtitles>
          </TitleWrapper>{" "}
          <AccordianStyle>
            <AccordianStyle.Item eventKey="1">
              <AccordianStyle.Header
                onClick={() => {
                  window.scrollTo({ top: 350 });
                }}
              >
                {" "}
                <Title
                  textAlign="left"
                  color="#232158"
                  font="21px"
                  fontWeight={600}
                  lineHeight="35px"
                >
                  {mypersonalDetials}
                </Title>
              </AccordianStyle.Header>
              <AccordianStyle.Body>
                <Personal />
              </AccordianStyle.Body>
            </AccordianStyle.Item>
            <AccordianStyle.Item eventKey="2">
              <AccordianStyle.Header
                onClick={() => {
                  window.scrollTo({ top: 400 });
                }}
              >
                {" "}
                <Title
                  textAlign="left"
                  color="#232158"
                  font="21px"
                  fontWeight={600}
                  lineHeight="35px"
                >
                  {myWorkExperience}
                </Title>
              </AccordianStyle.Header>
              <AccordianStyle.Body>
                <WorkExperience />
              </AccordianStyle.Body>
            </AccordianStyle.Item>{" "}
            <AccordianStyle.Item eventKey="3">
              <AccordianStyle.Header
                onClick={() => {
                  window.scrollTo({ top: 380 });
                }}
              >
                {" "}
                <Title
                  textAlign="left"
                  color="#232158"
                  font="21px"
                  fontWeight={600}
                  lineHeight="35px"
                >
                  {myEducation}
                </Title>
              </AccordianStyle.Header>
              <AccordianStyle.Body>
                <EducationDetails />
              </AccordianStyle.Body>
            </AccordianStyle.Item>{" "}
            <AccordianStyle.Item eventKey="4">
              <AccordianStyle.Header
                onClick={() => {
                  window.scrollTo({ top: 550 });
                }}
              >
                <Title
                  textAlign="left"
                  color="#232158"
                  font="21px"
                  fontWeight={600}
                  lineHeight="35px"
                >
                  {myAvailability}
                </Title>
              </AccordianStyle.Header>
              <AccordianStyle.Body>
                <Availability />
              </AccordianStyle.Body>
            </AccordianStyle.Item>{" "}
            <AccordianStyle.Item eventKey="5">
              <AccordianStyle.Header
                onClick={() => {
                  window.scrollTo({ top: 650 });
                }}
              >
                <Title
                  textAlign="left"
                  color="#232158"
                  font="21px"
                  fontWeight={600}
                  lineHeight="35px"
                >
                  {myLanguageSkills}
                </Title>
              </AccordianStyle.Header>
              <AccordianStyle.Body>
                <Languages />
              </AccordianStyle.Body>
            </AccordianStyle.Item>{" "}
            <AccordianStyle.Item eventKey="6">
              <AccordianStyle.Header
                onClick={() => {
                  window.scrollTo({ top: 700 });
                }}
              >
                <Title
                  textAlign="left"
                  color="#232158"
                  font="21px"
                  fontWeight={600}
                  lineHeight="35px"
                >
                  {otherInformation}
                </Title>
              </AccordianStyle.Header>
              <AccordianStyle.Body>
                <OtherDetials />
              </AccordianStyle.Body>
            </AccordianStyle.Item>
          </AccordianStyle>
          <FlexWrapper noMargin>
            <div>
              <span>
                {workWithUsPara}
                <span
                  onClick={() => {
                    setPrivacyPolicy(!privacyPolicy);
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {policy}
                </span>
                {remainingPara}
              </span>
              <Form.Check
                className="mt-4"
                label={tickboxOne}
                checked={privacy?.one}
                onClick={(e: any) => {
                  setPrivacy({
                    ...privacy,
                    one: e?.target.checked,
                  });
                }}
              />
              <Form.Check
                className="mt-4"
                checked={privacy?.two}
                onClick={(e: any) => {
                  setPrivacy({
                    ...privacy,
                    two: e?.target.checked,
                  });
                }}
                label={tickboxTwo}
              />
              <Form.Check
                className="mt-4"
                checked={privacy?.three}
                onClick={(e: any) => {
                  setPrivacy({
                    ...privacy,
                    three: e?.target.checked,
                  });
                }}
                label={tickboxThere}
              />
            </div>
          </FlexWrapper>
          <FlexWrapper justifyContent="center">
            <StyledButton
              onClick={() => {
                if (!preivacyPolicy) {
                  alert(alertmsg);
                } else {
                  dispatch(addWorkwithus()).then((data) => {
                    if (data?.meta?.requestStatus === "fulfilled") {
                      alert(successmsg);
                      dispatch(updateWorkWithInformationAfterSuccess());
                    } else if (data?.meta?.requestStatus === "rejected") {
                      alert("Failed to create a Register!");
                    }
                  });
                }
              }}
            >
              {isLoading ? (
                <Loader buttonLoader text={"Processing"} />
              ) : (
                submitButton
              )}
            </StyledButton>
          </FlexWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
      <Modal
        show={privacyPolicy}
        size="xl"
        onHide={() => {
          setPrivacyPolicy(false);
        }}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>Privacy Policy</div>
        </Modal.Header>{" "}
        <PrivacyPolicy />
      </Modal>
    </PageWrapper>
  );
};

export default WorkDeatils;
