import { ReactElement } from "react";
import { CardWrapper } from "../../../components/SeniorPriceCards/styledComponents";
import { Images, Subtitles, Title, TitleWrapper } from "../../styledComponents";
import { CardProsp } from "./types";

const Card = ({ title, subTitle, image }: CardProsp): ReactElement => {
  return (
    <CardWrapper borderTop isScale>
      <TitleWrapper height="220px" margin="10px">
        <Title font="22px" lineHeight="32px" fontWeight={500} color="#242864">
          {title}
        </Title>{" "}
        <Subtitles
          font="15px"
          lineHeight="26px"
          fontWeight={400}
          color="#686976"
          letterSpacing="0.3"
        >
          {subTitle}
        </Subtitles>
      </TitleWrapper>
      <Images src={image} width="100%" height="auto" noRadius />
    </CardWrapper>
  );
};

export default Card;
