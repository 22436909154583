import { FlexWrapper, DropdownWrapper, FormData } from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { updateWorkWithInformation } from "../../../state/services/actions";
import { Label } from "../../../components/Dropdown/subcomponents";
import { Form } from "react-bootstrap";
import { ChangeEvent, useEffect, useState } from "react";
import { FileUploader } from "../subcomponent";
import DatePicker from "react-datepicker";
import Tooltip from "@mui/material/Tooltip";
import { YesorNo } from "../const";

const Personal = () => {
  const { values, language } = useSelector(
    (state: RootState) => ({
      values: state.services.workWithDetails,
      language: state.language.language,
    }),
    shallowEqual
  );

  const [preffer, setPreffer] = useState<any>([]);

  const dispatch = useDispatch();
  const [fileType, setfileType] = useState(false);

  useEffect(() => {
    if (preffer?.length) {
      dispatch(
        updateWorkWithInformation({
          prefer_to_work_with: JSON.stringify(preffer),
        })
      );
    }
  }, [preffer, dispatch]);

  const {
    firstName,
    lastName,
    address,
    postCode,
    areaName,
    city,
    contact,
    email,
    dob,
    swadishBankAccnt,
    select,
    preferedToWork,
    Seniors,
    ChildrenFamily,
    location,
    ChildrenSchool,
    decription,
    hobbies,
    currentDoing,
    student,
    typeofstudy,
    hourWeek,
    instituteName,
    upload,
    proof,
    frelencer,
    refired,
    primaryOccupation,
    Specify,
    example,
    areaofStudy,
    dateFormate,
  } = language.workwithus.personalDetails;

  const { YesOrNo } = language.workwithus;

  return (
    <FlexWrapper noMargin noPadding justifyContent="space-between">
      <DropdownWrapper width="45%">
        <FormData
          value={values?.first_name}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                first_name: value,
              })
            );
          }}
          label={firstName}
          isRequired
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.last_name}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                last_name: value,
              })
            );
          }}
          label={lastName}
          isRequired
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.address}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                address: value,
              })
            );
          }}
          label={address}
          isRequired
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.postal_code}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                postal_code: value,
              })
            );
          }}
          label={postCode}
          isRequired
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.area_name}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                area_name: value,
              })
            );
          }}
          label={areaName}
          isRequired
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.city}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                city: value,
              })
            );
          }}
          label={city}
          isRequired
        />
      </DropdownWrapper>
      <DropdownWrapper width="45%">
        <FormData
          value={values?.contact_number}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                contact_number: value,
              })
            );
          }}
          label={contact}
          isRequired
        />
      </DropdownWrapper>
      <DropdownWrapper width="45%">
        <FormData
          value={values?.email}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                email: value,
              })
            );
          }}
          label={email}
          isRequired
        />
      </DropdownWrapper>
      <DropdownWrapper width="45%">
        <Label>{dob}*</Label>
        <DatePicker
          selected={values?.dob}
          dateFormat="yyyy/MM/dd"
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={50}
          scrollableYearDropdown
          placeholderText={dateFormate}
          onChange={(value) => {
            dispatch(
              updateWorkWithInformation({
                dob: value,
              })
            );
          }}
          customInput={<input className="ida" />}
        />
      </DropdownWrapper>
      <DropdownWrapper width="45%">
        <FormData
          value={values?.i_have_a_swedish_bank_account}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                i_have_a_swedish_bank_account: value,
              })
            );
          }}
          label={swadishBankAccnt}
          isRequired
          placeholder={select}
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper>
        <Label>{preferedToWork}</Label>
        <Form.Check
          label={Seniors}
          value={Seniors}
          className="mt-3"
          onClick={(e: any) => {
            setPreffer([...preffer, e?.target.value]);
            if (e?.target.checked === false) {
              setPreffer(
                preffer?.filter((name: any) => name !== e?.target.value)
              );
            }
          }}
        />
        <Form.Check
          label={ChildrenFamily}
          className="mt-2"
          value={ChildrenFamily}
          onClick={(e: any) => {
            setPreffer([...preffer, e?.target.value]);
            if (e?.target.checked === false) {
              setPreffer(
                preffer?.filter((name: any) => name !== e?.target.value)
              );
            }
          }}
        />
        <Form.Check
          label={ChildrenSchool}
          style={{ width: "400px" }}
          className="mt-2"
          value={ChildrenSchool}
          onClick={(e: any) => {
            setPreffer([...preffer, e?.target.value]);
            if (e?.target.checked === false) {
              setPreffer(
                preffer?.filter((name: any) => name !== e?.target.value)
              );
            }
          }}
        />
      </DropdownWrapper>
      <DropdownWrapper width="45%">
        <FormData
          placeholder={example}
          value={values?.preferred_work_with}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                preferred_work_with: value,
              })
            );
          }}
          label={location}
          isRequired
        />
      </DropdownWrapper>
      <DropdownWrapper width="100%">
        <FormData
          value={values.why_work_for_DoCare}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                why_work_for_DoCare: value,
              })
            );
          }}
          multiline
          label={decription}
          isRequired
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="100%" className="mt-5">
        <FormData
          value={values?.interests_and_hobbies}
          multiline
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                interests_and_hobbies: value,
              })
            );
          }}
          label={hobbies}
          isRequired
        />
      </DropdownWrapper>
      <DropdownWrapper width="45%" className="mt-5">
        <FormData
          value={values?.tell_us_what_you_are_currently_doing}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                tell_us_what_you_are_currently_doing: value,
              })
            );
          }}
          label={currentDoing}
          isRequired
          dropDownBanner={student}
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
      {values?.tell_us_what_you_are_currently_doing === YesOrNo[0]?.name && (
        <>
          <DropdownWrapper width="45%" className="mt-5">
            <FormData
              value={values?.type_of_study}
              onChange={(value: string) => {
                dispatch(
                  updateWorkWithInformation({
                    type_of_study: value,
                  })
                );
              }}
              label={typeofstudy}
              isRequired
              dropdownList={areaofStudy}
            />
          </DropdownWrapper>{" "}
          {values?.type_of_study === areaofStudy[1]?.name && (
            <DropdownWrapper width="45%">
              <FormData
                value={values?.per_state_hours_week}
                onChange={(value: string) => {
                  dispatch(
                    updateWorkWithInformation({
                      per_state_hours_week: value,
                    })
                  );
                }}
                label={hourWeek}
                isRequired
              />
            </DropdownWrapper>
          )}
          <DropdownWrapper width="45%">
            <FormData
              value={values?.institution_name}
              onChange={(value: string) => {
                dispatch(
                  updateWorkWithInformation({
                    institution_name: value,
                  })
                );
              }}
              label={instituteName}
              isRequired
            />
          </DropdownWrapper>
          <DropdownWrapper width="45%">
            <Label
              style={
                values?.tell_us_what_you_are_currently_doing ===
                  YesorNo[0]?.name &&
                values?.tell_us_what_you_are_currently_doing === undefined
                  ? { color: "red" }
                  : { color: "#002a5e" }
              }
            >
              {upload}
            </Label>
            <FileUploader
              type="file"
              size={"small"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const files = e?.target?.files?.[0];
                let reader = new FileReader();
                reader.readAsDataURL(files as unknown as Blob);
                if (
                  files?.type !== "application/pdf" &&
                  files?.type !== "image/jpeg"
                ) {
                  setfileType(true);
                } else {
                  setfileType(false);
                }
                reader.onload = () => {
                  dispatch(
                    updateWorkWithInformation({
                      proof_of_your_studies: reader?.result,
                    })
                  );
                };
              }}
            />{" "}
            <Tooltip className="mt-2" title={proof}>
              <i className="bx bx-info-circle"></i>
            </Tooltip>
            {values?.tell_us_what_you_are_currently_doing ===
              YesOrNo[0]?.name &&
            values?.tell_us_what_you_are_currently_doing === undefined ? (
              <span className="typeError">Field is Required</span>
            ) : (
              ""
            )}
            {fileType && (
              <span className="typeError">
                File format should be in PDF, JPEG.
              </span>
            )}
          </DropdownWrapper>
        </>
      )}
      {values?.tell_us_what_you_are_currently_doing === YesOrNo[1]?.name && (
        <>
          <DropdownWrapper width="45%" className="mt-5">
            <FormData
              value={values?.i_am_self_employed_freelancer}
              onChange={(value: string) => {
                dispatch(
                  updateWorkWithInformation({
                    i_am_self_employed_freelancer: value,
                  })
                );
              }}
              label={frelencer}
              isRequired
              dropdownList={YesOrNo}
            />
          </DropdownWrapper>
          <DropdownWrapper width="45%">
            <FormData
              value={values?.i_am_retired_pensioner}
              onChange={(value: string) => {
                dispatch(
                  updateWorkWithInformation({
                    i_am_retired_pensioner: value,
                  })
                );
              }}
              label={refired}
              isRequired
              dropdownList={YesOrNo}
            />
          </DropdownWrapper>{" "}
          <DropdownWrapper width="45%">
            <FormData
              value={values?.i_have_another_primary_occupation}
              onChange={(value: string) => {
                dispatch(
                  updateWorkWithInformation({
                    i_have_another_primary_occupation: value,
                  })
                );
              }}
              label={primaryOccupation}
              isRequired
              dropdownList={YesOrNo}
            />
          </DropdownWrapper>{" "}
          {values?.i_have_another_primary_occupation === YesOrNo[0]?.name && (
            <DropdownWrapper width="45%">
              <FormData
                value={values?.healthcare_name}
                onChange={(value: string) => {
                  dispatch(
                    updateWorkWithInformation({
                      healthcare_name: value,
                    })
                  );
                }}
                label={Specify}
                isRequired
              />
            </DropdownWrapper>
          )}
        </>
      )}
    </FlexWrapper>
  );
};

export default Personal;
