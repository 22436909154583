import React, { ReactElement } from "react";
import styled from "styled-components";
import { Title } from "../../styledComponents";
import {
  ColumnWrapper,
  RowWrapper,
} from "../../Services/subSections/styledcomponents";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

export const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 8%;
  @media (max-width: 600px) {
    text-align: start;
    padding-left: 0px;
    justify-content: flex-start;
  }
`;

export const NumberWrapper = styled.div<{
  fontSize?: string;
  height?: string;
  weight?: string;
  lineHeight?: string;
  absolute?: boolean;
  left?: string;
  top?: string;
  background?: string;
}>`
  display: flex;
  justify-content: center;
  position: ${({ absolute }) => (absolute ? "absolute" : null)};
  top: ${({ top }) => (top ? top : null)};
  left: ${({ left }) => (left ? left : null)};
  z-index: 11;
  span {
    display: inline-block;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
    font-weight: 700;
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "26px")};
    position: relative;
    height: ${({ height }) => (height ? height : "30px")};
    width: ${({ weight }) => (weight ? weight : "30px")};
    border-radius: 50%;
    text-align: center;
    color: #ffff;
    background-color: ${({ background }) =>
      background ? background : "#ff4880"};
    top: 5px;
  }
`;

export const TextTitleWrapper = styled.div`
  padding-left: 20px;
`;

export const SmallCard = (): ReactElement => {
  const {
    language: {
      prices: { company },
    },
  } = useSelector((state: RootState) => ({
    language: state.language.language,
  }));
  const { card1, card2, card3, card4 } = company;
  return (
    <RowWrapper padding>
      <ColumnWrapper lg={3} borderRight>
        {" "}
        <ContainerWrapper>
          <NumberWrapper>
            <span>01</span>
          </NumberWrapper>
          <TextTitleWrapper>
            <Title
              font="18.5px"
              lineHeight="25px"
              letterSpacing="0.2px"
              color="#242864"
              fontWeight={500}
            >
              {card1}
            </Title>
          </TextTitleWrapper>
        </ContainerWrapper>
      </ColumnWrapper>
      <ColumnWrapper lg={3} borderRight>
        {" "}
        <ContainerWrapper>
          <NumberWrapper>
            <span>02</span>
          </NumberWrapper>
          <TextTitleWrapper>
            <Title
              font="18.5px"
              lineHeight="25px"
              letterSpacing="0.2px"
              color="#242864"
              fontWeight={500}
            >
              {card2}
            </Title>
          </TextTitleWrapper>
        </ContainerWrapper>
      </ColumnWrapper>
      <ColumnWrapper lg={3} borderRight>
        {" "}
        <ContainerWrapper>
          <NumberWrapper>
            <span>03</span>
          </NumberWrapper>
          <TextTitleWrapper>
            <Title
              font="18.5px"
              lineHeight="25px"
              letterSpacing="0.2px"
              color="#242864"
              fontWeight={500}
            >
              {card3}
            </Title>
          </TextTitleWrapper>
        </ContainerWrapper>
      </ColumnWrapper>
      <ColumnWrapper lg={3} borderRight>
        {" "}
        <ContainerWrapper>
          <NumberWrapper>
            <span>04</span>
          </NumberWrapper>
          <TextTitleWrapper>
            <Title
              font="18.5px"
              lineHeight="25px"
              letterSpacing="0.2px"
              color="#242864"
              fontWeight={500}
            >
              {card4}
            </Title>
          </TextTitleWrapper>
        </ContainerWrapper>
      </ColumnWrapper>
    </RowWrapper>
  );
};
