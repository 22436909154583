import { ReactElement } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "./helper";
import { CardSlider } from "./styledcomponents";
import SS401 from "../../../assests/prices/seniors/SS4-01.png";
import SS402 from "../../../assests/prices/seniors/SS4-02.png";
import SS403 from "../../../assests/prices/seniors/SS4-03.png";
import SS404 from "../../../assests/prices/seniors/SS4-04.png";
import SS405 from "../../../assests/prices/seniors/SS4-05.png";
import SS406 from "../../../assests/prices/seniors/SS4-06.png";
import FS401 from "../../../assests/prices/families/FS4-01.png";
import FS402 from "../../../assests/prices/families/FS4-02.png";
import FS403 from "../../../assests/prices/families/FS4-03.png";
import FS404 from "../../../assests/prices/families/FS4-04.png";
import FS405 from "../../../assests/prices/families/FS4-05.png";
import FS406 from "../../../assests/prices/families/FS4-06.png";
import { SliderProsp } from "./types";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

const SliderSeniorCare = ({
  isFamilies,
  isSeniors,
}: SliderProsp): ReactElement => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipe: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    language: {
      prices: { families, seniors },
    },
  } = useSelector((state: RootState) => ({
    language: state.language.language,
  }));
  const { familiessection4 } = families;
  const { seniorsection3 } = seniors;
  return (
    <>
      {isSeniors && (
        <CardSlider {...settings}>
          <div>
            <Card
              title={seniorsection3.card.card1title}
              subTitle={seniorsection3.card.card1subtitle}
              image={SS401}
            />
          </div>
          <div>
            <Card
              title={seniorsection3.card.card2title}
              subTitle={seniorsection3.card.card2subtitle}
              image={SS402}
            />
          </div>{" "}
          <div>
            <Card
              title={seniorsection3.card.card3title}
              subTitle={seniorsection3.card.card3subtitle}
              image={SS403}
            />
          </div>{" "}
          <div>
            <Card
              title={seniorsection3.card.card4title}
              subTitle={seniorsection3.card.card4subtitle}
              image={SS404}
            />
          </div>{" "}
          <div>
            <Card
              title={seniorsection3.card.card5title}
              subTitle={seniorsection3.card.card5subtitle}
              image={SS405}
            />
          </div>{" "}
          <div>
            <Card
              title={seniorsection3.card.card6title}
              subTitle={seniorsection3.card.card6subtitle}
              image={SS406}
            />
          </div>
        </CardSlider>
      )}
      {isFamilies && (
        <CardSlider {...settings}>
          <div>
            <Card
              title={familiessection4.card.card1title}
              subTitle={familiessection4.card.card1subtitle}
              image={FS401}
            />
          </div>
          <div>
            <Card
              title={familiessection4.card.card2title}
              subTitle={familiessection4.card.card2subtitle}
              image={FS402}
            />
          </div>{" "}
          <div>
            <Card
              title={familiessection4.card.card3title}
              subTitle={familiessection4.card.card3subtitle}
              image={FS403}
            />
          </div>{" "}
          <div>
            <Card
              title={familiessection4.card.card4title}
              subTitle={familiessection4.card.card4subtitle}
              image={FS404}
            />
          </div>{" "}
          <div>
            <Card
              title={familiessection4.card.card5title}
              subTitle={familiessection4.card.card5subtitle}
              image={FS406}
            />
          </div>{" "}
          <div>
            <Card
              title={familiessection4.card.card6title}
              subTitle={familiessection4.card.card6subtitle}
              image={FS405}
            />
          </div>
        </CardSlider>
      )}
    </>
  );
};

export default SliderSeniorCare;
