import styled from "styled-components";
import NewsImage from "../../../assests/Home/companies.jpg";

export const FirstNewsContainer = styled.div`
  background-image: url(${NewsImage});
  width: 100%;
  height: 250px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const SecondNewsWrapper = styled.div`
  width: 98%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;
