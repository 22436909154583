import { ReactElement, useEffect, useState } from "react";
import { Select } from "./subcomponent";
import { useDispatch } from "react-redux";
import english from "../../locale/eng";
import swedish from "../../locale/se";
import {
  updateLanguage,
  updateSelectedLanguage,
} from "../../state/Language/action";

const LangugeDropdown = (): ReactElement => {
  const [lang, setLang] = useState("SV");

  const dispatch = useDispatch();

  useEffect(() => {
    switch (lang) {
      case "SV":
        dispatch(updateLanguage(swedish));
        dispatch(updateSelectedLanguage("SV"));
        break;
      case "EN":
        dispatch(updateLanguage(english));
        dispatch(updateSelectedLanguage("EN"));
        break;
      default:
        dispatch(updateLanguage(english));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <>
      <Select
        onChange={(e: any) => {
          setLang(e.target.value);
        }}
      >
        <option value="SV" selected>
          SV
        </option>
        <option value="EN">EN</option>
      </Select>
    </>
  );
};

export default LangugeDropdown;
