import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { InputWrapperProps } from "./types";
import fonts, { weight } from "../../const/fonts";
import { colors } from "../../const/theme";
import { MenuItem } from "@mui/material";
import { Tooltip } from "@mui/material";

export const InputWrapper = styled.div<InputWrapperProps>`
  width: ${({ width }) => width || "100%"};
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasPadding }) => (hasPadding ? "5px" : "0")};
  height: ${({ height }) => (height ? height : "70px")};

  font-family: "Playfair Display", serif;
  #outlined-basic {
    ::placeholder {
      font-size: ${fonts.small}px;
      font-weight: ${weight.normal};
      font-family: "Playfair Display", serif;
    }
  }
  @media (max-width: 415px) {
    width: 100%;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    `
pointer-events: none;
opacity: 0.9;
`}
`;

export const FormControl = styled(TextField)<InputWrapperProps>`
  font-family: "Playfair Display", serif;
  font-size: 15px !important;
  color: #002c99 !important;

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    color: ${colors.darkBlue} !important;
    font-family: "Playfair Display", serif;
    height: 30px;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
  .css-1qxw4jt-MuiInputBase-root-MuiOutlinedInput-root {
    color: #002c99 !important;
    font-family: "Playfair Display", serif;
    border-radius: 8px;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    height: ${({ inputHeight }) => (inputHeight ? inputHeight : null)};
    &:hover {
      border: none;
    }
  }
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 30px;
    background: ${({ backGround }) => (backGround ? backGround : null)};
    color: red;
  }
`;

export const MenuItems = styled(MenuItem)`
  color: #002c99 !important;
  font-size: 15px !important;
  font-family: "Playfair Display", serif;
`;

export const Label = styled.label<{ font?: string; weight?: number }>`
  color: #002a5e;
  font-size: ${({ font }) => (font ? font : "14px")};
  font-weight: ${({ weight }) => (weight ? weight : 500)};
  margin-bottom: 0.15rem;
  font-family: "Playfair Display", serif;
`;

export const DropdownBanner = styled.div`
  padding: 3px;
  font-weight: 500;
  color: #021c3c;
  font-size: 15px;
`;

export const Tool = styled(Tooltip)``;
