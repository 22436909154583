import styled from "styled-components";
import companies_banner from "../../../assests/prices/companies/banner.png";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import { Rating } from "react-simple-star-rating";

export const FirstCompanyWrapper = styled.section`
  background-image: url(${companies_banner});
  width: 100%;
  height: 400px;
  position: relative;
  background-size: cover;
`;

export const ThirdCompanyWrapper = styled.section`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 3% 0;
  .title {
    padding-bottom: 20px;
    width: 55%;
    display: flex;
    margin: 0 auto;
  }
`;

export const InsideRowWrapper = styled(Row)`
  margin-right: -200px !important;
  margin-top: 35px;
  margin-left: 0;
  position: relative;
  z-index: 1;
  @media (max-width: 700px) {
    margin-right: 0 !important;
  }
`;

export const InsideColumnWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 0.6px solid #dedede;
`;

export const OverFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  text-align: center;
  padding: 20px 10px;
  margin-bottom: 0;
  margin-right: -1px;
  margin-left: 0;
  overflow: visible;
  #icon {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 25px;
    color: #ff4880;
  }
`;

export const Icons = styled(FontAwesomeIcon)``;

export const FourServiceSection = styled.section`
  width: 100%;
  height: auto;
  background-color: #f9f9f9;
  padding: 20px 0 20px;
`;

//
//Persoanl Slider
//

export const PersoanlSlider = styled(Slider)`
  width: 120%;
  height: auto;
  margin-left: -5%;
  margin-top: -35px;
  z-index: 1;
  position: relative;
  .slick-prev {
    left: 19px;
    height: 128px;
    width: 30px;
    background-color: #ff4880;
  }
  .slick-next {
    right: 20px;
    background-color: black;
    height: 128px;
    width: 30px;
    background-color: #ff4880;
  }
`;

export const PersonalContainer = styled.div`
  background-color: #ff4880;
  padding: 50px 20px;
`;

export const ImageNameWrapper = styled.div`
  display: flex;
  .imageBorder {
    border: 1px solid #ffffff33;
    border-radius: 50%;
    padding: 5px;
    margin-right: 30px;
  }
`;

// export const ImageContainer = styled.div`
//   border-radius: 50%;

//   width: 20px;

// `
export const ProfileImages = styled.img`
  border-radius: 100%;
  object-fit: cover;
  width: 150px;
  height: 150px;
  padding-right: 10px;
`;

export const ProfileNameWrapper = styled.div`
  position: relative;
  padding-left: 50px;
  padding-top: 25px;
  border-left: 4px solid white;
`;

export const Ratings = styled(Rating)<{ margin?: string }>`
  font-size: 10px;
  margin: ${({ margin }) => (margin ? margin : "30px 0")};
`;
