export interface CreateContactProsp {
  first_name: string;
  last_name: string;
  phone: string;
  email_id: string;
  city_name: string;
  enquiry: string;
  describe_in_detail: string;
  docare_news_updates: boolean;
  privacy_policy: boolean;
}

export interface GetTestimonialResponse {
  id: number;
  name: string;
  role: string;
  description: string;
  is_active: number;
  created_at: string;
  updated_at: string;
  profile_pic: string;
  pro_pic: string;
  location: string;
  speciality: string;
}

export interface PostWorkWithUsProsp {
  first_name: string;
  last_name: string;
  address?: string;
  city?: string;
  area_name?: string;
  postal_code?: string;
  contact_number?: string;
  email?: string;
  dob?: Date;
  i_have_a_swedish_bank_account?: string;
  tell_us_what_you_are_currently_doing?: string;
  type_of_study?: string;
  edu_type_of_study?: string;
  per_state_hours_week?: string;
  edu_state_hours_week?: string;
  institution_name?: string;
  proof_of_your_studies?: any;
  preferred_work_with?: string;
  prefer_to_work_with?: any[];
  i_am_self_employed_freelancer?: string;
  i_am_self_employed_freelancer2?: string;
  why_work_for_DoCare?: string;
  i_am_retired_pensioner?: string;
  i_have_another_primary_occupation?: string;
  interests_and_hobbies?: string;
  Specify_personal_details?: string;
  experience_with_children?: string;
  children_work_exp_details?: Array<any>;
  experience_with_elderly?: string;
  studies_or_work_experience_in_healthcare?: string;
  elderly_people_work_exp_details?: Array<any>;
  i_have_studies_and_or_work_experience_in_healthcare?: string;
  healthcare_name?: string;
  other_work_experience?: string;
  other_work_exp_details?: Array<any>;
  i_am_currently_studying?: string;
  education_level?: string;
  name_of_the_institution?: string;
  completed_in?: string;
  can_start_working?: string;
  working_date?: Date | null | any;
  work_for_at_least_6_months?: string;
  lang_Sweedish?: string;
  lang_English?: string;
  i_have__police_clearance_certificate?: string;
  police_clearance_certificate?: string;
  i_agree_to_credit_and_background_check?: string;
  right_to_work_Sweden?: string;
  your_passport?: string;
  work_permit_card?: string;
  valid_driver_licence?: string;
  allergic_to_animals?: string;
  Specify_other_info?: string;
  caregiving_duties?: string;
  how_do_you_hear_about_us?: string;
  available_to_work?: string;
  hours_per_week?: string;
  work_citizin_type?: string;
  education_from?: Date;
  education_to?: Date;
  allergy_details?: string;
  lang_swedish_rate?: number;
  lang_english_rate?: number;
  other_language_details?: any[];
  privacy_policy_agreement?: string;
}

export interface GetPricePlane {
  created_at: string;
  id: string;
  is_active: string;
  name: string;
  plan_price: string;
  plan_type: string;
  comment4: string;
  scheduling: string;
  time_period: string;
  updated_at: string;
  visits: string;
}

export interface InitiaState {
  isSuccess: boolean;
  isLoading: boolean;
  workwithUsLoader: boolean;
  addContactusProsp: CreateContactProsp;
  addContactusResposne: Array<any>;
  getTestimonialListResposne: Array<GetTestimonialResponse>;
  workWithDetails: PostWorkWithUsProsp;
  getPricePlane: Array<GetPricePlane>;
  getTeamMember: Array<GetTestimonialResponse>;
}

export const initialState: InitiaState = {
  isSuccess: false,
  isLoading: false,
  workwithUsLoader: false,
  addContactusProsp: {
    first_name: "",
    last_name: "",
    phone: "",
    email_id: "",
    city_name: "",
    enquiry: "",
    describe_in_detail: "",
    docare_news_updates: false,
    privacy_policy: false,
  },
  addContactusResposne: [],
  getTestimonialListResposne: [],
  workWithDetails: {
    first_name: "",
    last_name: "",
  },
  getPricePlane: [],
  getTeamMember: [],
};
