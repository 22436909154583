import { Button, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavbarWrapper = styled.nav<{ isAnimation?: boolean }>`
  position: fixed;
  z-index: 111;
  background: #242864;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  @media (max-width: 700px) {
    justify-content: space-between;
  }

  ${({ isAnimation }) =>
    isAnimation &&
    `
   box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  animation: headerSlideDown 0.95s ease 0s normal forwards 1 running;
  @keyframes headerSlideDown {
    0% {
      margin-top: -150px;
    }
    100% {
      margin-top: 0;
    }
  }
  `}
`;

export const NavbarLogoWrapper = styled.div`
  min-width: 15%;
  width: 15%;
  position: relative;
  top: 4px;
  cursor: pointer;
  height: auto;
  @media (max-width: 700px) {
    width: 30%;
    min-width: 30%;
    left: 7%;
    top: 1%;
  }
  @media (max-width: 300px) {
    width: 40%;
    min-width: 40%;
  }
`;

export const NavbarLogo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

export const NavBarContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
  margin-right: 1rem;
  margin-top: 1rem;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const HamburgerMenu = styled.i`
  position: relative;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  font-size: 30px;
  height: 40px;
  display: flex;
  justify-items: center;
  text-align: center;
  align-items: center;
  margin-left: 20px;
  @media (min-width: 700px) {
    display: none;
  }
`;

export const MenuItemList = styled.li<any>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 80px;
  i {
    color: ${({ color }) => (color ? "#cc4079" : "white")};
    font-size: 10px;
    opacity: 0.8;
    transition: all 0.3s ease 0s;
    &:hover,
    &:active,
    &:focus {
      transition: all 0.3s ease 0s;
      color: #cc4079;
    }
  }
  @media (max-width: 400px) {
    height: 20px;
  }
`;

export const Anchor = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? "#ff4880" : "white")};
  text-decoration: none;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  padding: 0px 18px;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  &:hover,
  &:active,
  &:focus {
    transition: all 0.3s ease 0s;
    color: #ff4880;
    transition: all 0.3s;
    line-height: 83px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    line-height: 0px;
  }
`;

export const DropdownWrapper = styled.ul`
  width: auto;
  position: absolute;
  top: 70px;
  margin-left: 16px;
  z-index: 1000;
  list-style: none;
  text-align: start;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
`;

export const DropdownMenuList = styled.li`
  background: #fff;
  margin-right: 20px;
  margin-top: 5px;
  position: relative;
  left: -22px;
  margin-bottom: 6px;
  cursor: pointer;
  &:hover {
    border-radius: 3px;
  }
`;

export const DropdownAnchor = styled(Link)`
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #242864;
  font-weight: 500;
  font-family: "Playfair Display", serif;
  font-size: 16px;
  line-height: 25px;
  padding: 5px 10px 10px;
  &:hover {
    transition: all 0.5s;
    color: #ff4880;
  }
`;

export const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 13px;
  margin: auto 5px;
  border: none;
  font-family: "Playfair Display", serif;
  border-radius: 26px;
  background: #f54881;
  font-style: normal;
  transition: all 0.3s ease 0s;
  &:hover {
    background: #fc79a4;
  }
`;

export const IconWrapper = styled.div`
  a {
    text-decoration: none;
  }
  i {
    width: 32px;
    height: 32px;
    display: inline-flex;
    border-radius: 50%;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 30px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      background-color: #ff4880;
      border: none;
      transition: all 0.5s ease;
      color: white;
    }
  }
`;

export const ButtonWrappers = styled.div`
  display: flex;
  font-family: "Playfair Display", serif;
  gap: 20px;
  align-items: center;
`;

export const CanvaContianer = styled.div`
  position: relative;

  @media (min-width: 700px) {
    display: none;
  }
`;

export const Offcanva = styled(Offcanvas)`
  background: #242864;
  color: #ff4880;
  font-size: 25px;
  font-weight: 500;
  .offcanvas-header .btn-close {
    filter: contrast(100);
  }
`;

export const MobileNavContainer = styled.div`
  position: relative;
  background: #242864;
`;

export const MobileNavWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: start;
  list-style: none;
`;

export const MobileList = styled.li`
  padding: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

export const MobileAnchorText = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? "#ff4880" : "#ffffff")};

  text-decoration: none;
  position: relative;
  font-weight: 600;
  font-style: normal;
  border-bottom: ${({ isActive }) => (isActive ? "1px solid #ff4880" : "none")};
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 15px;
  text-transform: uppercase;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  &:hover {
    transition: all 0.2s;
    color: #ff4880;
    background-color: transparent;
    i {
      color: #ff4880;
    }
  }
  i {
    font-size: 17px;
    font-weight: 900;
    margin-top: -2px;
    vertical-align: middle;
  }
`;

export const MobileSubAnchorText = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? "#ff4880" : "#ffffff")};
  text-decoration: none;
  position: relative;
  font-weight: 400;
  font-style: normal;
  border-bottom: ${({ isActive }) => (isActive ? "1px solid #ff4880" : "none")};
  padding: 0;
  padding-left: 10px;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  &:hover {
    transition: all 0.2s;
    color: #ff4880;
    background-color: transparent;
    i {
      color: #ff4880;
    }
  }
  i {
    font-size: 12px;
    font-weight: 400;
    margin-top: -2px;
    vertical-align: middle;
  }
`;
