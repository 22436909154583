import { ChangeEvent, ReactElement } from "react";
import {
  DropdownBanner,
  FormControl,
  InputWrapper,
  Label,
  Tool,
  MenuItems,
} from "./subcomponents";
import { InputProps } from "./types";
import { colors } from "../../const/theme";

const Dropdown = ({
  label,
  innerLabel,
  value,
  name,
  onChange,
  onBlur,
  isDisabled,
  isRequired,
  width,
  height,
  color,
  error,
  inputType,
  focused,
  inputHeight,
  inputPlaceholderMargin,
  dropdownList,
  size,
  multiline,
  backGround,
  dropDownBanner,
  tooltip,
  defaultValue,
  placeholder,
}: InputProps): ReactElement => {
  return (
    <InputWrapper isDisabled={isDisabled} width={width} height={height}>
      <Label className="mb-1" font="15px">
        {label}
        {isRequired && "*"}{" "}
        {tooltip && (
          <Tool title={tooltip}>
            <i className="bx bx-info-circle"></i>
          </Tool>
        )}
      </Label>{" "}
      <FormControl
        placeholder={placeholder}
        backGround={backGround}
        id="outlined-basic"
        variant="outlined"
        autoComplete="off"
        defaultValue={defaultValue}
        size={size ? size : "small"}
        inputHeight={inputHeight}
        select={!!dropdownList}
        inputPlaceholderMargin={inputPlaceholderMargin}
        inputProps={{
          input: {
            color: colors.darkBlue,
            fontFamily: "Montserrat",
            fontSize: "15px",
          },
        }}
        color={color}
        rows={3}
        multiline={multiline}
        InputLabelProps={{
          style: {
            fontFamily: "Montserrat",
            color: colors.darkBlue,
            fontSize: "15px",
          },
        }}
        type={inputType || "text"}
        value={value}
        label={innerLabel}
        name={name || ""}
        focused={focused}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange && onChange(e.target.value);
        }}
        error={!!error}
        onBlur={(e: any) => onBlur && onBlur(e.target.value)}
      >
        <DropdownBanner>{dropDownBanner}</DropdownBanner>
        {dropdownList?.map((option) => (
          <MenuItems
            sx={{
              input: {
                color: colors.darkBlue,
                fontFamily: "Montserrat",
                fontSize: "15px",
              },
              label: {
                color: colors.darkBlue,
                fontFamily: "Montserrat",
                fontSize: "15px",
              },

              width: "100%",
              fontSize: "15px",
              color: "#002c99",
              fontFamily: "Montserrat",
            }}
            key={option.id}
            value={option.name}
          >
            {option.name}
          </MenuItems>
        ))}
      </FormControl>
    </InputWrapper>
  );
};

export default Dropdown;
