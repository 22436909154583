/* eslint-disable jsx-a11y/anchor-is-valid */
import { Accordion } from "react-bootstrap";
import {
  AccordianHeader,
  AccordianStyle,
  AccordianItem,
} from "./styledComponent";
import { AccordianProps } from "./types";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../state/store";

const Accordian = ({ data, module }: AccordianProps) => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  const {
    ForCompaniesQ1,
    ForCompaniesQ1ans1,
    ForCompaniesQ1ans2,
    ForCompaniesQ1ans3,
    ForCompaniesQ2,
    ForCompaniesQ2ans1,
    ForCompaniesQ2ans2,
    ForCompaniesQ2ans3,
    ForCompaniesQ3,
    ForCompaniesQ3ans1,
    ForCompaniesQ4,
    ForCompaniesQ4ans1,
    ForCompaniesQ4ans2,
    ForCompaniesQ4ans3,
    ForCompanies5,
    ForCompaniesQ5ans1,
    ForCompaniesQ6,
    ForCompaniesQ6ans1,
    ForCompaniesQ6ans2,
    ForCompaniesQ6ans3,
  } = language?.faq;
  return (
    <AccordianStyle defaultActiveKey="1">
      {data?.map((lists, index) => {
        return (
          <AccordianItem eventKey={index} key={index}>
            <AccordianHeader>
              <a>{lists?.question}</a>
            </AccordianHeader>
            <Accordion.Body>
              <p>{lists?.answer}</p>
            </Accordion.Body>
          </AccordianItem>
        );
      })}
      {module !== "companies" && (
        <AccordianItem>
          <AccordianHeader>
            <a>{ForCompaniesQ1}</a>
          </AccordianHeader>
          <Accordion.Body>
            <p>
              {ForCompaniesQ1ans1}{" "}
              <a href="https://skatteverket.se/privat/fastigheterochbostad/rotarbeteochrutarbete.4.2e56d4ba1202f95012080002966.html">
                {ForCompaniesQ1ans2}
              </a>{" "}
              {ForCompaniesQ1ans3}
            </p>
          </Accordion.Body>
        </AccordianItem>
      )}
      {module === "companies" && (
        <>
          <AccordianItem>
            <AccordianHeader>
              <a>{ForCompaniesQ2}</a>
            </AccordianHeader>
            <Accordion.Body>
              <p>
                {ForCompaniesQ2ans1}
                <a href="/contact"> {ForCompaniesQ2ans2}</a>{" "}
                {ForCompaniesQ2ans3}
              </p>
            </Accordion.Body>
          </AccordianItem>
          <AccordianItem eventKey={"2"}>
            <AccordianHeader>
              <a>{ForCompaniesQ3}</a>
            </AccordianHeader>
            <Accordion.Body>
              <p>{ForCompaniesQ3ans1}</p>
            </Accordion.Body>
          </AccordianItem>
          <AccordianItem eventKey={"3"}>
            <AccordianHeader>
              <a>{ForCompaniesQ4}</a>
            </AccordianHeader>
            <Accordion.Body>
              <p>
                {ForCompaniesQ4ans1}
                <a href="/contact"> {ForCompaniesQ4ans2} </a>{" "}
                {ForCompaniesQ4ans3}
              </p>
            </Accordion.Body>
          </AccordianItem>
          <AccordianItem eventKey={"4"}>
            <AccordianHeader>
              <a>{ForCompanies5}</a>
            </AccordianHeader>
            <Accordion.Body>
              <p>{ForCompaniesQ5ans1}</p>
            </Accordion.Body>
          </AccordianItem>
          <AccordianItem eventKey={"5"}>
            <AccordianHeader>
              <a>{ForCompaniesQ6}</a>
            </AccordianHeader>
            <Accordion.Body>
              <p>
                {ForCompaniesQ6ans1}{" "}
                <a href="/contact"> {ForCompaniesQ6ans2} </a>{" "}
                {ForCompaniesQ6ans3}
              </p>
            </Accordion.Body>
          </AccordianItem>
        </>
      )}
    </AccordianStyle>
  );
};

export default Accordian;
