import { useState } from "react";
import {
  DropdownMenuList,
  DropdownWrapper,
  DropdownAnchor,
} from "./styledcomponent";
import { DropdownProsp, Menu } from "./types";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../state/store";

const Dropdown = ({ dropdownName }: DropdownProsp) => {
  const {
    header: { priceDropdowns, moreDropdowns },
  } = useSelector(
    (state: RootState) => ({
      header: state.language.language.header,
    }),
    shallowEqual
  );

  const [click, setClick] = useState(false);

  const determineDropdown = (dropdownName: string): Array<Menu> => {
    switch (dropdownName) {
      case "price":
        return priceDropdowns as Array<Menu>;
      case "more":
        return moreDropdowns as Array<Menu>;
      default:
        return [];
    }
  };
  return (
    <>
      <DropdownWrapper
        onClick={() => {
          setClick(!click);
        }}
      >
        {determineDropdown(dropdownName)?.map((item, index) => {
          return (
            <DropdownMenuList key={index}>
              <DropdownAnchor to={item?.to} onClick={() => setClick(false)}>
                {item?.title}
              </DropdownAnchor>
            </DropdownMenuList>
          );
        })}
      </DropdownWrapper>
    </>
  );
};

export default Dropdown;
