import styled from "styled-components";

interface DropdownWrapperProps {
  width?: string;
  margin?: boolean;
  marginTop?: string;
  marginBottom?: string;
}

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  margin: ${({ margin }) => (margin ? "0 12px 0" : null)};
  width: ${({ width }) => width || "22%"};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "5px")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "30px"};
  @media (max-width: 550px) {
    width: 100%;
  }

  #editable-dropdown {
    height: 100%;
  }
  .form-check-label {
    font-size: 13px;
    font-weight: 500;
  }
  .typeError {
    color: red;
    position: relative;
    top: -20px;
    font-size: 13px;
    width: 100%;
    display: flex;
  }
  .ida {
    border-radius: 10px;
    border-width: 1px;
    width: 410px;
    height: 45px;
    border-color: #dee2e6;
    font-family: "Playfair Display", serif;
    color: #002c99 !important;
    font-size: 17px !important;
    &::placeholder {
      color: #002c99 !important;
      font-size: 15px !important;
      font-family: "Playfair Display", serif;
    }
    &:focus {
      box-shadow: none;
      border: none;
    }
    @media (max-width: 550px) {
      width: 300px;
    }
  }
`;

export default DropdownWrapper;
