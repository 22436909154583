import React, { ReactElement } from "react";
import { PageWrapper } from "../../../../components";
import {
  ImageBackgroundReducer,
  Images,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../../styledComponents";
import {
  FirstServiceSection,
  TextWrapper,
} from "../../../Prices/seniors/styledcomponents";
import {
  ColumnWrapper,
  RowWrapper,
} from "../../../Services/subSections/styledcomponents";
import { useLocation } from "react-router";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../../state/store";

const TeamMember = (): ReactElement => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  const location = useLocation();
  const memberDetails = location.state.memberDetails;
  const { MeettheDoCareteam } = language?.Aboutus;

  return (
    <PageWrapper>
      <FirstServiceSection>
        <ImageBackgroundReducer />
        <TextWrapper>
          <Title
            fontFamily="Playfair Display, serif"
            fontWeight={400}
            font="58px"
            color="#ffffff"
            fontStyle="normal"
          >
            {MeettheDoCareteam}
          </Title>
        </TextWrapper>
      </FirstServiceSection>
      <SectionContainer>
        <SectionWrapper>
          <RowWrapper>
            <ColumnWrapper lg={6}>
              <Images
                src={`https://api.dohr.io/${memberDetails?.profile_pic}`}
                width="90%"
                noRadius
              />
            </ColumnWrapper>
            <ColumnWrapper lg={6}>
              {" "}
              <TitleWrapper paddingLeft="0px">
                <Title
                  font="36px"
                  lineHeight="46px"
                  color="#242864"
                  fontWeight={500}
                >
                  {memberDetails?.name}
                </Title>
                <Subtitles
                  font="18px"
                  lineHeight="24px"
                  color="#686976"
                  fontFamily="Lato, sans-serif"
                  fontWeight={500}
                  className="mt-3"
                >
                  {memberDetails?.role}
                </Subtitles>
                <Subtitles
                  font="16px"
                  lineHeight="26px"
                  color="#686976"
                  fontFamily="Lato, sans-serif"
                  fontWeight={400}
                  className="mt-3"
                >
                  {memberDetails?.description}
                </Subtitles>
                <Subtitles
                  font="16px"
                  lineHeight="26px"
                  color="#686976"
                  fontFamily="Lato, sans-serif"
                  fontWeight={400}
                  className="mt-3"
                >
                  {memberDetails?.speciality}
                </Subtitles>
              </TitleWrapper>
            </ColumnWrapper>
          </RowWrapper>
        </SectionWrapper>
      </SectionContainer>
    </PageWrapper>
  );
};

export default TeamMember;
