import { ReactElement } from "react";
import {
  Footer,
  Movetop,
  PageWrapper,
  StyledButton,
} from "../../../components";
import { TextWrapper } from "../../Prices/seniors/styledcomponents";
import {
  ImageBackgroundReducer,
  Images,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../styledComponents";
import {
  ColumnWrapper,
  RowWrapper,
} from "../../Services/subSections/styledcomponents";
import aboutus_sectionImage from "../../../assests/more/aboutuu_section_01.jpg";
import { ImageLeftWrapper, AboutusFirstSection } from "./subcomponents";
import AboutusCard from "./helpers";
import AboutTeamSlider from "./cardSlider";
import { useNavigate } from "react-router";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../state/store";

const Aboutus = (): ReactElement => {
  const {
    language: { language },
    transulation,
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
      transulation: state.language.selectedLang,
    }),
    shallowEqual
  );
  const navigate = useNavigate();

  Movetop();

  const {
    title,
    title1,
    title2,
    cardtttle,
    cardp1,
    ForSeniors,
    ForSeniorsp1,
    ForFamilies,
    ForFamiliesp1,
    DoCareteam,
    Becomepartof,
    cardp2,
    helper,
    Asa,
    helper1,
    helper2,
    helper3,
    helper4,
    helper5,
    Applynow,
  } = language?.Aboutus;
  const { docare } = language?.header;

  return (
    <PageWrapper>
      <AboutusFirstSection>
        <ImageBackgroundReducer />
        <TextWrapper>
          <Title
            fontFamily="Playfair Display, serif"
            fontWeight={400}
            font="58px"
            color="#ffffff"
            fontStyle="normal"
            mFontStyle="250%"
          >
            {transulation === "EN" ? (
              <>
                <span style={{ color: "#ff4880" }}>{docare}</span>
                {title}
              </>
            ) : (
              <>
                {title1}
                <span style={{ color: "#ff4880" }}>{docare}</span>
                {title2}
              </>
            )}
          </Title>
        </TextWrapper>
      </AboutusFirstSection>
      <SectionContainer>
        <SectionWrapper width="100%">
          <RowWrapper noMargin padding="0" className="mt-5">
            <ColumnWrapper lg={5}>
              <ImageLeftWrapper>
                <Images
                  src={aboutus_sectionImage}
                  width="650px"
                  height="575px"
                  noRadius
                />{" "}
              </ImageLeftWrapper>
            </ColumnWrapper>
            <ColumnWrapper background="#242864" lg={7}>
              <TitleWrapper
                paddingLeft="120px"
                mPaddingleft="0"
                className="mt-5"
              >
                <Title
                  font="48px"
                  fontWeight={500}
                  lineHeight="66px"
                  color="#fff"
                >
                  {cardtttle}
                </Title>
                <Subtitles
                  font="16px"
                  fontWeight={400}
                  fontFamily="Lato, sans-serif"
                  lineHeight="26px"
                  color="#fff"
                  letterSpacing="0.2px"
                  className="mt-3"
                >
                  {cardp1}
                </Subtitles>
                <AboutusCard
                  icon="bx bxs-lock-open"
                  title={ForSeniors}
                  subTitle={ForSeniorsp1}
                />{" "}
                <AboutusCard
                  icon="bx bxs-lock-open"
                  title={ForFamilies}
                  subTitle={ForFamiliesp1}
                />
              </TitleWrapper>
            </ColumnWrapper>
          </RowWrapper>
        </SectionWrapper>
      </SectionContainer>
      <SectionContainer>
        <SectionWrapper>
          <TitleWrapper>
            <Title
              font="52px"
              fontWeight={500}
              lineHeight="66px"
              color="#242864"
            >
              {Becomepartof} <span>{DoCareteam}</span>
            </Title>
            <Subtitles
              className="mt-4 mb-4"
              font="16px"
              fontWeight={400}
              lineHeight="26px"
              letterSpacing="0.2px"
              color="#686976"
            >
              {cardp2}{" "}
            </Subtitles>
          </TitleWrapper>
          <AboutTeamSlider />
        </SectionWrapper>
      </SectionContainer>
      <SectionContainer>
        <SectionWrapper>
          <TitleWrapper paddingLeft="20px" margin="-40px 0 0">
            <Title
              font="35px"
              fontWeight={500}
              color="#242864"
              lineHeight="70px"
            >
              {Asa} <span>{helper}</span>
            </Title>
            <Subtitles
              fontFamily="Lato, sans-serif"
              fontWeight={400}
              font="16px"
              color="#686976"
              lineHeight="26px"
              fontStyle="normal"
            >
              {helper1}
              <br />
              <br />
              {helper2}
              <br />
              <br />
              {helper3}
              <br />
              <br />
              {helper4}
              <br />
              <br />
              {helper5}
            </Subtitles>{" "}
            <div>
              <StyledButton
                onClick={() => {
                  navigate("/workwithus");
                }}
                background="#ff4880"
                color={"#fff"}
                border="none"
                className="mt-2"
              >
                {Applynow}
              </StyledButton>
            </div>
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Aboutus;
