import styled from "styled-components";
import { WrapperProsp, FontProsp, ImagesProps } from "./Services/types";

export const SectionContainer = styled.section<{ background?: string }>`
  width: 100%;
  height: auto;
  background-color: ${({ background }) => (background ? background : "#fff")};
  padding: 30px 0;
`;

export const SectionWrapper = styled.div<{ width?: string; mWidth?: string }>`
  margin: 0 auto;
  max-width: 1500px;
  height: auto;
  width: ${({ width }) => (width ? width : "92%")};
  .borders {
    border-left: 5px solid #ff6100;
    border-radius: 3px;
    padding-right: 10px;
  }
  @media (max-width: 550px) {
    width: ${({ mWidth }) => (mWidth ? mWidth : "95%")};
  }
`;

export const TitleWrapper = styled.div<WrapperProsp>`
  margin: ${({ margin }) => (margin ? margin : "20px 0")};
  display: flex;
  height: ${({ height }) => (height ? height : null)};
  flex-direction: column;
  align-items: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : "center")};
  text-align: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : "center")};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : null)};
  @media (max-width: 550px) {
    padding-left: ${({ mPaddingleft }) => (mPaddingleft ? mPaddingleft : null)};
  }
`;

export const Title = styled.h1<FontProsp>`
  font-size: ${({ font }) => (font ? font : `36px`)};
  position: relative;
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
  span {
    color: #ff4880;
    font-style: italic;
  }
  .job {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: #686976;
  }
  @media (max-width: 550px) {
    font-size: ${({ mFontStyle }) => (mFontStyle ? mFontStyle : null)};
  }
`;

export const Subtitles = styled.p<FontProsp>`
  font-size: ${({ font }) => (font ? font : null)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? letterSpacing : null};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : null)};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
  @media (max-width: 850px) {
    letter-spacing: 0px;
    margin: 0px;
  }
  span {
    cursor: pointer;
  }
`;

export const StyledText = styled.h4<FontProsp>`
  font-size: ${({ font }) => (font ? font : null)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
`;

export const Images = styled.img<ImagesProps>`
  max-width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : null)};
  border-radius: ${({ noRadius }) => (noRadius ? "0" : "5px")};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
`;

export const ImageBackgroundReducer = styled.div`
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.52;
`;
