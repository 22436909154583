const english = {
  header: {
    docare: "DoCare",
    services: "Services",
    prices: "Prices",
    more: "More",
    contactus: "Contact us",
    workWithUs: "Work with us",
    forSeniors: "For Seniors",
    forFamilies: "For Families",
    forCompanies: "For Companies",

    priceDropdowns: [
      {
        title: "For Seniors",
        to: "/pricesSeniors",
      },
      {
        title: "For Families",
        to: "/pricesFamilies",
      },
      {
        title: "For Companies",
        to: "/pricesCompanies",
      },
    ],
    moreDropdowns: [
      {
        title: "About us",
        to: "/about-us",
      },

      {
        title: "FAQ",
        to: "/moreFaq",
      },
    ],
    mobile: [
      {
        title: "Services",
        to: "/",
      },
      {
        title: "Prices",
        to: "",
        childs: [
          {
            title: "For Seniors",
            to: "/pricesSeniors",
          },
          {
            title: "For Families",
            to: "/pricesFamilies",
          },
          {
            title: "For Companies",
            to: "/pricesCompanies",
          },
        ],
      },
      {
        title: "More",
        to: "",
        childs: [
          {
            title: "About us",
            to: "/about-us",
          },

          {
            title: "FAQ",
            to: "/moreFaq",
          },
        ],
      },
      {
        title: "Contact us",
        to: "/contact",
      },
      {
        title: "Work with us",
        to: "/workwithus",
      },
    ],
  },
  footer: {
    ourservices: "Our Services",
    ourprices: "Our Prices",
    aboutus: "About us",
    news: "News",
    faq: "FAQ",
    privacypolicy: "Privacy policy",
    mail1: "Stay updated with our news! ",
    mail2: "Enter email address",
    mail3: "",
    copyr1: "Copyright 2024.",
    copyr2: " Pictures freepik ",
    h1: "Are you looking for extra work? ",
    h2: "If this sounds like you",
    applynow: "apply now",
    h3: "to register your interest in becoming a part of DoCare team",
    subscribe: "Subscribe",
  },
  service: {
    h1: "Whether it is for you or your loved ones",
    h2: "We make it simple to find the ",
    h3: "right help",
    h4: "Our customer",
    h5: " testimonials",
    h6: "We always customise our services to meet customer needs!",
  },
  forseniors: {
    h1: "We think our elderly deserve the very best care!",
    h2: "DoCare uses a personal approach to pair you with a competent and dedicated assistant. We provide your family with peace of mind and accommodate those who require additional help for their elderly parents, or seniors who desire extra help with...",
    h3: " — Household tasks",
    h4: "— Grocery shopping and cooking",
    h5: " — Help with running errands",
    h6: "— Outdoor activities and companionship",
    h7: "— Simple technical support",
    h8: "— Primary (relative) carer",
    h9: "Our goal is to provide personalised and affordable help.",
    h10: " LEARN MORE ABOUT OUR PRICES.",
  },
  forfamilies: {
    h11: "We solve the puzzle of everyday family life!",
    h12: "DoCare takes a personalised approach to matching you with a competent and caring nanny or family helper who meets your needs. Whether you are a parent searching for support, a family looking for a long-term helping hand, or someone in need of short-term assistance, we provide your family with peace of mind and accommodate your family needs with...",
    h13: " — Homework assistance on a regular basis",
    h14: "— Babysitting",
    h15: " — Simple household tasks",
    h16: ` —  Dropping off and/or picking up kids from 
      pre-school/school/activities`,
    h17: "A nanny with specific language skills",
    h18: "Grocery shopping and cooking",
  },
  forcompanies: {
    h21: " We help companies create a better work-life balance!",
    h22: "Our services help to create a positive work environment and increase employee productivity and loyalty.",
    h23: "—Provide elderly care and nanny/babysitting services as an employee benefit",
    h24: "—Choose between several DoCare benefit programs",
    h25: "—Offer our services throughout working hours, during special events, or during business trips or conferences",
    h26: "—Show that you care about the family and well-being of your employees by giving them access to DoCare services",
  },
  prices: {
    seniors: {
      section1: {
        title1: "We",
        title2: "for the elderly!",
      },
      section2: {
        s2t1: "DoCare offers the most competitive",
        s2t2: "pricing plan for elderly care",
        subtitle:
          "Get the best personalised care that aligns with your family values and preferences while being flexible and cost-effective.",
        cardHours: "per hour",
        constactUs: "Contact us",
        vipCard: [
          {
            border: true,
            text: "Dedicated helper",
          },
          {
            border: true,
            text: "1-25 visits/week",
          },
          {
            border: true,
            text: "Get 2 hours for free after every 3 months",
          },
          {
            border: false,
            text: "Free rescheduling up to 24 hours",
          },
        ],
        basicCard: [
          {
            border: true,
            text: "Dedicated helper",
          },
          {
            border: true,
            text: "Minimum 1 visit/month",
          },
          {
            border: true,
            text: "Three-months commitment period",
          },
          {
            border: false,
            text: "Free rescheduling up to 24 hours",
          },
        ],
        payCard: [
          {
            border: true,
            text: "Dedicated helper**",
          },
          {
            border: true,
            text: "No minimum visits",
          },
          {
            border: true,
            text: "No commitment period",
          },
          {
            border: false,
            text: "Free cancellation up to 48 hours",
          },
        ],
        footer1:
          "*All prices including VAT and after RUT-avdrag. The minimum length of a visit is 1.5 hours. Predetermined schedule and a free test visit applies only for Basic and VIP price plans.",
        footer2:
          " *The same person cannot be guaranteed for pay as you go plan.",
        contactUs: "Contact us",
        giftVoucher: "to purchase DoCare gift voucher!",
        perHour: "per hour",
        Refer: "Refer",
        HundredKR: "100kr/100kr",
        friend: "a friend",
        discountPara:
          "With every successful referral, we give you 100kr discount, and your friends receive 100kr discount on their first order. ",

        discountPara2: "for a referral code.",
      },
      seniorsection3: {
        seniorst1: "We help elderly in living fulfilling lives through ",
        seniorst2: "our services",
        seniorst3: "which include...",
        card: {
          card1title: "Help with daily routines",
          card1subtitle:
            "Our dedicated helper can assist with cleaning, other light household tasks, and hygiene-related duties like changing bed linens, doing laundry, ironing, etc. Additionally, the helper can also assist with cooking, meal planning and grocery shopping.",
          card2title: "Help with running errands",
          card2subtitle:
            "Running errands, picking up deliveries, going shopping are made easy by our dedicated helper. Additionally, helper can also accompany your loved ones to a doctor's appointment or with transportation.",
          card3title: "Physical and mental well-being",
          card3subtitle:
            "Going out alone won't be as challenging for elderly with the assistance of a dedicated helper. We can help them improve their physical and mental well-being by taking them on short walks, engaging in memory-activating activities, or simply dancing to music.",
          card4title: "Technical help",
          card4subtitle:
            "Our dedicated helper can assist with technology, including the installation or use of smart phones, smart TVs, setting up channels, organising digital media, and creating and maintaining social media accounts. They can also help with downloading audio books or mobile apps.",
          card5title: "Conversation and companionship",
          card5subtitle:
            "A dedicated helper can simply provide a company, a sympathetic ear, read out load a book, or have fika to make the everyday more fun. They can assist your loved ones with their interests, such as painting, crosswords, or card games, or they can just inspire to start a new pastime activity. Additionally, our helper can aid with simple movie outings or cultural activities.",
          card6title: "Help primary (relative) carer",
          card6subtitle:
            "Being the primary carer can be demanding, so having support can be really helpful. Our dedicated helper offers relief help to a family member caring for an elderly.",
        },
      },
      seniorssection4: {
        header1: "How it works....",
        header2:
          "We believe it is important that your needs are being met by professional people at every stage of your DoCare journey!",
        cardtitle1: "Identify your need",
        cardtext1:
          "We help you in selecting the plan that best meets your needs.",
        cardtitle2: "Matching with helper",
        cardtext2:
          "We will find you a suitable helper who is competent to provide the appropriate care.",
        cardtitle3: "Free first meet and greet test visit",
        cardtext3:
          "Senior/family meets the assigned helper for the first time to get acquianted.",
        cardtitle4: "Follow up and get started",
        cardtext4:
          "We follow up on your test visit and decide on how we go forward.",
      },
    },
    families: {
      title: "solves the puzzle of daily family life! ",
      familiessection1: {
        s1t1: " DoCare offers the most competitive",
        s1t2: "pricing plan for families",
        s1t3: "Get the best personalised nanny/babysitting services that align with your family values and preferences while being flexible and cost-effective.",
      },
      familiessection2: {
        s2t1: "*All prices including VAT and after RUT-avdrag. The minimum length of a visit is 1.5 hours. Predetermined schedule and a free test visit applies only for Basic and VIP price plans.",
        s2t2: "**The same person cannot be guaranteed for pay as you go plan.",
        s2t3: "Contact us",
        s2t4: "to purchase DoCare gift voucher!",
      },
      familiessection3: {
        header:
          "Are you thinking about getting a tax reduction for our services (Rutavdrag)...",
        s3t1: "Although you may be reimbursed for our services, it is your obligation to make sure that you check the personal maximum amount for rutavdrag, allocated by the Swedish tax department. The Swedish Tax Agency's website allows you to check the amount of the reduction that may be applied to our services.",
        s3t2: "If you have already used up your entire Rutavdrag deduction amount, please let us know so that our invoice is correct.",
        Learnmore: "Learn more",
      },
      familiessection4: {
        header1: "We help with taking care of your children when needed!",
        header2: "Our services include...",
        card: {
          card1title: "Babysitting/nanny care",
          card1subtitle:
            " Our dedicated babysitter/nanny can assist with specific tasks such as dropping off and picking up children from preschool or school, help with home-work, engage in play activities, etc.",
          card2title: "Household support",
          card2subtitle:
            "Our dedicated babysitter/nanny can also assist with household support such as meal preparation, tidying up play areas, light cleaning, laundry, or any other specific needs for your family.",
          card3title: "Flexibility",
          card3subtitle:
            "Families can get help regularly to make daily life easier, sporadically when in need of a date-night or simply alone time, or for a specific occasion such as a wedding or work event.",
          card4title: "Experience and safety",
          card4subtitle:
            "Our committed babysitters/nannies are experienced and have undergone screening, background checks, and most of them held First Aid certification.",
          card5title: " Communication and collaboration",
          card5subtitle:
            "We understand how important it is for families and our dedicate babysitters/nannies to collaborate and communicate well. We provide regular follow up to ensure that we are aware of any changes, expectations, or specific instructions pertaining to your needs.",
          card6title: "Help primary (relative) carer",
          card6subtitle:
            "Being the primary carer can be demanding, so having support can be really helpful. Our dedicated helper offers relief help to a family member caring for a child with specific needs.",
        },
      },

      familiessection5: {
        header: "How it works...",
        title:
          "We believe it is important that your needs are being met by professional people at every stage of your DoCare journey!",
        card1title: "Identify your need",
        card1s1:
          "We help you in selecting the plan that best meets your needs.",
        card2title: "Matching with babysitter/nanny",
        card1s2:
          "We will find you a suitable babysitter/nanny who is competent to provide the appropriate care.",
        card3title: "Free first meet and greet test visit",
        card1s3:
          "Family meets the assigned babysitter/nanny for the first time to get acquianted.",
        card4title: "Follow up and get started",
        card1s4:
          "  We follow up on your test visit and decide on how we go forward.",
      },
    },
    company: {
      card1: "Offer the most appreciated benefit among employees",
      card2: "Support employees' work-life balance",
      card3: "Attract and retain employees",
      card4: "Foster a family-friendly work environment",
      companyt1: " DoCare services are the most ",
      compantt2: "sought-after benefit",
      companyt3: "for employee wellbeing",
      companyts1: " DoCare-tjänster är ",
      compantts2: " den ",
      companyts3: " mest eftertraktade ",
      companyts4: " förmånen ",
      companyts5: " för dina anställdas välbefinnande",
      companysection1: {
        header1: "DoCare is a great way to show ",
        header2: "you care!",
        content:
          "DoCare services as a benefit are an increasingly popular offering by companies to attract and retain employees, especially working parents and staff with elderly parents. By collaborating with DoCare, you show that you understand the challenges that working parents or grown children with aging parents experience while juggling their personal and professional life. Here are some key aspects to consider regarding our services for companies:",
      },
      companycard: {
        card1: "Employee well-being program",
        cardtext1:
          "To alleviate financial stress for working parents and staff with elderly parents, give them access to DoCare services.Our services are also qualified for Rutavdrag.",
        card2: "   On-demand program",
        cardtext2:
          " When needed, businesses can use our services to allow employees with family obligations to take part in workrelated events.",
        card3: " Collaboration with DoCare",
        cardtext3:
          "Collaboration with DoCare will provide 10% discount on our services used by your employees.",
        card4: " Gift vouchers",
        cardtext4:
          "DoCare gift voucher is a great way to show appreciation to your employees.",
      },
      companysection2: {
        companys2t1:
          "We are committed to providing our services to your employees and helping them reduce daily stress.",
        TOLEARNMORE: "TO LEARN MORE,",
        CONTACTUS: "CONTACT US",
      },
    },
  },
  Aboutus: {
    title: " is a great way to show you care! ",
    title1: "Vi på ",
    title2: " bryr oss om dina nära och kära!",
    cardtttle: "We think our loved ones deserve the very best care!",
    cardp1:
      "DoCare provides personalised care tailored to the specific needs of your loved ones, whether they are young children  or elderly parents. We consider your loved ones' safety, well-being, and communication to be of the utmost importance.",
    ForSeniors: "For Seniors",
    ForSeniorsp1:
      "To provide your family peace of mind, we match you with an experienced and dedicated helper. We accommodate people who need extra assistance for their ageing parents or seniors who want customised care.",
    ForFamilies: "For Families",
    ForFamiliesp1:
      "We help you solve the puzzle of daily family life, allowing you to be an even better parent, whether you are a parent looking for support, a family looking for long-term aid, or in need of short-term assistance.",
    Becomepartof: "Become part ",
    DoCareteam: "of DoCare team!",
    cardp2:
      "DoCare provides personalised care to elderly and families. Our goal is to build a long-term relationship with our customers by being flexible, affordable, and committed to providing best care and assistance. As a member of DoCare team of helpers, you can help us meet our goals and needs of our customers at every stage of their DoCare journey",
    Asa: "As a",
    helper: "DoCare helper...",
    helper1:
      " - You will provide elderly with short-term or long-term assistance with simple household tasks, outdoor activities and companionship, etc...",
    helper2:
      " - You will assist families on a regular basis or as needed with babysitting/nanny tasks, picking up and dropping off children at activities or preschool, etc",
    helper3: " - You decide when and where you want to work extra",
    helper4: "- You are sixteen years of age or older",
    helper5:
      "If this sounds like you, apply now to register your interest in becoming a member of a DoCare team.",
    Applynow: "Apply now",
    MeettheDoCareteam: "Meet the DoCare team",
  },
  faq: {
    Frequently: "Frequently asked questions...",
    ForSeniors: "For Seniors",
    ForFamilies: "For Families",
    ForCompanies: " For Companies",
    ForCompaniesQ1: "Can I use Rutavdrag for DoCare services?",
    ForCompaniesQ1ans1:
      "Yes, we are able to deduct rutavdrag from the price for the DoCare services. We recommend that you check your personal maximum amount for rutavdrag, allocated by the ",
    ForCompaniesQ1ans2: "Swedish tax department",
    ForCompaniesQ1ans3:
      "and let us know the reduction amount that may be applied and we will handle the administrative tasks associated with Rutavdrag",
    ForCompaniesQ2:
      "What employee well-being programs does DoCare provide to companies?",
    ForCompaniesQ2ans1:
      " DoCare has several different programs that companies can offer to their employees, including employee benefits, gift vouchers, collaborations with DoCare, and on-demand programs. Please",
    ForCompaniesQ2ans2: "contact us ",
    ForCompaniesQ2ans3: "for more information.",
    ForCompaniesQ: "",
    ForCompaniesQans1: " ",
    ForCompaniesQ3:
      "How does it work when companies want to provide DoCare services as an employee well-being program?",
    ForCompaniesQ3ans1:
      " When our services are provided as an employee benefit, the invoice is allocated to the employee and delivered to the employer, who simply deducts that amount from the employee's net salary. All our services are qualifed for Rutavdrag.",
    ForCompaniesQ4:
      "How can an employee use DoCare services as a salary benefit?",
    ForCompaniesQ4ans1:
      "An employee will need to confirm with their employer whether the cost of our services can be deducted from their salary. Please ",
    ForCompaniesQ4ans2: "contact us",
    ForCompaniesQ4ans3: "for additional information. ",
    ForCompanies5: "Can DoCare services be used in the evenings or weekends?",
    ForCompaniesQ5ans1:
      " Yes we can arrange for our team to help your company regardless of the times and days you need help on. Our helpers are available for work during the day, at night, on weekends, and during holidays.",
    ForCompaniesQ6: "Can DoCare assist companies during events or expos?",
    ForCompaniesQ6ans1:
      " Yes, we can help during any events, mainly with child care. Please ",
    ForCompaniesQ6ans2: "contact us ",
    ForCompaniesQ6ans3: "for more information. ",
    senoirsfaq: [
      {
        question: "Can I choose my own helper/assistant?",
        answer: `DoCare matches you with a dedicated helper depending on your needs and requirements. If you are unhappy with the person we chose, you can simply request a replacement helper. Customer satisfaction is very important for us and we make sure that the helper we've chosen for you is well-liked by you and your family. Furthermore, following each visit, each of our helpers are required to submit post visit report/feedback so we can be proactive in helping our customers. `,
      },
      {
        question:
          "What checks are performed before DoCare helper is allocated to a customer?",
        answer: `Customer's safety is very important to us. After a video and/or in-person interview process, a background check on our helper is conducted, always including a police check. `,
      },
      {
        question: `What is a "free first visit"?`,
        answer: `There is no need to prepare for the "first visit" because the purpose of this visit is for you to get to know your helper. It is advised that a family member attends this first meeting. You can go over the tasks you need help with in detail, as well as potential activities you can undertake together. We will contact you to follow up on the visit and to schedule future visits. If anything unexpected happens, such as illness, this first visit can always be rescheduled. `,
      },
      {
        question: "What happens if I have to reschedule a planned visit?",
        answer: `In the event you need to reschedule your visit, please contact DoCare as soon as possible. The notification period for free rescheduling will be applied according to the pricing plan you have selected. We will contact you to arrange a new time for the visit depending on your helper's availability.`,
      },
      {
        question: `What does helping "primary (relative) carer" mean?`,
        answer: `It means that DoCare helper can relieve a family member who is looking after an elderly person. It is crucial to note that this helper does not include assistance with medical needs or medication. DoCare helpers are not required to have nursing experience or previous experience in elderly care, therefore they shall not offer medical assistance.  `,
      },
      {
        question: `Who covers the expenses for the activities that cost (such as fika, museum visits, movies, etc)?`,
        answer: `The customer is responsible for the costs associated with the activities for both you and your helper. Please note that the Rutavdrag deduction cannot be applied to these expenses.`,
      },
      {
        question: "How do I pay for DoCare services?",
        answer: `You will receive an invoice by email based on your service plan. You cannot give cash to your helper since they are not responsible for invoicing or handling any money.`,
      },
      {
        question:
          "What happens if my helper is unable to complete my scheduled visit? ",
        answer: `Due to unforeseen circumstances, such as illness, visits may need to be postponed.  If this happens, we will contact you first, and if you still want the visit to take place, we will try to find a replacement.`,
      },
    ],

    familiesfaq: [
      {
        question: " Can I choose my own babysitter/nanny?",
        answer: `DoCare matches you with a dedicated babysitter/nanny depending on your needs and requirements. If you are unhappy with the person we chose, you can simply request a replacement babysitter/nanny. Customer satisfaction is very important for us and we make sure that the babysitter/nanny we've chosen for you is well-liked by you and your family. Furthermore, following each visit, each of our team members are required to submit post visit report/feedback so we can be proactive in helping our customers. `,
      },
      {
        question:
          "What checks are performed before a babysitter/nanny is allocated to a family?",
        answer: `Your safety is very important to us. After a video and/or in-person interview process, a background check on our babysitter/nanny is conducted, always including a police check`,
      },
      {
        question: `What is a "free first visit"?`,
        answer: `The purpose of the first visit is for you and your family to get to know your babysitter/nanny. During this visit the babysitter/nanny will come to your home. You can go over the tasks you need help with in detail, as well as potential activities you will need your babysitter/nanny to undertake. We will contact you to follow up on the visit and to schedule future visits. If anything unexpected happens, such as illness, this first visit can always be rescheduled. `,
      },
      {
        question: `Is it possible to use babysitter/nanny for only the pickup and/or drop-off?`,
        answer: `It is possible for the DoCare babysitter/nanny to pick up and/or drop off your child at preschool or school; the number of visits per week is up to you, but each visit must be at least 1.5 hours. Our babysitter/nanny can also assist with getting children ready for preschool or school before drop-off and help with any additional tasks after pick-up.`,
      },
      {
        question: `Are babysitters/nannies available in the evenings or/and weekends?`,
        answer: `Yes we can arrange a nanny/babysitter regardless of the times and days you wish to receive help. Our babysitter/nanny are available to work during the day, at night, on weekends, and on holidays.`,
      },
      {
        question: `Am I able to get the same babysitter/nanny every time?`,
        answer: `Yes, you always get the same babysitter/nanny because we match them with your family based on your needs.`,
      },
      {
        question: "How do I pay for DoCare services?",
        answer: `You will receive an invoice by email based on your service plan. You cannot give cash to your babysitter/nanny since they are not responsible for invoicing or handling any money. `,
      },
      {
        question: "Am I able to get a babysitter/nanny for a newly born?",
        answer: `Yes, we can arrange for a babysitter/nanny to care for children of all ages, from newborns to school-age children.`,
      },
    ],
  },
  Contactus: {
    contactus: "Contact us",
    WithDoCare: " With DoCare, ",
    getting: "getting started is simple...",
    assestl:
      "If you have any questions, get in touch with us and we'll be happy to assist!",
    Firstname: "First name",
    Lastname: "Last name",
    contactby: "How do you want to be contacted",
    city: "Name of the city where the assistance is needed",
    Describe: "Describe in detail what you need help with and how often",
    submitalertmsg: "Your query has now been submitted!",
    SelectEnquiry: "Select type of your enquiry",
    Sign: "Sign up for DoCare news and updates",
    agree: "I agree that my data is processed in accordance with the ",
    policy: "privacy policy",
    Submit: "Submit",
    By: ["By phone", "By email", "Both"],
    Byphone: "Phone number",
    Byemail: "Email id",
    cByemail: "By email",
    cByphone: "By phone",
    Both: "Both",
    Enquirytype: [
      "For seniors",
      "For families",
      "For companies",
      "Gift voucher",
      "Referral",
      "Other",
    ],
  },
  workwithus: {
    i: "This means that we will look at your police report as well as all the information available in the Swedish legal database. This will also include your credit rating report. ",
    YesOrNo: [
      {
        id: "01",
        name: "Yes",
      },
      {
        id: "02",
        name: "No",
      },
    ],
    successmsg: "You have applied successfully!",
    alertmsg: "Fill in all mandatory fields before sending your application.",
    para: "Fill in the job application to express your interest in becoming part of the DoCare team. We will contact you after we review your application.",
    mypersonalDetials: "My personal details",
    myWorkExperience: "My work experience",
    myEducation: "My education",
    myAvailability: "My availability",
    myLanguageSkills: "My language skills",
    otherInformation: "Other information",
    workWithUsPara:
      "DoCare is responsible for registering the personal information you provide in your application. For more information, please see our ",
    policy: " Privacy Policy",
    remainingPara:
      ". By submitting my personal information and application to DoCare, I acknowledge that:",
    tickboxOne:
      "I have read, understand and agree to the above Privacy Policy.",
    tickboxTwo:
      "the information I provided is complete and truthful, and that I understand that inaccurate information may result in the rejection or termination of my application",
    tickboxThere:
      "I agree that DoCare may save and process the information submitted and subsequently collected, share information about new assignments, and use information for job-related marketing purposes",
    submitButton: "Submit",
    personalDetails: {
      areaofStudy: [
        {
          id: "01",
          name: "Full time",
        },
        {
          id: "02",
          name: "Part time",
        },
      ],
      firstName: "First name",
      lastName: "Last name",
      address: "Address",
      postCode: "Post code",
      areaName: "Area name",
      city: "City",
      contact: "Contact number",
      email: "Email",
      dob: "Date of birth",
      dateFormate: "yyyy/mm/dd",
      swadishBankAccnt: "I have a Swedish bank account",
      example: "(for example, Stockholm)",
      select: "Select",
      yes: "Yes",
      no: "No",
      preferedToWork:
        "I prefer to work with (tick option and the user can choose one or multiple):*",
      Seniors: "Seniors",
      ChildrenFamily: "Children/Family",
      ChildrenSchool: "Children/school & preschool setting",
      location: "My preferred work location",
      decription: "Briefly describe why you would like to work for DoCare",
      hobbies: "Describe your interests and hobbies",
      currentDoing: "Tell us what you are currently doing",
      student: "I am a student",
      typeofstudy: "Type of study",
      hourWeek: "State hours/week",
      instituteName: "Institution name",
      upload: "Upload proof of your studies",
      proof:
        " The proof of study can be either a letter of admission, a CSN card, or something similar",
      frelencer: "I am self-employed/freelancer",
      refired: "I am retired/pensioner",
      primaryOccupation: "I have another primary occupation (minimum 50%)",
      Specify: "Specify",
      SpecifyOthersInfo: "Specify",
    },
    workExperience: {
      workExperience: [
        {
          id: "01",
          name: "Nurse",
        },
        {
          id: "02",
          name: "Assistant nurse",
        },
        {
          id: "03",
          name: "Elderly care assistant",
        },
        {
          id: "04",
          name: "Nanny",
        },
        {
          id: "05",
          name: "Child care/preschool",
        },
        {
          id: "06",
          name: "Preschool teacher",
        },
      ],
      add: "Add",
      submit: "Submit",
      experience: "I have experience working with children",
      duration: "Duration of time:",
      Roletitle: "Title of the role: ",
      responsibilities: "Key responsibilities: (maximum 150 words)",
      experience2: "I have experience working with elderly people",
      experience3: "I have studies in healthcare/childcare",
      experience4: "I have other work experience",
    },
    education: {
      stateEducation: "State your education",
      NameInstite: "Name of the institution:",
      from: "From",
      to: "To",
    },
    availiablity: {
      select_hours_week: [
        {
          id: "01",
          name: "30+ hours per week",
        },
        {
          id: "01",
          name: "15 -30 hours per week",
        },
        {
          id: "03",
          name: "2-15 hours per week",
        },
        {
          id: "04",
          name: "Flexible, open to any of the above alternatives",
        },
      ],
      availibilityofwork: [
        {
          id: "01",
          name: "Before noon",
        },
        {
          id: "02",
          name: "After noon",
        },
        {
          id: "03",
          name: "Evenings",
        },
        {
          id: "04",
          name: "Weekends",
        },
        {
          id: "05",
          name: "Flexible, open to any of the above alternatives",
        },
        {
          id: "06",
          name: "Not Flexible - My schedule changes every week",
        },
      ],
      date: "Date",
      workingTime: [
        {
          id: "01",
          name: "As soon as possible",
        },
        {
          id: "01",
          name: "Specify date",
        },
      ],
      item1: "I can start working:",
      item2: "I am available to work",
      hourWeek: "Select hours/week",
      item3: "I can commit to be available for work for at least 6 months",
    },
    language: {
      swedish: "Swedish:",
      english: "English:",
      other: "Other language:",
      Native: "Native",
      Fluent: "Fluent",
      Proficient: "Proficient",
      Intermediate: "Intermediate",
      Beginner: "Beginner",
      Cancel: "Cancel",
      Submit: "Submit",
    },
    othersInfo: {
      platforms: [
        {
          id: "01",
          name: "Arbetsförmedlingen",
        },
        {
          id: "02",
          name: "Marketing event",
        },
        {
          id: "03",
          name: "Word of mouth",
        },
        {
          id: "04",
          name: "Job posting",
        },
        {
          id: "05",
          name: "Instagram",
        },
        {
          id: "06",
          name: "Facebook",
        },
        {
          id: "07",
          name: "Linkedin",
        },
        {
          id: "08",
          name: "Google",
        },
        {
          id: "09",
          name: "Tiktok",
        },
      ],
      item1: "I have a valid police clearance certificate",
      clearancecertificate: "Upload a police clearance certificate*",
      item1No:
        "Before you start employment with DoCare, you  must present a valid police clearance certificate. For more information and to order a copy, visit",
      url: "> > >https://polisen.se/tjanster-tillstand/belastningsregistret/barn-annan-verksamhet/blanketter---ovrigt-arbete-med-barn/",
      item1Nopart2:
        "After you receive your certificate, please remember to email us a copy.This is because you cannot start working without a valid certificate.",
      item2: "I agree to a credit and background check",
      item3: "I have a right to work in Sweden",
      Select: "Select",
      EU: [
        {
          id: "01",
          name: "I am an EU citizen",
        },
        {
          id: "02",
          name: "I am a non-EU citizen",
        },
      ],

      EUCertificate: "Upload the main page of your passport",
      nonEUCertificate: "Upload a copy of your work permit card",
      NoSweden:
        "To work in Sweden, you need a valid work permit. For more information, visit",
      maigrationURL: "https://www.migrationsverket.se",
      item4: "I have a valid driver's licence",
      item5: "I am allergic to animals",
      item6:
        "If necessary, I am willing to take on additional caregiving duties, such as helping the customer with hygiene (showering, helping them use the restroom, etc).",
      item7: "How did you hear about us?",
    },
  },
};

export default english;
