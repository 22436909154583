import React, { ChangeEvent, ReactElement, useState } from "react";
import {
  ContactContainer,
  FormCheck,
  FormDropdown,
  FormInput,
  ContactSection,
} from "./styledcomponents";
import {
  ColumnWrapper,
  ColumnWrapper1,
  RowWrapper,
} from "../Services/subSections/styledcomponents";
import {
  ImageBackgroundReducer,
  Images,
  Subtitles,
  Title,
  TitleWrapper,
} from "../styledComponents";
import contactus_section from "../../assests/more/contact_section.png";
import Footer from "../../components/Footer";
import { Movetop, PageWrapper, StyledButton } from "../../components";
import { TextWrapper } from "../Prices/seniors/styledcomponents";
import { AppDispatch, RootState } from "../../state/store";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { CreateContactProsp } from "../../state/services/types";
import { createContactus } from "../../state/services/api";

const Contact = (): ReactElement => {
  const {
    services: { addContactusProsp },
    language: { language },
    // addcontactresponce,
  } = useSelector(
    (state: RootState) => ({
      services: state.services,
      // addcontactresponce:state.services.addContactusResposne,
      isSuccess: state.services.isSuccess,
      language: state.language,
    }),
    shallowEqual
  );

  Movetop();
  const dispatch: AppDispatch = useDispatch();
  const [values, setValues] = useState(addContactusProsp as CreateContactProsp);
  const [contacted, setContacted] = useState("");

  const {
    contactus,
    WithDoCare,
    getting,
    assestl,
    Firstname,
    Lastname,
    contactby,
    city,
    Describe,
    submitalertmsg,
    SelectEnquiry,
    Sign,
    agree,
    policy,
    Submit,
    By,
    Enquirytype,
    Both,
    Byemail,
    Byphone,
    cByemail,
    cByphone,
  } = language?.Contactus;

  return (
    <PageWrapper>
      <ContactContainer>
        {/* <ToastMessage show={isSuccess} message={addcontactresponce} bg={"danger"}></ToastMessage> */}
        <ContactSection>
          <ImageBackgroundReducer />
          <TextWrapper>
            <Title
              fontFamily="Playfair Display, serif"
              fontWeight={400}
              font="58px"
              color="#ffffff"
              fontStyle="normal"
            >
              {contactus}
            </Title>
          </TextWrapper>
        </ContactSection>
        <TitleWrapper>
          <Title font="52px" fontWeight={500} color="#242864" lineHeight="66px">
            {WithDoCare} <span> {getting}</span>
          </Title>
          <Subtitles
            fontFamily="Lato, sans-serif"
            fontWeight={400}
            font="16px"
            color="#686976"
            lineHeight="26px"
            fontStyle="normal"
            style={{ marginTop: "1%" }}
          >
            {assestl}
          </Subtitles>{" "}
        </TitleWrapper>
        <RowWrapper>
          <ColumnWrapper lg={6}>
            <Images src={contactus_section} width={`100%`} height="auto" />
          </ColumnWrapper>
          <ColumnWrapper1 lg={6}>
            <div>
              <FormInput
                placeholder={Firstname}
                value={values?.first_name}
                height="50px"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    first_name: e.target.value,
                  });
                }}
              />
              <FormInput
                placeholder={Lastname}
                value={values?.last_name}
                height="50px"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    last_name: e.target.value,
                  });
                }}
              />{" "}
              <FormDropdown
                placeholder={contactby}
                // defaultValue={ contactby}
                data={By}
                onChange={(value: any) => {
                  setContacted(value);
                }}
              />
              {contacted === Both ? (
                <>
                  {" "}
                  <FormInput
                    placeholder={Byemail}
                    height="50px"
                    value={values?.email_id}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        email_id: e.target.value,
                      });
                    }}
                  />{" "}
                  <FormInput
                    placeholder={Byphone}
                    height="50px"
                    value={values?.phone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        phone: e.target.value,
                      });
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {contacted === cByemail && (
                <FormInput
                  placeholder={Byemail}
                  height="50px"
                  value={values?.email_id}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      email_id: e.target.value,
                    });
                  }}
                />
              )}
              {contacted === cByphone && (
                <FormInput
                  placeholder={Byphone}
                  height="50px"
                  value={values?.phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValues({
                      ...values,
                      phone: e.target.value,
                    });
                  }}
                />
              )}
              <FormInput
                placeholder={city}
                value={values?.city_name}
                height="50px"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    city_name: e.target.value,
                  });
                }}
              />{" "}
              <FormDropdown
                placeholder={SelectEnquiry}
                data={Enquirytype}
                onChange={(value: any) => {
                  setValues({
                    ...values,
                    enquiry: value,
                  });
                }}
              />
              <FormInput
                placeholder={Describe}
                as="textarea"
                value={values?.describe_in_detail}
                onChange={(e: any) => {
                  setValues({
                    ...values,
                    describe_in_detail: e.target.value,
                  });
                }}
              />
              <div className="checkboxwrapper">
                <FormCheck
                  checked={values?.docare_news_updates}
                  onClick={(e: any) => {
                    setValues({
                      ...values,
                      docare_news_updates: e?.target.checked,
                    });
                  }}
                />
                <p>{Sign}</p>
              </div>
              <div className="checkboxwrapper">
                <FormCheck
                  checked={values?.privacy_policy}
                  onClick={(e: any) => {
                    setValues({
                      ...values,
                      privacy_policy: e?.target.checked,
                    });
                  }}
                />
                <p>
                  {agree}
                  <a href="/privacy-policy"> {policy}</a>
                </p>
              </div>
            </div>

            <StyledButton
              background={"#ff4880"}
              fontSize={"14px"}
              hoverBackground={"#242864"}
              border={"none"}
              color={"white"}
              margin={"10px 0 0"}
              onClick={() => {
                !!values?.privacy_policy
                  ? dispatch(createContactus({ ...values })).then((d) => {
                      if (d?.payload?.success) {
                        alert(submitalertmsg);
                        setValues(addContactusProsp as CreateContactProsp);
                      }
                    })
                  : alert(
                      "Prior to submitting your query, you are requied to agree to the privacy policy."
                    );
              }}
            >
              {Submit}
            </StyledButton>
          </ColumnWrapper1>
        </RowWrapper>
      </ContactContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Contact;
