import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addWorkwithus,
  createContactus,
  GetPriceplane,
  getTeamLists,
  getTestimonialLists,
} from "./api";
import { GetTestimonialResponse, initialState, GetPricePlane } from "./types";

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    updateWorkWithInformation: (state, action) => {
      const key = Object.keys(action.payload)[0];
      const formInfo = {
        ...state.workWithDetails,
        [key]: action.payload[key],
      };
      state.workWithDetails = formInfo;
    },
    updateWorkWithInformationAfterSuccess: (state) => {
      state.workWithDetails = {
        first_name: "",
        last_name: "",
        address: "",
        city: "",
        area_name: "",
        postal_code: "",
        contact_number: "",
        email: "",
        dob: new Date(),
        i_have_a_swedish_bank_account: "",
        tell_us_what_you_are_currently_doing: "",
        type_of_study: "",
        edu_type_of_study: "",
        per_state_hours_week: "",
        edu_state_hours_week: "",
        institution_name: "",
        proof_of_your_studies: "",
        preferred_work_with: "",
        prefer_to_work_with: [],
        i_am_self_employed_freelancer: "",
        i_am_self_employed_freelancer2: "",
        why_work_for_DoCare: "",
        i_am_retired_pensioner: "",
        i_have_another_primary_occupation: "",
        interests_and_hobbies: "",
        Specify_personal_details: "",
        experience_with_children: "",
        children_work_exp_details: [],
        experience_with_elderly: "",
        studies_or_work_experience_in_healthcare: "",
        elderly_people_work_exp_details: [],
        i_have_studies_and_or_work_experience_in_healthcare: "",
        healthcare_name: "",
        other_work_experience: "",
        other_work_exp_details: [],
        i_am_currently_studying: "",
        education_level: "",
        name_of_the_institution: "",
        completed_in: "",
        can_start_working: "",
        working_date: "",
        work_for_at_least_6_months: "",
        lang_Sweedish: "",
        lang_English: "",
        i_have__police_clearance_certificate: "",
        police_clearance_certificate: "",
        i_agree_to_credit_and_background_check: "",
        right_to_work_Sweden: "",
        your_passport: "",
        work_permit_card: "",
        valid_driver_licence: "",
        allergic_to_animals: "",
        Specify_other_info: "",
        caregiving_duties: "",
        how_do_you_hear_about_us: "",
        available_to_work: "",
        hours_per_week: "",
        work_citizin_type: "",
        education_from: new Date(),
        education_to: new Date(),
        allergy_details: "",
        lang_swedish_rate: 0,
        lang_english_rate: 0,
        other_language_details: [],
        privacy_policy_agreement: "",
      };
    },
  },
  extraReducers: {
    [createContactus.pending.toString()]: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [createContactus.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<any>>
    ) => {
      state.isLoading = true;
      state.isSuccess = true;
      state.addContactusResposne = action.payload;
    },
    [createContactus.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getTestimonialLists.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getTestimonialLists.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetTestimonialResponse>>
    ) => {
      state.isLoading = false;
      state.getTestimonialListResposne = action.payload;
    },
    [getTestimonialLists.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetPriceplane.pending.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetPriceplane.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetPricePlane>>
    ) => {
      state.isLoading = true;
      state.getPricePlane = action.payload;
    },
    [GetPriceplane.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getTeamLists.pending.toString()]: (state) => {
      state.isLoading = false;
    },
    [getTeamLists.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetTestimonialResponse>>
    ) => {
      state.isLoading = true;
      state.getTeamMember = action.payload;
    },
    [getTeamLists.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [addWorkwithus.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [addWorkwithus.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [addWorkwithus.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default servicesSlice.reducer;
