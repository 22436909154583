import styled from "styled-components";

export const PageWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden !important;
  h5 {
    color: #2196f3;
    font-size: 20px;
  }
`;
