import React, { ReactElement, useEffect } from "react";
import {
  ImageNameWrapper,
  PersoanlSlider,
  PersonalContainer,
  ProfileImages,
  ProfileNameWrapper,
} from "./styledComponents";
import { Subtitles, Title } from "../../styledComponents";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { getTestimonialLists } from "../../../state/services/api";

const PersonalSlider = (): ReactElement => {
  const {
    services: { getTestimonialListResposne },
  } = useSelector(
    (state: RootState) => ({
      services: state.services,
    }),
    shallowEqual
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipe: true,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestimonialLists());
  }, [dispatch]);

  return (
    <PersoanlSlider {...settings}>
      {getTestimonialListResposne
        ?.filter((d) => d?.role === "Companies")
        ?.map((data, index) => {
          return (
            <PersonalContainer key={index}>
              <ImageNameWrapper>
                <ProfileImages
                  src={`https://api.dohr.io/${data?.pro_pic}`}
                  alt="profile_pic"
                />

                <hr />
                <ProfileNameWrapper>
                  <Title
                    font={"25px"}
                    fontWeight={400}
                    lineHeight="26px"
                    color="white"
                  >
                    {data?.name}
                  </Title>
                  <Subtitles
                    font={"15px"}
                    fontWeight={400}
                    lineHeight="26px"
                    color="white"
                  >
                    - {data?.location}
                  </Subtitles>
                </ProfileNameWrapper>
              </ImageNameWrapper>
              {/* <Ratings initialValue={3.5} readonly size={20} /> */}
              <Title
                font={"20px"}
                fontWeight={400}
                lineHeight="30px"
                color="white"
                fontStyle="italic"
                fontFamily='"Lato", sans-serif'
              >
                {data?.description}
              </Title>
            </PersonalContainer>
          );
        })}
    </PersoanlSlider>
  );
};

export default PersonalSlider;
