import { Form, FormControl } from "react-bootstrap";
import DropdownList from "react-widgets/DropdownList";
import styled from "styled-components";
import contact_banner from "../../assests/prices/seniors/S5-02.png";

export const ContactSection = styled.section`
  background-image: url(${contact_banner});
  width: 100%;
  height: 225px;
  position: relative;
  background-position: 10% 20%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
`;

export const ContactContainer = styled.div`
  margin: 0 0 5% 0;
  .checkboxwrapper {
    display: flex;
    flex-direction: row;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #686976;
  }
  p {
    margin-top: -3px;
    margin-left: 10px;
  }
`;

export const FormInput = styled(FormControl)`
  margin: 20px 0;
  border: 1px solid #dfdfdf;
  background-color: #f6f8fe;
  width: 100%;
  border-radius: 8px;
  height: ${({ height }) => (height ? height : "90px")};

  &::placeholder {
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #686976;
  }
  &:focus {
    border: 1px solid #dfdfdf;
    background-color: #f6f8fe;
    box-shadow: none;
  }
`;

export const FormCheck = styled(Form.Check)`
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #686976;
`;

export const SelectDropdownWrapper = styled.div`
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #dfdfdf;
  background-color: #f6f8fe;
`;

export const FormDropdown = styled(DropdownList)`
  text-align: left;
  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: Lato, sans-serif;
  border: 1px solid #dfdfdf;

  background-color: #f6f8fe;
  .rw-widget-input {
    font-size: 14px;
    line-height: 26px;
    color: #686976;
    background-color: #f6f8fe;
    font-weight: 600;
    box-shadow: none;
    cursor: pointer;
    font-size: 1vw;
    &:hover,
    &:focus,
    &:active {
      font-size: 14px;
      line-height: 26px;
      color: #686976;
      box-shadow: none;
    }
  }
  .rw-widget-picker {
    border: 1px solid transparent;
    min-height: 45px;
  }
  .rw-picker-caret {
    font-size: 14px;
    line-height: 26px;
    color: #686976;
    background-color: #f6f8fe;
    font-weight: 600;
  }
  .rw-popup-container {
    color: grey;
    font-weight: 600;
    font-size: 1vw;
    border: none;
  }
  .rw-popup {
    border: none;
  }
  .rw-list-option.rw-state-selected,
  .rw-state-selected.rw-list-option-create {
    background-color: #ff4880;
    border: none;
  }
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    border-color: none;
  }
  .rw-dropdown-list-input > * {
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #686976;
  }
`;
