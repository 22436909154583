import { ReactElement } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Images, Subtitles, Title, TitleWrapper } from "../../styledComponents";
import {
  Sliderss,
  SlickSlider,
  ImageWrapper,
  TextContainer,
} from "./styledcomponents";
import E from "../../../assests/Home/slider_quote.svg";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../state/store";

const SliderTestimonials = (): ReactElement => {
  const {
    services: { getTestimonialListResposne },
  } = useSelector(
    (state: RootState) => ({
      services: state.services,
    }),
    shallowEqual
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipe: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <SlickSlider {...settings}>
      {getTestimonialListResposne
        ?.filter((f) => f?.role === "Services")
        ?.map((list, index) => {
          return (
            <div key={index}>
              <ImageWrapper>
                <Images
                  src={`https://api.dohr.io/${list?.pro_pic}`}
                  width="150px"
                  height="150px"
                  style={{ border: "5px solid #ffffff" }}
                />
              </ImageWrapper>
              <Sliderss>
                <TextContainer>
                  <img
                    src={E}
                    alt=""
                    style={{ position: "absolute", top: "25px", left: "60px" }}
                    width={"100px"}
                  />
                  <TitleWrapper>
                    <Title
                      font="25px"
                      lineHeight="32px"
                      color="#242864"
                      fontWeight={400}
                      fontFamily="'Playfair Display', serif"
                    >
                      {list?.name} -{" "}
                      <span className="job">{list?.location}</span>
                    </Title>
                    <Subtitles
                      font="20px"
                      lineHeight="32px"
                      color="#686976"
                      fontWeight={400}
                      fontStyle="italic"
                      fontFamily="'Lato', sans-serif"
                      letterSpacing="0.2px"
                      style={{ width: "95%" }}
                    >
                      {" "}
                      {list?.description}
                    </Subtitles>
                  </TitleWrapper>
                  {/* <Ratings
                  initialValue={5}
                  readonly
                  size={20}
                  margin={"10px 0 0 0"}
                /> */}
                </TextContainer>
              </Sliderss>
            </div>
          );
        })}
    </SlickSlider>
  );
};

export default SliderTestimonials;
