import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { CreateContactProsp, GetTestimonialResponse } from "./types";
import { RootState } from "../store";

export const createContactus = createAsyncThunk(
  "contact/createContact",
  async (requestPayload: CreateContactProsp): Promise<any> => {
    const response = await api.post(`${"add_contact_us"}`, requestPayload);
    return response?.data;
  }
);

export const getTestimonialLists = createAsyncThunk(
  "testimonial/getTestimonials",
  async (): Promise<Array<GetTestimonialResponse>> => {
    const resposne = await api.get("get_testimonials");
    return resposne.data.message;
  }
);

export const addEmail = createAsyncThunk(
  "email/getEmail",
  async (requestPayload: { email_id: string }): Promise<any> => {
    const resposne = await api.post("add_stay_connected", requestPayload);
    return resposne.data.message;
  }
);

export const addWorkwithus = createAsyncThunk(
  "student/saveStudent",
  async (_undefined, { getState, rejectWithValue }): Promise<any> => {
    const {
      services: {
        workWithDetails: {
          first_name,
          last_name,
          address,
          city,
          area_name,
          postal_code,
          contact_number,
          email,
          dob,
          i_have_a_swedish_bank_account,
          tell_us_what_you_are_currently_doing,
          type_of_study,
          edu_type_of_study,
          per_state_hours_week,
          edu_state_hours_week,
          institution_name,
          prefer_to_work_with,
          i_am_self_employed_freelancer,
          i_am_self_employed_freelancer2,
          why_work_for_DoCare,
          i_am_retired_pensioner,
          i_have_another_primary_occupation,
          interests_and_hobbies,
          Specify_personal_details,
          experience_with_children,
          other_work_exp_details,
          experience_with_elderly,
          elderly_people_work_exp_details,
          i_have_studies_and_or_work_experience_in_healthcare,
          healthcare_name,
          studies_or_work_experience_in_healthcare,
          other_work_experience,
          children_work_exp_details,
          i_am_currently_studying,
          education_level,
          name_of_the_institution,
          completed_in,
          can_start_working,
          working_date,
          work_for_at_least_6_months,
          lang_Sweedish,
          lang_English,
          i_have__police_clearance_certificate,
          i_agree_to_credit_and_background_check,
          right_to_work_Sweden,
          work_permit_card,
          valid_driver_licence,
          allergic_to_animals,
          Specify_other_info,
          caregiving_duties,
          how_do_you_hear_about_us,
          available_to_work,
          hours_per_week,
          education_to,
          privacy_policy_agreement,
          education_from,
          allergy_details,
          lang_swedish_rate,
          lang_english_rate,
          other_language_details,
          preferred_work_with,
          proof_of_your_studies,
        },
      },
    } = getState() as RootState;

    if (first_name) {
      const requestPayload = {
        first_name,
        last_name,
        address,
        city,
        area_name,
        postal_code,
        contact_number,
        email,
        dob,
        privacy_policy_agreement,
        i_have_a_swedish_bank_account:
          i_have_a_swedish_bank_account === "Ja"
            ? "Yes"
            : i_have_a_swedish_bank_account === "Nej"
            ? "No"
            : i_have_a_swedish_bank_account,
        tell_us_what_you_are_currently_doing:
          tell_us_what_you_are_currently_doing === "Ja"
            ? "Yes"
            : tell_us_what_you_are_currently_doing === "Nej"
            ? "No"
            : tell_us_what_you_are_currently_doing,
        type_of_study,
        edu_type_of_study,
        per_state_hours_week,
        edu_state_hours_week,
        institution_name,
        prefer_to_work_with,
        i_am_self_employed_freelancer:
          i_am_self_employed_freelancer === "Ja"
            ? "Yes"
            : i_am_self_employed_freelancer === "Nej"
            ? "No"
            : i_am_self_employed_freelancer,
        i_am_self_employed_freelancer2,
        why_work_for_DoCare,
        i_am_retired_pensioner:
          i_am_retired_pensioner === "Ja"
            ? "Yes"
            : i_am_retired_pensioner === "Nej"
            ? "No"
            : i_am_retired_pensioner,
        i_have_another_primary_occupation:
          i_have_another_primary_occupation === "Ja"
            ? "Yes"
            : i_have_another_primary_occupation === "Nej"
            ? "No"
            : i_have_another_primary_occupation,
        interests_and_hobbies,
        Specify_personal_details,
        experience_with_children:
          experience_with_children === "Ja"
            ? "Yes"
            : experience_with_children === "Nej"
            ? "No"
            : experience_with_children,
        other_work_exp_details,
        experience_with_elderly:
          experience_with_elderly === "Ja"
            ? "Yes"
            : experience_with_elderly === "Nej"
            ? "No"
            : experience_with_elderly,
        elderly_people_work_exp_details,
        i_have_studies_and_or_work_experience_in_healthcare:
          i_have_studies_and_or_work_experience_in_healthcare === "Ja"
            ? "Yes"
            : i_have_studies_and_or_work_experience_in_healthcare === "Nej"
            ? "No"
            : i_have_studies_and_or_work_experience_in_healthcare,
        healthcare_name,
        studies_or_work_experience_in_healthcare,
        other_work_experience:
          other_work_experience === "Ja"
            ? "Yes"
            : other_work_experience === "Nej"
            ? "No"
            : other_work_experience,
        children_work_exp_details,
        i_am_currently_studying,
        education_level,
        name_of_the_institution,
        completed_in,
        can_start_working,
        working_date,
        work_for_at_least_6_months:
          work_for_at_least_6_months === "Ja"
            ? "Yes"
            : work_for_at_least_6_months === "Nej"
            ? "No"
            : work_for_at_least_6_months,
        lang_Sweedish,
        lang_English,
        i_have__police_clearance_certificate:
          i_have__police_clearance_certificate === "Ja"
            ? "Yes"
            : i_have__police_clearance_certificate === "Nej"
            ? "No"
            : i_have__police_clearance_certificate,
        i_agree_to_credit_and_background_check:
          i_agree_to_credit_and_background_check === "Ja"
            ? "Yes"
            : i_agree_to_credit_and_background_check === "Nej"
            ? "No"
            : i_agree_to_credit_and_background_check,
        right_to_work_Sweden:
          right_to_work_Sweden === "Ja"
            ? "Yes"
            : right_to_work_Sweden === "Nej"
            ? "No"
            : right_to_work_Sweden,
        work_permit_card,
        valid_driver_licence:
          valid_driver_licence === "Ja"
            ? "Yes"
            : valid_driver_licence === "Nej"
            ? "No"
            : valid_driver_licence,
        allergic_to_animals:
          allergic_to_animals === "Ja"
            ? "Yes"
            : allergic_to_animals === "Nej"
            ? "No"
            : allergic_to_animals,
        Specify_other_info,
        caregiving_duties:
          caregiving_duties === "Ja"
            ? "Yes"
            : caregiving_duties === "Nej"
            ? "No"
            : caregiving_duties,
        how_do_you_hear_about_us,
        available_to_work,
        hours_per_week,
        education_to,
        education_from,
        allergy_details,
        lang_swedish_rate,
        lang_english_rate,
        other_language_details,
        preferred_work_with,
        proof_of_your_studies,
      };

      const response = await api.post("add_work_with_us", requestPayload);
      if (response?.data) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (response?.data?.success === false) {
        alert("You have Error!");
      }
      return response?.data;
    } else {
      return rejectWithValue(
        alert("Fill in all mandatory fields before sending your application.")
      );
    }
  }
);

export const GetPriceplane = createAsyncThunk(
  "get/getPrice",
  async (): Promise<Array<GetTestimonialResponse>> => {
    const resposne = await api.get("get_price_plans");
    return resposne.data.message;
  }
);

export const getTeamLists = createAsyncThunk(
  "teamlist/getTeamlist",
  async (): Promise<Array<GetTestimonialResponse>> => {
    const resposne = await api.get("get_team_member");
    return resposne.data.message;
  }
);
