import { Card } from "react-bootstrap";
import styled from "styled-components";

export const NewsCardWrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  border: none;
  border-radius: 0;
  &:hover {
    img {
      width: 100%;
    }
  }
`;

export const ContainerWrapper = styled.div`
  background-color: #f6f8fe;
  width: 100%;
`;

export const DetailWrapper = styled.div`
  width: 90%;
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const AnchorText = styled.a`
  color: #242864;
  font-size: 15px;
  line-height: 25px;
  padding-left: 10px;
  font-weight: 700;
  &:hover {
    color: #f54881;
  }
`;
