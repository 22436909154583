import { Card } from "react-bootstrap";
import styled from "styled-components";

export const CardWrapper = styled(Card)`
  border: none;
  border-radius: 0;
  border-top: ${({ borderTop }) => (borderTop ? "3px solid #ff4880" : null)};
  position: relative;
  margin: ${({ margin }) => (margin ? margin : "0 10px")};
  transition: all 0.3s ease;
  &:hover {
    ${({ isMarginBottom }) =>
      isMarginBottom &&
      `  transform: translateY(10px);
         transition: all 0.3s ease;
      `}
    ${({ isScale }) =>
      isScale &&
      `
      img{
        transition: all 0.4s ease;
      }
    `}
  }
`;

export const PriceWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  background-color: #ff4880;
  .text {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    display: inline-block;
    margin-top: -8px;
    color: white;
  }
`;

export const PriceNumberWrapper = styled.div`
  box-sizing: border-box;
  span {
    font-size: 30px;
    line-height: 64px;
    font-weight: 700;
    display: inline-block;
    color: white;
    margin-top: -20px;
    font-family: "Playfair Display", serif;
    padding-right: 4px;
  }
  .symbol {
    position: absolute;
    top: 13px;
    display: inline-block;
    font-size: 16px;
    line-height: 66px;
    font-weight: 700;
    color: white;
  }
`;

export const PriceTextWrapper = styled.div``;

export const Lists = styled.li<{ noBorder?: boolean }>`
  padding: 10px 0 12px 0;
  text-align: center;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.2px;
  color: #686976;
  font-weight: 400;
  border-bottom: ${({ noBorder }) => (noBorder ? "1px solid #dedede" : "none")};
`;

export const CardBadge = styled.div`
  font-size: 13px;
  font-weight: 500;
  background: #242864;
  padding: 5px 10px;
  border: 1px solid #242864;
  border-radius: 10px;
  color: white;
  top: -14px;
  left: 170px;
  position: absolute;
  .bg-primary {
    background-color: #242864;
  }
  @media (max-width: 550px) {
    left: 111px;
  }
`;
