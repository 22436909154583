import styled from "styled-components";
import faq_banner from "../../../assests/prices/families/FS5-02.png";

export const FirstFAQSection = styled.section`
  background-image: url(${faq_banner});
  width: 100%;
  height: 250px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
`;

export const SecondWrapper = styled.section`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  margin: 20px 0;
`;

export const QuestionWrapper = styled.div`
  background: #f6f8fe;
  width: 98%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;
