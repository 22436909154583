import styled from "styled-components";
import { DropdownList } from "react-widgets";

interface FormProps {
  top?: string;
}

export const Forms = styled.form<FormProps>`
  width: 6vw;
`;

export const Dropdown = styled(DropdownList)`
  text-align: center;
  border-radius: 26px;
  border: 1px solid #ff4880;
  .rw-widget-input {
    background: #ff4880;
    color: white;
    font-weight: 600;
    box-shadow: none;
    cursor: pointer;
    font-size: 1vw;
    &:hover,
    &:focus,
    &:active {
      background-color: #ff4880;
    }
  }
  .rw-widget-picker {
    border: 1px solid transparent;
    border-radius: 20px;
    min-height: 30px;
    width: 6vw;
  }
  .rw-picker-caret {
    color: white;
    font-weight: 600;
  }
  .rw-popup-container {
    color: grey;
    font-weight: 600;
    font-size: 1vw;
    border: none;
  }
  .rw-popup {
    border: none;
  }
  .rw-list-option.rw-state-selected,
  .rw-state-selected.rw-list-option-create {
    background: #ff4880;
    border: none;
  }
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    border-color: #ff4880;
  }
`;

export const Select = styled.select`
  border-radius: 26px;
  padding: 5px;
  width: 6vw;
  background: #ff4880;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 1vw;
  align-items: center;
  text-align: center;
  border: none;
  box-shadow: none;
  &:hover,
  &:focus,
  &:active {
    border: none;
    box-shadow: none;
  }
  option {
  }
  @media (max-width: 700px) {
    width: 18%;
    height: 30px;
    font-size: 3vw;
  }
`;
