import React from "react";
import { FlexWrapper, DropdownWrapper, FormData } from "../../../components";
import { updateWorkWithInformation } from "../../../state/services/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { Label } from "../../../components/Dropdown/subcomponents";
import DatePicker from "react-datepicker";

const EducationDetails = () => {
  const { values, language } = useSelector(
    (state: RootState) => ({
      values: state.services.workWithDetails,
      language: state.language.language,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const { stateEducation, NameInstite, from, to } =
    language.workwithus.education;
  const { dateFormate } = language.workwithus.personalDetails;

  return (
    <FlexWrapper noMargin noPadding justifyContent="space-between">
      <DropdownWrapper width="45%">
        <FormData
          value={values?.education_level}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                education_level: value,
              })
            );
          }}
          label={stateEducation}
          isRequired
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.name_of_the_institution}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                name_of_the_institution: value,
              })
            );
          }}
          label={NameInstite}
          isRequired
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <Label>{from}*</Label>
        <DatePicker
          selected={values?.education_from}
          dateFormat="yyyy/MM/dd"
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={50}
          scrollableYearDropdown
          placeholderText={dateFormate}
          onChange={(value) => {
            dispatch(
              updateWorkWithInformation({
                education_from: value,
              })
            );
          }}
          customInput={<input className="ida" />}
        />
      </DropdownWrapper>
      <DropdownWrapper width="45%">
        <Label>{to}*</Label>
        <DatePicker
          selected={values?.education_to}
          dateFormat="yyyy/MM/dd"
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={50}
          scrollableYearDropdown
          placeholderText={dateFormate}
          onChange={(value) => {
            dispatch(
              updateWorkWithInformation({
                education_to: value,
              })
            );
          }}
          customInput={<input className="ida" />}
        />
      </DropdownWrapper>
      {/* <DropdownWrapper width="45%">
        <FormData
          value={values?.education_to}
          placeholder={dateFormate}
          inputType="month"
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                education_to: value,
              })
            );
          }}
          label={to}
          isRequired
        />
      </DropdownWrapper> */}
      {/* <DropdownWrapper width="45%">
            <FormData
              value={""}
              onChange={() => {}}
              label="Completed in"
              isRequired
            />
          </DropdownWrapper>{" "} */}
    </FlexWrapper>
  );
};

export default EducationDetails;
