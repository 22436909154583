/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import {
  MenuItemList,
  NavBarContainer,
  NavbarWrapper,
  Anchor,
  NavbarLogo,
  StyledButton,
  NavbarLogoWrapper,
  ButtonWrappers,
  HamburgerMenu,
} from "./styledcomponent";

import Docare from "../../assests/h1.png";
import { useNavigate } from "react-router";
import LangugeDropdown from "../LanguageToggleDropdown";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import Dropdown from "./Dropdown";
import MobileHeder from "./mobileHeader";

const Navbar = () => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  const onMouseEnter = (name: any) => {
    if (window.innerWidth < 960) {
      setDropdown("");
    } else {
      setDropdown(name);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown("");
    } else {
      setDropdown("");
    }
  };

  document.addEventListener("scroll", (event: any) => {
    const scrollPosition = event.target.scrollingElement.scrollTop;
    setScrollPosition(scrollPosition);
  });

  const { services, more, prices, contactus, workWithUs } = language?.header;
  const [showCanvas, setshowCanvas] = useState(false);

  return (
    <NavbarWrapper isAnimation={scrollPosition > 15 ? true : false}>
      <HamburgerMenu
        onClick={() => {
          setshowCanvas(!showCanvas);
        }}
        className={!showCanvas ? "bx bx-menu" : "bx bx-x"}
        title="menu"
      ></HamburgerMenu>
      <NavbarLogoWrapper>
        <NavbarLogo src={Docare} alt="docare" onClick={() => navigate("/")} />
      </NavbarLogoWrapper>

      <MobileHeder setshowCanvas={setshowCanvas} showCanvas={showCanvas} />
      <NavBarContainer>
        <MenuItemList>
          <Anchor
            onClick={() => {
              navigate("/");
            }}
          >
            {services}
          </Anchor>
        </MenuItemList>
        <MenuItemList
          color={dropdown === "price"}
          onMouseEnter={() => {
            onMouseEnter("price");
          }}
          onMouseLeave={onMouseLeave}
        >
          <Anchor style={{ paddingRight: "6px" }}>{prices}</Anchor>{" "}
          {dropdown === "price" ? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
          {dropdown === "price" && <Dropdown dropdownName={"price"} />}
        </MenuItemList>
        <MenuItemList
          color={dropdown === "more"}
          onMouseEnter={() => {
            onMouseEnter("more");
          }}
          onMouseLeave={onMouseLeave}
          style={{ marginRight: "10px" }}
        >
          <Anchor style={{ paddingRight: "6px" }}>{more}</Anchor>
          {dropdown === "more" ? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
          {dropdown === "more" && <Dropdown dropdownName={"more"} />}
        </MenuItemList>
        <MenuItemList>
          <ButtonWrappers>
            <StyledButton
              onClick={() => {
                navigate("/contact");
              }}
            >
              {contactus}
            </StyledButton>
            <StyledButton
              onClick={() => {
                navigate("/workwithus");
              }}
            >
              {workWithUs}
            </StyledButton>
          </ButtonWrappers>
        </MenuItemList>
      </NavBarContainer>
      <LangugeDropdown />
    </NavbarWrapper>
  );
};

export default Navbar;
