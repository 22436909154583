import { ReactElement, useEffect } from "react";
import { AboutusCardSlider } from "./subcomponents";
import { TeamMemberCard } from "./helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { getTeamLists } from "../../../state/services/api";

const AboutTeamSlider = (): ReactElement => {
  const { getTeamMember } = useSelector(
    (state: RootState) => ({
      getTeamMember: state.services.getTeamMember,
    }),
    shallowEqual
  );
  const dispatch: AppDispatch = useDispatch();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: getTeamMember?.length >= 4 ? 4 : getTeamMember?.length,
    slidesToScroll: getTeamMember?.length >= 4 ? 4 : getTeamMember?.length,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getTeamLists());
  }, [dispatch]);

  return (
    <AboutusCardSlider {...settings}>
      {getTeamMember?.slice(0, 8)?.map((list, index) => {
        return (
          <div key={index}>
            <TeamMemberCard
              name={list?.name}
              imag={list?.profile_pic}
              details={list}
              role={list?.role}
            />
          </div>
        );
      })}
    </AboutusCardSlider>
  );
};

export default AboutTeamSlider;
