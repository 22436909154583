import React, { ReactElement, useEffect, useState } from "react";
import { FlexWrapper, FormData } from "../../../components";
import { AdditionButton, RatingBoxWrapper, TitleCard } from "../subcomponent";
import Rating from "@mui/material/Rating";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Button, Modal } from "react-bootstrap";
import { AppDispatch, RootState } from "../../../state/store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateWorkWithInformation } from "../../../state/services/actions";

const Languages = (): ReactElement => {
  const { values, languages } = useSelector(
    (state: RootState) => ({
      values: state.services.workWithDetails,
      languages: state.language.language,
    }),
    shallowEqual
  );
  const dispatch: AppDispatch = useDispatch();
  const [show, setshow] = useState(false);
  const [languageArray, setLanguageArray] = useState<any>([]);
  const [language, setLanguage] = useState<any>({
    name: "",
    rate: 0,
  });

  const handleLanguageRemove = (i: any) => {
    const data = [...languageArray];
    data?.splice(i, 1);
    setLanguageArray(data);
  };

  useEffect(() => {
    dispatch(
      updateWorkWithInformation({
        other_language_details: JSON.stringify(languageArray),
      })
    );
  }, [languageArray, dispatch]);

  const determineLangeage = (rateing: any) => {
    let languageDetials;
    switch (rateing) {
      case "1":
        languageDetials = Beginner;
        break;
      case "2":
        languageDetials = Intermediate;
        break;
      case "3":
        languageDetials = Proficient;
        break;
      case "4":
        languageDetials = Fluent;
        break;
      case "5":
        languageDetials = Native;
        break;
    }
    return languageDetials;
  };
  const {
    swedish,
    english,
    other,
    Native,
    Fluent,
    Proficient,
    Intermediate,
    Beginner,
    Cancel,
    Submit,
  } = languages.workwithus.language;
  return (
    <FlexWrapper noMargin noPadding justifyContent="space-start">
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ width: "10%" }}>
            {" "}
            <TitleCard>{swedish}</TitleCard>{" "}
            <TitleCard>
              {determineLangeage(values?.lang_swedish_rate)}
            </TitleCard>
          </div>
          <RatingBoxWrapper>
            <Rating
              name="customized-color"
              defaultValue={0}
              value={values?.lang_swedish_rate}
              onChange={(e: any) => {
                dispatch(
                  updateWorkWithInformation({
                    lang_swedish_rate: e?.target?.value,
                  })
                );
              }}
              precision={1}
              icon={<CircleIcon fontSize="inherit" htmlColor="#f54881" />}
              emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
            />
          </RatingBoxWrapper>{" "}
        </div>
        <div
          className="mt-4"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ width: "10%" }}>
            <TitleCard>{english}</TitleCard>
            <TitleCard>
              {determineLangeage(values?.lang_english_rate)}
            </TitleCard>
          </div>
          <RatingBoxWrapper>
            <Rating
              name="customized-color"
              precision={1}
              value={values?.lang_english_rate}
              onChange={(e: any) => {
                dispatch(
                  updateWorkWithInformation({
                    lang_english_rate: e?.target?.value,
                  })
                );
              }}
              icon={<CircleIcon fontSize="inherit" htmlColor="#f54881" />}
              emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
            />
          </RatingBoxWrapper>{" "}
        </div>
        <RatingBoxWrapper></RatingBoxWrapper>{" "}
        <div
          className="mt-4"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TitleCard>{other}</TitleCard>
          <AdditionButton
            onClick={() => {
              setshow(!show);
              setLanguage({
                ...language,
                name: "",
                rate: 0,
              });
            }}
          >
            +
          </AdditionButton>
        </div>
        {languageArray?.map((data: any, index: any) => (
          <div
            className="mt-4"
            key={index}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "10%" }}>
              {" "}
              <TitleCard>{data?.name}s</TitleCard>
              <TitleCard>{determineLangeage(data?.rate)}</TitleCard>
            </div>
            <RatingBoxWrapper>
              <Rating
                name="customized-color"
                defaultValue={data?.rate}
                readOnly
                icon={<CircleIcon fontSize="inherit" htmlColor="#f54881" />}
                emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
              />{" "}
              <AdditionButton
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  handleLanguageRemove(index);
                }}
              >
                -
              </AdditionButton>
            </RatingBoxWrapper>{" "}
          </div>
        ))}
      </>
      <Modal
        show={show}
        onHide={() => {
          setshow(!show);
        }}
      >
        <Modal.Header closeButton closeLabel=":'dsfdsf">
          Specify other language
        </Modal.Header>
        <Modal.Body>
          <div
            className="mt-4"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "100%" }}>
              <FormData
                value={language?.name}
                onChange={(value: string) => {
                  setLanguage({
                    ...language,
                    name: value,
                  });
                }}
                placeholder="Language Name"
              />
              <TitleCard>{determineLangeage(language?.rate)}</TitleCard>
            </div>
            <RatingBoxWrapper width="100%">
              <Rating
                name="customized-color"
                defaultValue={2}
                value={language?.rate}
                onChange={(e: any) => {
                  setLanguage({
                    ...language,
                    rate: e?.target.value,
                  });
                }}
                precision={1}
                icon={<CircleIcon fontSize="medium" htmlColor="#f54881" />}
                emptyIcon={<RadioButtonUncheckedIcon fontSize="medium" />}
              />
            </RatingBoxWrapper>{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <Button
            className="btn"
            variant="secondary"
            style={{ fontSize: "12px" }}
            onClick={() => {
              setshow(false);
            }}
          >
            {Cancel}
          </Button>
          <Button
            className="btn"
            style={{ fontSize: "12px" }}
            onClick={() => {
              setLanguageArray([...languageArray, { ...language }]);
              setshow(false);
            }}
          >
            {Submit}
          </Button>
        </Modal.Footer>
      </Modal>
    </FlexWrapper>
  );
};

export default Languages;
