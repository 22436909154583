/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, useEffect, useState } from "react";
import {
  AdWrapper,
  Adtext,
  FirstServiceSection,
  ImageBorder,
  ImageColoumn,
  ImageContainer,
  ImageRow,
  ImageWrapper,
  PolyImage,
  PolyImageWarper,
  TextParaWrapper,
  TextWrapper,
} from "./styledcomponents";
import {
  ColumnWrapper,
  RowWrapper,
} from "../../Services/subSections/styledcomponents";
import {
  Footer,
  Movetop,
  PageWrapper,
  SeniorCardContainer,
  StyledButton,
} from "../../../components";
import CardImage1 from "../../../assests/Services/senior_card_1.png";
import CardImage2 from "../../../assests/Services/senior_card_2.png";
import CardImage3 from "../../../assests/Services/senior_card_3.png";
import SliderSeniorCare from "./slider";
import {
  ImageBackgroundReducer,
  Images,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../styledComponents";
import S501 from "../../../assests/prices/seniors/S5-01.png";
import S502 from "../../../assests/prices/seniors/S5-02.png";
import S503 from "../../../assests/prices/seniors/S5-03.png";
import S504 from "../../../assests/prices/seniors/S5-04.png";
import { NumberWrapper } from "../companies/helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { useNavigate } from "react-router";
import { GetPriceplane } from "../../../state/services/api";

const PricesSeniors = (): ReactElement => {
  const {
    language: {
      language: {
        prices: { seniors, families },
      },
    },
    getPricePlane,
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
      getPricePlane: state.services.getPricePlane,
    }),
    shallowEqual
  );
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  Movetop();

  const {
    section1: { title1, title2 },
    section2: {
      s2t1,
      s2t2,
      subtitle,
      constactUs,
      footer1,
      footer2,
      contactUs,
      giftVoucher,
      perHour,
      Refer,
      HundredKR,
      friend,
      discountPara,
      discountPara2,
    },
    seniorsection3,
    seniorssection4,
  } = seniors;
  const { familiessection3 } = families;
  useEffect(() => {
    dispatch(GetPriceplane());
  }, [dispatch]);

  let SeniorsVIPList: any = [];
  let SeniorsBasicList: any = [];
  let SeniorsPayList: any = [];
  getPricePlane
    ?.filter((d) => d?.name === "Seniors")
    ?.filter((vi) => vi?.plan_type === "VIP")
    ?.forEach((list) => {
      SeniorsVIPList?.push({
        border: true,
        text1: list?.visits,
        text2: list?.time_period,
        text3: list?.scheduling,
        text4: list?.comment4,
        price: list?.plan_price,
      });
    });

  getPricePlane
    ?.filter((d) => d?.name === "Seniors")
    ?.filter((vi) => vi?.plan_type === "Basic")
    ?.forEach((list) => {
      SeniorsBasicList?.push({
        border: true,
        text1: list?.visits,
        text2: list?.time_period,
        text3: list?.scheduling,
        text4: list?.comment4,
        price: list?.plan_price,
      });
    });
  getPricePlane
    ?.filter((d) => d?.name === "Seniors")
    ?.filter((vi) => vi?.plan_type === "Pay as you go")
    ?.forEach((list) => {
      SeniorsPayList?.push({
        border: true,
        text1: list?.visits,
        text2: list?.time_period,
        text3: list?.scheduling,
        text4: list?.comment4,
        price: list?.plan_price,
      });
    });

  return (
    <PageWrapper>
      <FirstServiceSection>
        <ImageBackgroundReducer />
        <TextWrapper>
          <Title
            fontFamily="Playfair Display, serif"
            fontWeight={400}
            font="58px"
            color="#ffffff"
            fontStyle="normal"
            mFontStyle="260%"
          >
            {title1} <span>DoCare </span>
            {title2}
          </Title>
        </TextWrapper>
      </FirstServiceSection>
      <SectionContainer background="#f6f8fe">
        <SectionWrapper>
          <RowWrapper noMargin padding="0">
            <ColumnWrapper lg={12}>
              <TitleWrapper margin="0">
                <Title
                  font="50px"
                  fontWeight={500}
                  color="#242864"
                  mFontStyle="32px"
                  lineHeight="70px"
                >
                  {s2t1} <br />
                  <span>{s2t2}</span>
                </Title>
                <Subtitles
                  fontFamily="Lato, sans-serif"
                  fontWeight={400}
                  font="16px"
                  color="#686976"
                  lineHeight="26px"
                  fontStyle="normal"
                  className="mt-3 mb-5"
                >
                  {subtitle}
                </Subtitles>{" "}
                <AdWrapper
                  onMouseEnter={() => {
                    setshow(!show);
                  }}
                >
                  <b>{Refer}</b> {HundredKR} <br /> <b> {friend}</b>
                </AdWrapper>
                {show && (
                  <Adtext>
                    {discountPara}
                    <span
                      style={{
                        color: "#ff4880",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/contact");
                      }}
                    >
                      {constactUs}
                    </span>{" "}
                    {discountPara2}
                  </Adtext>
                )}
              </TitleWrapper>
            </ColumnWrapper>
          </RowWrapper>
          <RowWrapper noMargin padding="0">
            <ColumnWrapper lg={4}>
              <SeniorCardContainer
                image={CardImage1}
                title={"VIP"}
                lists={SeniorsVIPList?.length ? SeniorsVIPList : []}
                timing={SeniorsVIPList[0]?.price}
                buttonName={constactUs}
                perHour={perHour}
              />
            </ColumnWrapper>
            <ColumnWrapper lg={4}>
              <SeniorCardContainer
                image={CardImage2}
                title={"Basic "}
                lists={SeniorsBasicList?.length ? SeniorsBasicList : []}
                timing={SeniorsBasicList[0]?.price}
                buttonName={constactUs}
                perHour={perHour}
              />
            </ColumnWrapper>
            <ColumnWrapper lg={4}>
              <SeniorCardContainer
                image={CardImage3}
                title={"Pay as you go"}
                lists={SeniorsPayList?.length ? SeniorsPayList : []}
                timing={SeniorsPayList[0]?.price}
                buttonName={constactUs}
                perHour={perHour}
              />
            </ColumnWrapper>
            <TitleWrapper margin="20px 0 0 0">
              <Subtitles font="15px" fontWeight={500}>
                {footer1} <br />
                {footer2}
              </Subtitles>
              <Subtitles font="16px">
                <span
                  style={{
                    color: "#ff4880",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  {contactUs}
                </span>{" "}
                {giftVoucher}
              </Subtitles>
            </TitleWrapper>
          </RowWrapper>
        </SectionWrapper>
      </SectionContainer>
      <SectionContainer background="#242864">
        <SectionWrapper>
          <TitleWrapper paddingLeft="20px">
            <Title
              font="35px"
              fontWeight={500}
              color="#242864"
              lineHeight="70px"
            >
              <span>{familiessection3.header}</span>
            </Title>
            <Subtitles
              fontFamily="Lato, sans-serif"
              fontWeight={400}
              font="16px"
              color="#ffffff"
              lineHeight="26px"
              fontStyle="normal"
            >
              {familiessection3.s3t1}
              <br />
              <br />
              {familiessection3.s3t2}
            </Subtitles>{" "}
            <div>
              <StyledButton
                onClick={() => {
                  window.location.href =
                    "https://skatteverket.se/privat/fastigheterochbostad/rotarbeteochrutarbete.4.2e56d4ba1202f95012080002966.html";
                }}
                background="#ff4880"
                color={"#fff"}
                border="none"
              >
                {familiessection3.Learnmore}
              </StyledButton>
            </div>
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <SectionContainer background="#f9f9f9">
        <SectionWrapper>
          <TitleWrapper>
            <Title
              font="40px"
              fontWeight={500}
              color="#242864"
              lineHeight="70px"
            >
              {seniorsection3.seniorst1} <br />
              {seniorsection3.seniorst2}
              <span> {seniorsection3.seniorst3}</span>
            </Title>
          </TitleWrapper>
          <SliderSeniorCare isSeniors />
        </SectionWrapper>
      </SectionContainer>
      <SectionContainer background="#fff">
        <SectionWrapper width="100%">
          <TitleWrapper>
            <Title
              font="50px"
              fontWeight={500}
              color="#242864"
              lineHeight="70px"
            >
              {seniorssection4.header1}
            </Title>
            <Subtitles
              fontFamily="Lato, sans-serif"
              fontWeight={400}
              font="16px"
              color="#686976"
              lineHeight="26px"
              fontStyle="normal"
            >
              {seniorssection4.header2}
            </Subtitles>{" "}
          </TitleWrapper>
          <ImageRow style={{ margin: "5% 0" }}>
            <ImageColoumn lg={3}>
              {" "}
              <ImageContainer>
                <ImageWrapper>
                  <ImageBorder>
                    <Images src={S501} noRadius width="235px" height="235px" />
                  </ImageBorder>
                </ImageWrapper>
                <TextParaWrapper>
                  <NumberWrapper
                    fontSize="20px"
                    weight="45px"
                    height="45px"
                    lineHeight="40px"
                    absolute
                    top="52%"
                    left="49%"
                  >
                    <span>01</span>
                  </NumberWrapper>
                  <TitleWrapper>
                    <Title
                      font="25px"
                      fontWeight={500}
                      lineHeight="34px"
                      color="#242864"
                    >
                      {seniorssection4.cardtitle1}
                    </Title>
                    <Subtitles
                      font="15px"
                      lineHeight="26px"
                      fontWeight={400}
                      color="#686976"
                      letterSpacing="0.2px"
                      fontFamily='"Lato", sans-serif'
                    >
                      {seniorssection4.cardtext1}
                    </Subtitles>
                  </TitleWrapper>
                </TextParaWrapper>
              </ImageContainer>
            </ImageColoumn>
            <ImageColoumn lg={3}>
              {" "}
              <ImageContainer>
                <ImageWrapper>
                  <ImageBorder borderRadius="50%" rotate="null">
                    <Images
                      src={S502}
                      noRadius
                      width="235px"
                      height="235px"
                      style={{ borderRadius: "50%" }}
                    />
                  </ImageBorder>
                </ImageWrapper>
                <TextParaWrapper>
                  <NumberWrapper
                    fontSize="20px"
                    weight="45px"
                    height="45px"
                    lineHeight="40px"
                    absolute
                    top="-1%"
                    left="46%"
                    background="#242864"
                  >
                    <span>02</span>
                  </NumberWrapper>
                  <TitleWrapper>
                    <Title
                      font="25px"
                      fontWeight={500}
                      lineHeight="34px"
                      color="#242864"
                    >
                      {seniorssection4.cardtitle2}
                    </Title>
                    <Subtitles
                      font="15px"
                      lineHeight="26px"
                      fontWeight={400}
                      color="#686976"
                      letterSpacing="0.2px"
                      fontFamily='"Lato", sans-serif'
                    >
                      {seniorssection4.cardtext2}
                    </Subtitles>
                  </TitleWrapper>
                </TextParaWrapper>{" "}
              </ImageContainer>
            </ImageColoumn>
            <ImageColoumn lg={3}>
              {" "}
              <ImageContainer>
                <ImageWrapper>
                  <ImageBorder rotate="rotate(11deg)">
                    <Images src={S503} noRadius width="235px" height="235px" />
                  </ImageBorder>
                </ImageWrapper>
                <TextParaWrapper>
                  <NumberWrapper
                    fontSize="20px"
                    weight="45px"
                    height="45px"
                    lineHeight="40px"
                    absolute
                    top="49%"
                    left="37%"
                  >
                    <span>03</span>
                  </NumberWrapper>
                  <TitleWrapper>
                    <Title
                      font="25px"
                      fontWeight={500}
                      lineHeight="34px"
                      color="#242864"
                    >
                      {seniorssection4.cardtitle3}
                    </Title>
                    <Subtitles
                      font="15px"
                      lineHeight="26px"
                      fontWeight={400}
                      color="#686976"
                      letterSpacing="0.2px"
                      fontFamily='"Lato", sans-serif'
                    >
                      {seniorssection4.cardtext3}
                    </Subtitles>
                  </TitleWrapper>
                </TextParaWrapper>
              </ImageContainer>
            </ImageColoumn>
            <ImageColoumn lg={3}>
              <ImageContainer>
                <ImageWrapper>
                  <PolyImageWarper>
                    <PolyImage src={S504} />
                  </PolyImageWarper>
                </ImageWrapper>
                <TextParaWrapper>
                  <NumberWrapper
                    fontSize="20px"
                    weight="45px"
                    height="45px"
                    lineHeight="40px"
                    absolute
                    background="#242864"
                    top="58%"
                    left="43%"
                  >
                    <span>04</span>
                  </NumberWrapper>
                  <TitleWrapper>
                    <Title
                      font="25px"
                      fontWeight={500}
                      lineHeight="34px"
                      color="#242864"
                    >
                      {seniorssection4.cardtitle4}
                    </Title>
                    <Subtitles
                      font="15px"
                      lineHeight="26px"
                      fontWeight={400}
                      color="#686976"
                      letterSpacing="0.2px"
                      fontFamily='"Lato", sans-serif'
                    >
                      {seniorssection4.cardtext4}
                    </Subtitles>
                  </TitleWrapper>
                </TextParaWrapper>
              </ImageContainer>
            </ImageColoumn>
          </ImageRow>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default PricesSeniors;
