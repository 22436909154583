import React from "react";
import { FlexWrapper, DropdownWrapper, FormData } from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { updateWorkWithInformation } from "../../../state/services/actions";

const Availability = () => {
  const { values, language } = useSelector(
    (state: RootState) => ({
      values: state.services.workWithDetails,
      language: state.language.language,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const {
    item1,
    item2,
    hourWeek,
    item3,
    date,
    workingTime,
    availibilityofwork,
    select_hours_week,
  } = language.workwithus.availiablity;
  const { YesOrNo } = language.workwithus;
  return (
    <FlexWrapper noMargin noPadding justifyContent="space-between">
      {" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.can_start_working}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                can_start_working: value,
              })
            );
          }}
          label={item1}
          isRequired
          dropdownList={workingTime}
        />
      </DropdownWrapper>{" "}
      {values?.can_start_working === workingTime[1]?.name && (
        <DropdownWrapper width="45%">
          <FormData
            value={values?.working_date}
            onChange={(date: any) => {
              dispatch(
                updateWorkWithInformation({
                  working_date: date,
                })
              );
            }}
            label={date}
            defaultValue={new Date()}
            inputType="date"
            isRequired
          />
        </DropdownWrapper>
      )}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.available_to_work}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                available_to_work: value,
              })
            );
          }}
          label={item2}
          isRequired
          dropdownList={availibilityofwork}
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.hours_per_week}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                hours_per_week: value,
              })
            );
          }}
          label={hourWeek}
          isRequired
          dropdownList={select_hours_week}
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.work_for_at_least_6_months}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                work_for_at_least_6_months: value,
              })
            );
          }}
          label={item3}
          isRequired
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
    </FlexWrapper>
  );
};

export default Availability;
