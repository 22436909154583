import React, { ReactElement, useState } from "react";
import { Accordian, Footer, Movetop, PageWrapper } from "../../../components";
import { TextWrapper } from "../../Prices/seniors/styledcomponents";
import { ImageBackgroundReducer, Title } from "../../styledComponents";
import {
  QuestionWrapper,
  SecondWrapper,
  FirstFAQSection,
} from "./styledComponents";
import { ButtonWrapper, SectionButton } from "../../Services/styledcomponents";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../state/store";

const FAQ = (): ReactElement => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  Movetop();
  const [accordion, setAccordion] = useState("seniors");
  const {
    Frequently,
    ForCompanies,
    ForFamilies,
    ForSeniors,
    senoirsfaq,
    familiesfaq,
  } = language?.faq;
  return (
    <PageWrapper>
      <FirstFAQSection>
        <ImageBackgroundReducer />
        <TextWrapper>
          <Title
            fontFamily="Playfair Display, serif"
            fontWeight={400}
            font="58px"
            mFontStyle="40px"
            color="#ffffff"
            fontStyle="normal"
          >
            {Frequently}
          </Title>
        </TextWrapper>
      </FirstFAQSection>
      <SecondWrapper>
        <ButtonWrapper>
          <SectionButton
            onClick={() => setAccordion("seniors")}
            style={
              accordion === "seniors"
                ? { color: "white", backgroundColor: "#ff4880" }
                : { color: "black", backgroundColor: "white" }
            }
          >
            {ForSeniors}
          </SectionButton>
          <SectionButton
            onClick={() => setAccordion("families")}
            style={
              accordion === "families"
                ? { color: "white", backgroundColor: "#ff4880" }
                : { color: "black", backgroundColor: "white" }
            }
          >
            {ForFamilies}
          </SectionButton>
          <SectionButton
            onClick={() => setAccordion("companies")}
            style={
              accordion === "companies"
                ? { color: "white", backgroundColor: "#ff4880" }
                : { color: "black", backgroundColor: "white" }
            }
          >
            {ForCompanies}
          </SectionButton>
        </ButtonWrapper>
        <QuestionWrapper>
          {accordion === "seniors" && (
            <Accordian data={senoirsfaq} module="seniors" />
          )}
          {accordion === "families" && (
            <Accordian data={familiesfaq} module="families" />
          )}
          {accordion === "companies" && (
            <Accordian data={[]} module="companies" />
          )}
        </QuestionWrapper>
      </SecondWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default FAQ;
