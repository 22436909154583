import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const ActionButton = styled(Button)`
  margin: 0 2px;
  letter-spacing: 0.02857em;
  font-size: 13px;
  text-align: center;
  font-weight: 600;
  height: ${({ height }) => (height ? height : 'auto')};
`
export default ActionButton
