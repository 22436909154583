import React, { ReactElement, useState } from "react";

import {
  CanvaContianer,
  MobileAnchorText,
  MobileList,
  MobileNavContainer,
  MobileNavWrapper,
  MobileSubAnchorText,
  Offcanva,
} from "./styledcomponent";

import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useNavigate } from "react-router";

interface MobileProps {
  showCanvas: boolean;
  setshowCanvas: any;
}

const MobileHeder = ({
  setshowCanvas,
  showCanvas,
}: MobileProps): ReactElement => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  const navigate = useNavigate();
  const { mobile } = language?.header;
  const [dropdown, setDropdown] = useState({
    child: false,
    name: "",
  });

  return (
    <CanvaContianer>
      <Offcanva
        show={showCanvas}
        scroll
        backdrop
        responsive={showCanvas ? "lg" : ""}
        autoFocus
        onHide={() => {
          setshowCanvas(false);
        }}
      >
        <Offcanva.Header closeButton>DoCare</Offcanva.Header>
        <Offcanva.Body>
          <MobileNavContainer>
            <MobileNavWrapper>
              {mobile?.map((list, index) => {
                return list?.to === "" ? (
                  <>
                    <MobileList
                      key={index}
                      onClick={() => {
                        setDropdown({
                          ...dropdown,
                          child: !dropdown.child,
                          name: list.title,
                        });
                      }}
                    >
                      <MobileAnchorText>{list?.title}</MobileAnchorText>{" "}
                      {dropdown.child && dropdown.name === list.title ? (
                        <i className="fas fa-chevron-up" />
                      ) : (
                        <i className="fas fa-chevron-down" />
                      )}
                    </MobileList>
                    {dropdown.name === list.title &&
                      dropdown.child &&
                      list?.childs?.map((each, index) => {
                        return (
                          <MobileList>
                            <MobileSubAnchorText
                              onClick={() => {
                                navigate(each?.to);
                                setshowCanvas(false);
                              }}
                            >
                              {each?.title}
                            </MobileSubAnchorText>
                          </MobileList>
                        );
                      })}
                  </>
                ) : (
                  <MobileList key={index}>
                    <MobileAnchorText
                      onClick={() => {
                        navigate(list.to);
                        setshowCanvas(false);
                      }}
                    >
                      {list?.title}
                    </MobileAnchorText>{" "}
                  </MobileList>
                );
              })}
            </MobileNavWrapper>
          </MobileNavContainer>
        </Offcanva.Body>
      </Offcanva>
    </CanvaContianer>
  );
};

export default MobileHeder;
