import { ReactElement } from "react";
import { Footer, Movetop, PageWrapper } from "../../../components";
import {
  FirstCompanyWrapper,
  InsideColumnWrapper,
  InsideRowWrapper,
  OverFlowContainer,
} from "./styledComponents";
import {
  Images,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../styledComponents";
import {
  ColumnWrapper,
  ColumnWrapper1,
  RowWrapper,
} from "../../Services/subSections/styledcomponents";
import { SmallCard } from "./helpers";
import section_02_image from "../../../assests/prices/companies/CS2-01.png";
import section_03_image from "../../../assests/prices/companies/CS3-01.png";
import PersonalSlider from "./slider";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useNavigate } from "react-router";

const PricesCompanies = (): ReactElement => {
  const {
    language: {
      prices: { company },
    },
    transulation,
  } = useSelector((state: RootState) => ({
    language: state.language.language,
    transulation: state.language.selectedLang,
  }));

  Movetop();

  const {
    companyt1,
    compantt2,
    companyt3,
    companyts1,
    compantts2,
    companyts3,
    companyts4,
    companyts5,
    companysection1,
    companycard,
    companysection2,
  } = company;

  const navigate = useNavigate();

  return (
    <PageWrapper>
      <FirstCompanyWrapper>
        <RowWrapper noMargin>
          <ColumnWrapper lg={5}></ColumnWrapper>
          <ColumnWrapper1 lg={7} className="mt-5">
            <TitleWrapper>
              <Title
                color="#ffffff"
                font="50px"
                lineHeight="70px"
                fontFamily="'Playfair Display', serif"
                mFontStyle="175%"
              >
                {transulation === "EN" ? (
                  <>
                    {companyt1} <span>{compantt2}</span> {companyt3}
                  </>
                ) : (
                  <>
                    {companyts1}
                    <span style={{ color: "#ff4880" }}>{compantts2}</span>
                    {companyts3}
                    <span style={{ color: "#ff4880" }}>{companyts4}</span>
                    {companyts5}
                  </>
                )}
              </Title>
            </TitleWrapper>
          </ColumnWrapper1>
        </RowWrapper>
      </FirstCompanyWrapper>
      <SectionContainer background="#f6f8fe">
        <SectionWrapper>
          <SmallCard />
          <RowWrapper padding="30px 0" noMargin>
            <ColumnWrapper lg={6}>
              <TitleWrapper>
                <Title
                  font="48px"
                  fontWeight={500}
                  lineHeight="65px"
                  color="#242864"
                  textAlign="start"
                >
                  {companysection1.header1}{" "}
                  <span>{companysection1.header2}</span>
                </Title>
                <Subtitles
                  font="16px"
                  fontWeight={400}
                  lineHeight="26px"
                  color="#686976"
                  textAlign="start"
                  className="mt-3"
                  letterSpacing="0.2px"
                >
                  {companysection1.content}
                </Subtitles>
              </TitleWrapper>
              <InsideRowWrapper>
                <InsideColumnWrapper lg={6}>
                  <OverFlowContainer>
                    {" "}
                    <Title
                      color="#242864"
                      font="22px"
                      lineHeight="30px"
                      fontWeight={500}
                    >
                      {companycard.card1}
                    </Title>
                    <Subtitles
                      color="#686976"
                      font="15px"
                      lineHeight="26px"
                      fontWeight={400}
                      fontFamily='"Lato", sans-serif'
                    >
                      {companycard.cardtext1}
                    </Subtitles>
                  </OverFlowContainer>
                </InsideColumnWrapper>
                <InsideColumnWrapper lg={6}>
                  {" "}
                  <OverFlowContainer>
                    <Title
                      color="#242864"
                      font="22px"
                      lineHeight="30px"
                      fontWeight={500}
                    >
                      {companycard.card2}
                    </Title>
                    <Subtitles
                      color="#686976"
                      font="15px"
                      lineHeight="26px"
                      fontWeight={400}
                      fontFamily='"Lato", sans-serif'
                    >
                      {companycard.cardtext2}
                    </Subtitles>{" "}
                  </OverFlowContainer>
                </InsideColumnWrapper>
                <InsideColumnWrapper lg={6}>
                  {" "}
                  <OverFlowContainer>
                    {" "}
                    <Title
                      color="#242864"
                      font="22px"
                      lineHeight="30px"
                      fontWeight={500}
                    >
                      {companycard.card3}
                    </Title>
                    <Subtitles
                      color="#686976"
                      font="15px"
                      lineHeight="26px"
                      fontWeight={400}
                      fontFamily='"Lato", sans-serif'
                    >
                      {companycard.cardtext3}
                    </Subtitles>
                  </OverFlowContainer>
                </InsideColumnWrapper>
                <InsideColumnWrapper lg={6}>
                  {" "}
                  <OverFlowContainer>
                    {" "}
                    <Title
                      color="#242864"
                      font="22px"
                      lineHeight="30px"
                      fontWeight={500}
                    >
                      {companycard.card4}
                    </Title>
                    <Subtitles
                      color="#686976"
                      font="15px"
                      lineHeight="26px"
                      fontWeight={400}
                      fontFamily='"Lato", sans-serif'
                    >
                      {companycard.cardtext4}
                    </Subtitles>
                  </OverFlowContainer>
                </InsideColumnWrapper>
              </InsideRowWrapper>
            </ColumnWrapper>
            <ColumnWrapper lg={6}>
              <Images
                src={section_02_image}
                width="150%"
                height="auto"
                noRadius
              />
            </ColumnWrapper>
          </RowWrapper>
        </SectionWrapper>
      </SectionContainer>
      <SectionContainer background="#ffffff">
        <SectionWrapper>
          <div className="title">
            <TitleWrapper>
              <Subtitles
                font={"16px"}
                fontWeight={400}
                lineHeight="26px"
                color="#686976"
                fontFamily='"Lato", sans-serif'
              >
                {companysection2.companys2t1} <br />
                {companysection2.TOLEARNMORE}{" "}
                <span
                  style={{ color: "#3080b5" }}
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  {companysection2.CONTACTUS}
                </span>
              </Subtitles>
            </TitleWrapper>
          </div>
          <RowWrapper noMargin>
            <ColumnWrapper lg={6}>
              <PersonalSlider />
            </ColumnWrapper>
            <ColumnWrapper lg={6}>
              <Images
                src={section_03_image}
                width="110%"
                height="auto"
                noRadius
              />
            </ColumnWrapper>
          </RowWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default PricesCompanies;
