import styled from "styled-components";
import HomeSection_1 from "../../assests/Home/home_1.jpg";
import { Button } from "react-bootstrap";
import { WrapperProsp } from "./types";

export const HomeSectiononeWrapper = styled.section`
  width: 100%;
  height: 600px;
  position: relative;
  background-image: url(${HomeSection_1});
  background-size: cover;
  display: grid;
  grid-template-columns: auto auto;
  object-fit: contain;
`;

export const FirstWrapper = styled.div<WrapperProsp>`
  margin: 0 45px 10px;
  position: relative;
  max-width: 1500px;
  height: auto;
  width: ${({ width }) => (width ? width : null)};
`;

export const FirstContainer = styled.div`
  padding-top: 14%;
  margin-left: 2%;
`;

export const SecondWarpper = styled.div<WrapperProsp>`
  margin: 10px;
  width: ${({ width }) => (width ? width : null)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px auto;
`;

export const SectionButton: any = styled(Button)<{ isActive: boolean }>`
  border: none;
  margin: 0 10px;
  font-size: 16px;
  text-transform: capitalize;
  height: 2.5rem;
  width: 9rem;
  color: black;
  &:hover,
  &:focus {
    color: #f54881;
    background: #f6f8fe;
  }
  @media (max-width: 550px) {
    font-size: 13px;
    font-weight: 500;
    height: auto;
  }
`;

export const SectionContainers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
