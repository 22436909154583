import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { FontProsp } from "../types";
import Slider from "react-slick";

export const SectionWrapper = styled.div`
  margin-top: 1%;
  margin-bottom: 1%;
`;

export const RowWrapper = styled(Row)`
  max-width: 100%;
  width: 100%;
  margin: ${({ noMargin }) => (noMargin ? null : "1%")};
  padding: ${({ padding }) => (padding ? padding : "1%")};
  max-width: 1500px;
  @media (max-width: 850px) {
    padding: 10px;
  }
`;

export const ColumnWrapper = styled(Col)<{
  borderRight?: boolean;
  background?: string;
}>`
  border-right: ${({ borderRight }) =>
    borderRight ? "1px solid lightgrey" : null};
  position: relative;
  background: ${({ background }) => (background ? background : null)};
  @media (max-width: 600px) {
    text-align: start;
  }
`;

export const ColumnWrapper1 = styled(Col)``;

export const TextWrapper = styled.div``;

//
//
//

export const OrderListWrapper = styled.ul<FontProsp>`
  list-style: none;
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : null)};
  font-size: ${({ font }) => (font ? font : null)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  padding: 0;
  width: 100%;
  min-width: 100%;
  position: relative;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : null)};
`;

export const OrderList = styled.div<FontProsp>`
  padding: 15px 0;
  width: 100%;
  display: flex;
  position: relative;
  font-size: 15px;
`;

//
//Slider
//

export const SlickSlider = styled(Slider)`
  height: 500px;
  z-index: 0;
  .slick-prev {
    left: -30px;
    height: 128px;
    width: 30px;
    background-color: #ff4880;
  }
  .slick-next {
    right: -30px;
    background-color: black;
    height: 128px;
    width: 30px;
    background-color: #ff4880;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 6px;
  z-index: 3;
  display: flex;
  justify-content: center;
`;

export const Sliderss = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -60px;
`;

export const TextContainer = styled.div`
  padding: 100px 0;
  text-align: center;
`;
