import React, { useEffect, useState } from "react";
import { DropdownWrapper, FlexWrapper, FormData } from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { updateWorkWithInformation } from "../../../state/services/actions";
import {
  WorkChildrenInterface,
  WorkElderlyValuesInterface,
  WorkOtherValuesInterface,
} from "./const";
import { AdditionButton } from "../subcomponent";

const WorkExperience = () => {
  const { values, language } = useSelector(
    (state: RootState) => ({
      values: state.services.workWithDetails,
      language: state.language.language,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [childrenArray, setChildrenArray] = useState<
    Array<WorkChildrenInterface>
  >([]);

  const [childrenValues, setChildrenValues] = useState(
    {} as WorkChildrenInterface
  );

  const [elderlyArray, setElderlyArray] = useState<
    Array<WorkElderlyValuesInterface>
  >([]);

  const [elderlyValues, setElderlyValues] = useState(
    {} as WorkElderlyValuesInterface
  );

  const [otherArray, setOtherArray] = useState<Array<WorkOtherValuesInterface>>(
    []
  );

  const [otherValues, setOtherValues] = useState(
    {} as WorkOtherValuesInterface
  );

  const [hide, setHide] = useState({
    childers: false,
    elder: false,
    other: false,
  });

  const addFormFields = () => {
    setChildrenArray([
      ...childrenArray,
      { id: childrenArray?.length + 1, ...childrenValues },
    ]);
    setChildrenValues({
      title_of_the_role_children: "",
      duration_of_time_children: "",
      key_responsibilities_children: "",
    });
  };

  const addFormFields2 = () => {
    setElderlyArray([
      ...elderlyArray,
      { id: elderlyArray?.length + 1, ...elderlyValues },
    ]);
    setElderlyValues({
      title_of_the_role_elderly: "",
      duration_of_time_elderly: "",
      Key_responsibilities_elderly: "",
    });
  };

  const addFormFields3 = () => {
    setOtherArray([
      ...otherArray,
      { id: otherArray?.length + 1, ...otherValues },
    ]);
    setOtherValues({
      title_of_the_role_other: "",
      duration_of_time_other: "",
      Key_responsibilities_other: "",
    });
  };

  const handleRemove = (i: number) => {
    const value = [...childrenArray];
    value.splice(i, 1);
    setChildrenArray(value);
  };

  const handleRemove2 = (i: number) => {
    const value = [...elderlyArray];
    value.splice(i, 1);
    setElderlyArray(value);
  };

  const handleRemove3 = (i: number) => {
    const value = [...otherArray];
    value.splice(i, 1);
    setOtherArray(value);
  };

  useEffect(() => {
    dispatch(
      updateWorkWithInformation({
        children_work_exp_details: JSON.stringify(childrenArray),
      })
    );
  }, [dispatch, childrenArray]);

  useEffect(() => {
    dispatch(
      updateWorkWithInformation({
        elderly_people_work_exp_details: JSON.stringify(elderlyArray),
      })
    );
  }, [dispatch, elderlyArray]);

  useEffect(() => {
    dispatch(
      updateWorkWithInformation({
        other_work_exp_details: JSON.stringify(otherArray),
      })
    );
  }, [dispatch, otherArray]);

  const {
    experience,
    duration,
    Roletitle,
    responsibilities,
    experience2,
    experience3,
    experience4,
    workExperience,
    submit,
    add,
  } = language.workwithus.workExperience;

  const { Specify } = language.workwithus.personalDetails;
  const { YesOrNo } = language.workwithus;

  return (
    <FlexWrapper noMargin noPadding justifyContent="space-between">
      <DropdownWrapper width="100%">
        <FormData
          value={
            values?.experience_with_children
              ? values?.experience_with_children
              : ""
          }
          width="45%"
          onChange={(value: string) => {
            setHide({
              ...hide,
              childers: false,
            });
            dispatch(
              updateWorkWithInformation({
                experience_with_children: value,
              })
            );
          }}
          label={experience}
          isRequired
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
      {values?.experience_with_children === YesOrNo[0]?.name && (
        <>
          {childrenArray?.map((list, index) => {
            return (
              <>
                <DropdownWrapper width="30%">
                  <FormData value={list?.title_of_the_role_children} />
                </DropdownWrapper>
                <DropdownWrapper width="30%">
                  <FormData value={list?.duration_of_time_children} />
                </DropdownWrapper>
                <DropdownWrapper width="30%">
                  <FormData value={list?.key_responsibilities_children} />
                </DropdownWrapper>
                <AdditionButton
                  className="mt-3"
                  onClick={() => {
                    handleRemove(index);
                  }}
                >
                  -
                </AdditionButton>
              </>
            );
          })}
          {/* {childrenArray?.length === 3 && (
            <AdditionButton
              style={{ marginLeft: "10px" }}
              onClick={() => {
                childrenArray.push({
                  id: childrenArray?.length + 1,
                  ...childrenValues,
                });
                setHide({
                  ...hide,
                  childers: true,
                });
              }}
            >
              {submit}
            </AdditionButton>
          )} */}
          {hide.childers ||
            (childrenArray?.length !== 3 && (
              <>
                <DropdownWrapper width="45%">
                  <FormData
                    value={childrenValues?.title_of_the_role_children}
                    onChange={(value: string) => {
                      setChildrenValues({
                        ...childrenValues,
                        title_of_the_role_children: value,
                      });
                    }}
                    label={Roletitle}
                    isRequired
                  />
                </DropdownWrapper>
                <DropdownWrapper width="45%">
                  <FormData
                    value={childrenValues?.duration_of_time_children}
                    onChange={(value: string) => {
                      setChildrenValues({
                        ...childrenValues,
                        duration_of_time_children: value,
                      });
                    }}
                    label={duration}
                    isRequired
                  />
                </DropdownWrapper>{" "}
                <DropdownWrapper width="100%">
                  <FormData
                    value={childrenValues?.key_responsibilities_children}
                    multiline
                    onChange={(value: string) => {
                      setChildrenValues({
                        ...childrenValues,
                        key_responsibilities_children: value,
                      });
                    }}
                    label={responsibilities}
                    isRequired
                  />
                </DropdownWrapper>{" "}
                <div style={{ display: "flex" }} className="mt-4">
                  <AdditionButton
                    disabled={childrenValues?.title_of_the_role_children === ""}
                    onClick={addFormFields}
                  >
                    {add}
                  </AdditionButton>{" "}
                  <AdditionButton
                    style={{ marginLeft: "10px" }}
                    disabled={childrenValues?.title_of_the_role_children === ""}
                    onClick={() => {
                      childrenArray.push({
                        id: childrenArray?.length + 1,
                        ...childrenValues,
                      });

                      setHide({
                        ...hide,
                        childers: true,
                      });
                    }}
                  >
                    {submit}
                  </AdditionButton>
                </div>
              </>
            ))}
        </>
      )}
      <DropdownWrapper
        width="100%"
        className={
          values?.experience_with_children === YesOrNo[0]?.name ? "mt-5" : ""
        }
      >
        <FormData
          value={
            values?.experience_with_elderly
              ? values?.experience_with_elderly
              : ""
          }
          width="45%"
          onChange={(value: string) => {
            setHide({
              ...hide,
              elder: false,
            });
            dispatch(
              updateWorkWithInformation({
                experience_with_elderly: value,
              })
            );
          }}
          label={experience2}
          isRequired
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
      {values?.experience_with_elderly === YesOrNo[0]?.name && (
        <>
          {" "}
          {elderlyArray?.map((list, index) => {
            return (
              <>
                <DropdownWrapper width="30%">
                  <FormData value={list?.title_of_the_role_elderly} />
                </DropdownWrapper>
                <DropdownWrapper width="30%">
                  <FormData value={list?.duration_of_time_elderly} />
                </DropdownWrapper>
                <DropdownWrapper width="30%">
                  <FormData value={list?.Key_responsibilities_elderly} />
                </DropdownWrapper>
                <AdditionButton
                  className="mt-3"
                  onClick={() => {
                    handleRemove2(index);
                  }}
                >
                  -
                </AdditionButton>
              </>
            );
          })}
          {/* {elderlyArray?.length === 3 && (
            <AdditionButton
              style={{ marginLeft: "10px" }}
              onClick={() => {
                elderlyArray?.push({
                  id: elderlyArray?.length + 1,
                  ...elderlyValues,
                });

                setHide({
                  ...hide,
                  elder: true,
                });
              }}
            >
              {submit}
            </AdditionButton>
          )} */}
          {hide?.elder ||
            (elderlyArray?.length !== 3 && (
              <>
                <DropdownWrapper width="45%">
                  <FormData
                    value={elderlyValues?.title_of_the_role_elderly}
                    onChange={(value: string) => {
                      setElderlyValues({
                        ...elderlyValues,
                        title_of_the_role_elderly: value,
                      });
                    }}
                    label={Roletitle}
                    isRequired
                  />
                </DropdownWrapper>
                <DropdownWrapper width="45%">
                  <FormData
                    value={elderlyValues?.duration_of_time_elderly}
                    onChange={(value: string) => {
                      setElderlyValues({
                        ...elderlyValues,
                        duration_of_time_elderly: value,
                      });
                    }}
                    label={duration}
                    isRequired
                  />
                </DropdownWrapper>{" "}
                <DropdownWrapper width="100%">
                  <FormData
                    value={elderlyValues?.Key_responsibilities_elderly}
                    multiline
                    onChange={(value: string) => {
                      setElderlyValues({
                        ...elderlyValues,
                        Key_responsibilities_elderly: value,
                      });
                    }}
                    label={responsibilities}
                    isRequired
                  />
                </DropdownWrapper>{" "}
                <div style={{ display: "flex" }} className="mt-4">
                  <AdditionButton
                    disabled={elderlyValues?.title_of_the_role_elderly === ""}
                    onClick={addFormFields2}
                  >
                    {add}
                  </AdditionButton>{" "}
                  <AdditionButton
                    style={{ marginLeft: "10px" }}
                    disabled={elderlyValues?.title_of_the_role_elderly === ""}
                    onClick={() => {
                      elderlyArray?.push({
                        id: elderlyArray?.length + 1,
                        ...elderlyValues,
                      });
                      setHide({
                        ...hide,
                        elder: true,
                      });
                    }}
                  >
                    {submit}
                  </AdditionButton>
                </div>
              </>
            ))}
        </>
      )}
      {values?.experience_with_elderly === YesOrNo[0]?.name && (
        <DropdownWrapper width="100%"></DropdownWrapper>
      )}
      <DropdownWrapper
        width="45%"
        className={
          values?.experience_with_elderly === YesOrNo[0]?.name ? "mt-5" : ""
        }
      >
        <FormData
          value={
            values?.i_have_studies_and_or_work_experience_in_healthcare
              ? values?.i_have_studies_and_or_work_experience_in_healthcare
              : ""
          }
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                i_have_studies_and_or_work_experience_in_healthcare: value,
              })
            );
          }}
          label={experience3}
          isRequired
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
      {values?.i_have_studies_and_or_work_experience_in_healthcare ===
        YesOrNo[0]?.name && (
        <DropdownWrapper
          width="45%"
          className={
            values?.experience_with_elderly === YesOrNo[0]?.name ? "mt-5" : ""
          }
        >
          <FormData
            value={values?.studies_or_work_experience_in_healthcare}
            onChange={(value: string) => {
              dispatch(
                updateWorkWithInformation({
                  studies_or_work_experience_in_healthcare: value,
                })
              );
            }}
            label={Specify}
            isRequired
            dropdownList={workExperience}
          />
        </DropdownWrapper>
      )}
      <DropdownWrapper width="100%">
        <FormData
          value={
            values?.other_work_experience ? values?.other_work_experience : ""
          }
          onChange={(value: string) => {
            setHide({
              ...hide,
              other: false,
            });
            dispatch(
              updateWorkWithInformation({
                other_work_experience: value,
              })
            );
          }}
          width="45%"
          label={experience4}
          isRequired
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
      {values?.other_work_experience === YesOrNo[0]?.name && (
        <>
          {otherArray?.map((list, index) => {
            return (
              <>
                <DropdownWrapper width="30%">
                  <FormData value={list?.title_of_the_role_other} />
                </DropdownWrapper>
                <DropdownWrapper width="30%">
                  <FormData value={list?.duration_of_time_other} />
                </DropdownWrapper>
                <DropdownWrapper width="30%">
                  <FormData value={list?.Key_responsibilities_other} />
                </DropdownWrapper>
                <AdditionButton
                  className="mt-3"
                  onClick={() => {
                    handleRemove3(index);
                  }}
                >
                  -
                </AdditionButton>
              </>
            );
          })}
          {/* {otherArray?.length === 3 && (
            <AdditionButton
              style={{ marginLeft: "10px" }}
              onClick={() => {
                otherArray?.push({
                  id: otherArray?.length + 1,
                  ...otherValues,
                });

                setHide({
                  ...hide,
                  other: true,
                });
              }}
            >
              {submit}
            </AdditionButton>
          )} */}
          {hide?.other ||
            (otherArray?.length !== 3 && (
              <>
                <DropdownWrapper width="45%">
                  <FormData
                    value={otherValues?.title_of_the_role_other}
                    onChange={(value: string) => {
                      setOtherValues({
                        ...otherValues,
                        title_of_the_role_other: value,
                      });
                    }}
                    label={Roletitle}
                    isRequired
                  />
                </DropdownWrapper>
                <DropdownWrapper width="45%">
                  <FormData
                    value={otherValues?.duration_of_time_other}
                    onChange={(value: string) => {
                      setOtherValues({
                        ...otherValues,
                        duration_of_time_other: value,
                      });
                    }}
                    label={duration}
                    isRequired
                  />
                </DropdownWrapper>{" "}
                <DropdownWrapper width="100%">
                  <FormData
                    value={otherValues?.Key_responsibilities_other}
                    onChange={(value: string) => {
                      setOtherValues({
                        ...otherValues,
                        Key_responsibilities_other: value,
                      });
                    }}
                    multiline
                    label={responsibilities}
                    isRequired
                  />
                </DropdownWrapper>{" "}
                <div style={{ display: "flex" }} className="mt-4">
                  <AdditionButton
                    disabled={otherValues?.title_of_the_role_other === ""}
                    onClick={addFormFields3}
                  >
                    {add}
                  </AdditionButton>{" "}
                  <AdditionButton
                    style={{ marginLeft: "10px" }}
                    disabled={otherValues?.title_of_the_role_other === ""}
                    onClick={() => {
                      otherArray?.push({
                        id: otherArray?.length + 1,
                        ...otherValues,
                      });
                      setHide({
                        ...hide,
                        other: true,
                      });
                    }}
                  >
                    {submit}
                  </AdditionButton>
                </div>
              </>
            ))}
        </>
      )}
    </FlexWrapper>
  );
};

export default WorkExperience;
