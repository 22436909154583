import { ReactElement, useEffect, useState } from "react";
import {
  FirstWrapper,
  HomeSectiononeWrapper,
  SecondWarpper,
  ButtonWrapper,
  SectionButton,
  FirstContainer,
} from "./styledcomponents";
import {
  SectionButtonWrapperOne,
  SectionButtonWrapperThree,
  SectionButtonWrapperTwo,
} from "./subSections/helper";
import { ColumnWrapper, RowWrapper } from "./subSections/styledcomponents";
import Footer from "../../components/Footer";
import { Movetop, PageWrapper, StyledButton } from "../../components";
import SliderTestimonials from "./subSections/slider";
import {
  ImageBackgroundReducer,
  SectionContainer,
  SectionWrapper,
  StyledText,
  Subtitles,
  Title,
  TitleWrapper,
} from "../styledComponents";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getTestimonialLists } from "../../state/services/api";
import { AppDispatch, RootState } from "../../state/store";
import { useNavigate } from "react-router";

const HomePage = (): ReactElement => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  Movetop();
  const dispatch: AppDispatch = useDispatch();
  const [prices, setPrices] = useState("seniors");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTestimonialLists());
  }, [dispatch]);

  const { forSeniors, forFamilies, forCompanies, contactus } = language?.header;
  const { h1, h2, h3, h4, h5, h6 } = language?.service;

  return (
    <PageWrapper>
      <HomeSectiononeWrapper>
        <ImageBackgroundReducer />
        <FirstWrapper>
          <FirstContainer>
            <StyledText
              fontFamily="Abuget"
              fontWeight={400}
              font="59px"
              color="#ff4880"
              fontStyle="normal"
            >
              {h1}
            </StyledText>
            <Title
              fontFamily="Playfair Display, serif"
              fontWeight={700}
              font="70px"
              color="#ffffff"
              fontStyle="normal"
              mFontStyle="340%"
            >
              {h2} <br /> {h3}
            </Title>
            <StyledButton
              background={"#ff4880"}
              fontSize={"14px"}
              hoverBackground={"#242864"}
              border={"none"}
              color={"white"}
              margin={"10px 0 0"}
              onClick={() => {
                navigate("/contact");
              }}
            >
              {contactus}
            </StyledButton>
          </FirstContainer>
        </FirstWrapper>
        <SecondWarpper> </SecondWarpper>
      </HomeSectiononeWrapper>
      <SectionContainer background="white">
        <SectionWrapper>
          <ButtonWrapper>
            <SectionButton
              onClick={() => setPrices("seniors")}
              style={
                prices === "seniors"
                  ? { color: "white", backgroundColor: "#ff4880" }
                  : { color: "black", backgroundColor: "white" }
              }
            >
              {forSeniors}
            </SectionButton>
            <SectionButton
              onClick={() => setPrices("families")}
              style={
                prices === "families"
                  ? { color: "white", backgroundColor: "#ff4880" }
                  : { color: "black", backgroundColor: "white" }
              }
            >
              {forFamilies}
            </SectionButton>
            <SectionButton
              onClick={() => setPrices("companies")}
              style={
                prices === "companies"
                  ? { color: "white", backgroundColor: "#ff4880" }
                  : { color: "black", backgroundColor: "white" }
              }
            >
              {forCompanies}
            </SectionButton>
          </ButtonWrapper>
          {prices === "seniors" && <SectionButtonWrapperOne />}
          {prices === "families" && <SectionButtonWrapperTwo />}
          {prices === "companies" && <SectionButtonWrapperThree />}
        </SectionWrapper>
      </SectionContainer>
      <SectionContainer background="#f6f8fe">
        <SectionWrapper>
          <RowWrapper noMargin>
            <ColumnWrapper lg={12}>
              <TitleWrapper>
                <Title
                  font="52px"
                  fontWeight={500}
                  color="#242864"
                  lineHeight="70px"
                >
                  {h4}
                  <span>{h5}</span>
                </Title>
                <Subtitles
                  fontFamily="Lato, sans-serif"
                  fontWeight={400}
                  font="16px"
                  color="#686976"
                  lineHeight="26px"
                  fontStyle="normal"
                >
                  {h6}
                </Subtitles>{" "}
              </TitleWrapper>
              <SliderTestimonials />
            </ColumnWrapper>
          </RowWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default HomePage;
