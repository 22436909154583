const swedish = {
  header: {
    docare: "DoCare",
    services: "Tjänster",
    prices: "Priser",
    more: "Mer",
    contactus: "Kontakta oss",
    workWithUs: "Jobba hos oss",
    forSeniors: "För Äldre",
    forFamilies: "För Familjer",
    forCompanies: "För Företag",
    priceDropdowns: [
      {
        title: "För Äldre",
        to: "/pricesSeniors",
      },
      {
        title: "För Familjer",
        to: "/pricesFamilies",
      },
      {
        title: "För Företag",
        to: "/pricesCompanies",
      },
    ],
    moreDropdowns: [
      {
        title: "Om oss",
        to: "/about-us",
      },

      {
        title: "Vanliga frågor",
        to: "/moreFaq",
      },
    ],
    mobile: [
      {
        title: "Tjänster",
        to: "/",
      },
      {
        title: "Priser",
        to: "",
        childs: [
          {
            title: "För Äldre",
            to: "/pricesSeniors",
          },
          {
            title: "För Familjer",
            to: "/pricesFamilies",
          },
          {
            title: "För Företag",
            to: "/pricesCompanies",
          },
        ],
      },
      {
        title: "Mer",
        to: "",
        childs: [
          {
            title: "Om oss",
            to: "/about-us",
          },

          {
            title: "Vanliga frågor",
            to: "/moreFaq",
          },
        ],
      },
      {
        title: "Kontakta oss",
        to: "/contact",
      },
      {
        title: "Jobba hos oss",
        to: "/workwithus",
      },
    ],
  },
  footer: {
    ourservices: "Våra tjänster",
    ourprices: "Priser",
    aboutus: "Om oss",
    news: "Nyheter",
    faq: "Vanliga frågor",
    privacypolicy: "Integritetspolicy",
    mail1: "Håll dig uppdaterad med våra nyheter!",
    mail2: "Ange e-post",
    mail3: "",
    copyr1: "Copyright 2024.",
    copyr2: "Bilder freepik",
    h1: "Söker du extrajobb?",
    h2: "Om detta låter som dig, ",
    applynow: "ansök nu",
    h3: "för att anmäla ditt intresse för att bli en del av DoCare-teamet.",
    subscribe: "Skicka",
  },
  service: {
    h1: "Oavsett om det är för dig eller dina nära och kära",
    h2: "Vi gör det lätt att få",
    h3: "rätt hjälp",
    h4: "Vad våra kunder ",
    h5: "säger...",
    h6: "Våra tjänster anpassas alltid för att möta våra kunders behov!",
  },
  forseniors: {
    h1: "Vi tycker att våra äldre förtjänar den bästa möjliga omsorgen!",
    h2: "DoCare tillhandahåller personlig matchning för att hjälpa dig att hitta en kompetent och engagerad hjälpare. Vi ger din familj trygghet och erbjuder hjälp till de som behöver extra hjälp för sina äldre föräldrar eller stöd till äldre som önskar hjälp med...",
    h3: "— Hushållssysslor",
    h4: "— Handla och matlagning",
    h5: "— Hjälp med ärenden",
    h6: "— Utomhusvistelser och sällskap",
    h7: "— Enkel tekniskt stöd",
    h8: "— Primär (anhörig) vårdare",
    h9: "Vårt mål är att erbjuda skräddarsydd och prisvärd hjälp!",
    h10: "LÄS MER OM VÅRA PRISER",
  },
  forfamilies: {
    h11: "Vi löser familjelivets pussel!",
    h12: "DoCare erbjuder personliga matchningstjänster för att hjälpa dig att hitta en kompetent familjehjälpare eller nanny/barnvakt som uppfyller dina behov. Oavsett om du är en förälder som söker hjälp, en familj i behov av långsiktigt stöd eller någon som behöver tillfällig hjälp, prioriterar vi din familjs välbefinnande och tillgodoser dina unika behov.",
    h13: "— Regelbunden läxhjälp",
    h14: "— Barnpassning",
    h15: "— Enklare hushållssysslor",
    h16: "— Lämna/hämta barn från förskola/skola/aktiviteter",
    h17: "Nanny/barnvakt med specifika språkkunskaper",
    h18: " Handla och matlagning",
  },
  forcompanies: {
    h21: "Vi hjälper era anställda att få en bättre balans mellan arbete och privatliv!",
    h22: "Våra tjänster bidrar till att skapa en positiv arbetsmiljö och ökar anställdas produktivitet och lojalitet.",
    h23: "— Erbjud hjälp med äldre och/eller nanny/barnvaktstjänster som en personalförmån",
    h24: "— Välj mellan flera DoCare förmånsprogram",
    h25: "— Erbjud våra tjänster under arbetstider, evenemang, affärsresor och konferenser",
    h26: "— Visa att du bryr dig om dina anställdas familjer och välbefinnande genom att erbjuda DoCare-tjänster",
  },
  prices: {
    seniors: {
      section1: {
        title1: "Vi",
        title2: "för de äldre!",
      },
      section2: {
        s2t1: "DoCare erbjuder de mest konkurrenskraftiga",
        s2t2: "priser för äldreomsorgen",
        subtitle:
          "Med våra flexibla och kostnadseffektiva erbjudanden kan du få den bästa hjälpen som passar dina äldre.",
        cardHours: "per timme",
        constactUs: "Kontakta oss",
        vipCard: [
          {
            border: true,
            text: "Dedikerad hjälpare**",
          },
          {
            border: true,
            text: "1-25 besök/vecka",
          },
          {
            border: true,
            text: "Få 2 timmar gratis efter var tredje månad",
          },
          {
            border: false,
            text: "Gratis ombokning upp till 24 timmar",
          },
        ],
        basicCard: [
          {
            border: true,
            text: "Dedikerad hjälpare**",
          },
          {
            border: true,
            text: "Minst 1 besök/månad",
          },
          {
            border: true,
            text: "Tre månaders bindningstid",
          },
          {
            border: false,
            text: "Gratis ombokning upp till 24 timmar",
          },
        ],
        payCard: [
          {
            border: true,
            text: "Dedicated helper**",
          },
          {
            border: true,
            text: "Inga minimumbesök",
          },
          {
            border: true,
            text: "Ingen bindningstid",
          },
          {
            border: false,
            text: "Gratis avbokning upp till 48 timmar",
          },
        ],
        footer1:
          "Priserna inkluderar moms och efter RUT-avdrag. Minimum besökslängd 1,5 timmar. Endast Basic- och VIP-prisplaner erbjuder ett kostnadsfritt introduktionsbesök och baseras på ett förutbestämt schema.",
        footer2:
          "**Samma hjälpare kan inte garanteras för Pay as you go prisplan.",
        contactUs: "Kontakta oss",
        giftVoucher: "för att köpa DoCare presentkort!",
        perHour: "per timme",
        Refer: "Värva",
        HundredKR: "100kr/100kr",
        friend: "en vän",
        discountPara:
          "När du värvar en vän till oss, får ni båda 100kr rabatt på nästa beställning. ",
        discountPara2: "för din rabattkod.",
      },
      seniorsection3: {
        seniorst1: "Vi hjälper äldre att leva ett meningsfullt liv med ",
        seniorst2: "våra tjänster",
        seniorst3: "som inkluderar...",
        card: {
          card1title: "Hjälp med dagliga rutiner",
          card1subtitle:
            "Vår dedikerade hjälpare kan erbjuda hjälp till äldre i hemmet med städning, enkla hushållssysslor och hygienrelaterade uppgifter som att byta sängkläder, tvätta, stryka och med mera. Dessutom kan hjälparen hjälpa till med att handla, matlagning och måltidsplanering. ",
          card2title: "Hjälp med ärenden",
          card2subtitle:
            "Vår engagerade hjälpare hjälper dig gärna med olika ärenden, som att hämta leveranser, handla, med mera. Dessutom kan hjälparen följa med till ett läkarbesök eller hjälpa till med transport.",
          card3title: "Fysiskt och psykiskt välbefinnande",
          card3subtitle:
            "Med hjälp av våra dedikerade hjälpare kommer äldre att slippa gå ut ensam. Vi hjälper äldre att förbättra sitt fysiska och psykiska välbefinnande genom att ta dem på promenader, delta i minnesaktiverande aktiviteter eller helt enkelt dansa till musik.",
          card4title: "Teknisk hjälp",
          card4subtitle:
            "Vår dedikerade hjälpare kan hjälpa till med teknik, som att installera eller använda smarta telefoner, smarta TV:er, ställa in kanaler, organisera digitala medier och skapa och underhålla socialamediekonton. Dessutom kan de hjälpa till med nedladdning av ljudböcker eller mobilappar.",
          card5title: "Samtal och sällskap",
          card5subtitle:
            "För att göra vardagen roligare kan vår hjälpare bara ge ett sällskap,ett välvilligt öra, läsa en bok eller ha fika tillsammans. De kan också hjälpa med hobbies som att måla, lösa korsord eller spela kort. Dessutom följer vår hjälpare gärna med på bio eller någon annan kulturaktivitet.",
          card6title: "Hjälp primär (anhörig) vårdare",
          card6subtitle:
            "Att vara en primärvårdare kan vara utmanande, så det kan vara mycket fördelaktigt att ha stöd. Våra dedikerade hjälpare hjälper en familjemedlem som tar hand om en äldre.",
        },
      },
      seniorssection4: {
        header1: "Hur fungerar det?",
        header2:
          "Vi tror att det är viktigt att dina behov tillgodoses i varje skede av din DoCare-resa!",
        cardtitle1: "Identifiera dina behov",
        cardtext1:
          "Vi hjälper dig att välja den bästa lösningen för dina behov.",
        cardtitle2: "Matchning med hjälpare",
        cardtext2: "Vi matchar dig med en kompetent hjälpare för bästa vård.",
        cardtitle3: "Kostnadsfritt introduktionsbesök",
        cardtext3:
          "Äldre och/eller familjemedlemmar träffar hjälparen för att lära känna varandra.",
        cardtitle4: "Dags att börja",
        cardtext4:
          "Vi följer upp ditt introduktionsbesök och bestämmer om hur vi ska fortsätta.",
      },
    },
    families: {
      title: "löser familjens vardagspussel!",
      familiessection1: {
        s1t1: " DoCare erbjuder de mest konkurrenskraftiga",
        s1t2: " priser för nanny/barnpassningstjänster ",
        s1t3: "Med våra flexibla och kostnadseffektiva erbjudanden kan du få den bästa hjälpen som passar din familj.",
      },
      familiessection2: {
        s2t1: "*Priserna inkluderar moms och efter RUT-avdrag. Minimum besökslängd 1,5 timmar. Endast Basic- och VIP-prisplaner erbjuder ett kostnadsfritt introduktionsbesök och baseras på ett förutbestämt schema. ",
        s2t2: " **Samma hjälpare kan inte garanteras för Pay as you go prisplan.",
        s2t3: "Kontakta oss",
        s2t4: "för att köpa DoCare presentkort!",
      },
      familiessection3: {
        header: "Hur kan du dra nytta av Rutavdrag med våra tjänster...",
        s3t1: "Det är möjligt att tillämpa rutavdrag när du använder våra tjänster, dock rekommenderar vi att du kontrollerar ditt personliga maxbelopp för rutavdrag. På Skatteverkets hemsida kan du få mer information om rutavdrag.",
        s3t2: "För att säkerställa att du är korrekt fakturerad, vänligen informera oss om du har använt hela ditt rutavdragsbelopp.",
        Learnmore: "Läs mer",
      },
      familiessection4: {
        header1: "Vi tar hand om dina barn när det behövs!",
        header2: "Våra tjänster inkluderar...",
        card: {
          card1title: "Barnvakt/nanny tjänster",
          card1subtitle:
            "Vår engagerade barnvakt/nanny kan hjälpa till med olika uppgifter som att lämna och hämta barn från förskola/skola/aktiviteter, hjälpa till med läxorna, lekaktiviteter, med mera.",
          card2title: "Hushållssysslor",
          card2subtitle:
            "Vår engagerade barnvakt/nanny kan också hjälpa till med enkla hushållsysslor såsom matlagning, städning av lekytor, lätt städning, tvätt eller andra sysslor.",
          card3title: "Flexibilitet",
          card3subtitle:
            "Familjer kan få regelbunden hjälp för att göra vardagen enklare, eller de kan också få hjälp när de behöver en dejtkväll eller ensamtid, eller till och med för speciella tillfällen som ett bröllop eller ett jobbevenemang.",
          card4title: "Erfarenhet och säkerhet",
          card4subtitle:
            "Våra engagerade barnvakter/nannys är erfarna och har genomgått screening, bakgrundskontroller, och de flesta av dem har första hjälpen-certifiering.",
          card5title: "Kommunikation och samarbete",
          card5subtitle:
            "Vi är medvetna om vikten av att familjer och våra barnvakter/nannys samarbetar och kommunicerar effektivt. Vi följer upp regelbundet för att hålla oss informerade om eventuella ändringar, förväntningar eller specifika instruktioner som rör dina familjebehov.",
          card6title: "Hjälp primär (anhörig) vårdare",
          card6subtitle:
            "Att vara en primärvårdare kan vara utmanande, så det kan vara mycket fördelaktigt att ha stöd. Våra dedikerade hjälpare hjälper en familjemedlem som tar hand om ett barn med speciella behov.",
        },
      },
      familiessection5: {
        header: " Hur fungerar det?",
        title:
          "Vi tror att det är viktigt att dina behov tillgodoses i varje skede av din DoCare-resa!",
        card1title: "Identifiera dina behov",
        card1s1:
          "Vi hjälper dig att välja den bästa lösningen för dina familjebehov.",
        card2title: "Matchning med barnvakt/nanny",
        card1s2: "Vi matchar din familj med en kompetent nanny/barnvakt.",
        card3title: "Kostnadsfritt introduktionsbesök",
        card1s3:
          "Din familj träffar en nanny/barnvakt för att lära känna varandra",
        card4title: "Dags att börja",
        card1s4:
          " Vi följer upp ditt introduktionsbesök och bestämmer om hur vi ska fortsätta.",
      },
    },
    company: {
      card1: "Erbjud dina anställda den bästa förmånen",
      card2: "Hjälp anställda att hitta en balans mellan arbete och privatliv",
      card3: "Attrahera och behåll personal",
      card4: "Skapa en familjevänlig arbetsmiljö",
      companyt1: " DoCare-tjänster är det mest ",
      compantt2: "  eftertraktade förmån ",
      companyt3: "för dina anställdas välbefinnande",
      companyts1: " DoCare-tjänster är ",
      compantts2: " den ",
      companyts3: " mest eftertraktade ",
      companyts4: " förmånen ",
      companyts5: " för dina anställdas välbefinnande",
      companysection1: {
        header1: "DoCare är det bästa sättet att visa att du bryr dig om",
        header2: "  dina medarbetare!",
        content:
          "DoCare-tjänsterna blir allt mer populära bland företag som en förmån som är utformad för att attrahera och behålla anställda, särskilt de som är arbetande föräldrar eller har äldre föräldrar. Genom att samarbeta med DoCare visar du att du förstår de utmaningar som arbetande föräldrar eller vuxna barn till åldrande föräldrar möter när de balanserar sina personliga och professionella liv. Här är några viktiga aspekter att tänka på när det gäller våra tjänster för företag:",
      },
      companycard: {
        card1: "Program för anställdas välbefinnande",
        card2: "On-demand-program",
        card3: " Samarbete med DoCare",
        card4: "Presentkort",
        cardtext1:
          "Erbjud dina anställda DoCare-tjänster för att lindra ekonomiska utmaningar för arbetande föräldrar och personal med äldre föräldrar. Dessutom kan rutavdrag nyttjas för våra tjänster.",
        cardtext2:
          " Företag kan använda våra tjänster för barnpassning när deras anställda vill delta i arbetsrelaterade evenemang.",
        cardtext3:
          "Samarbete med DoCare ger 10 % rabatt på de tjänster som dina anställda använder.",
        cardtext4:
          "DoCare presentkort är ett fantasktiskt sätt att visa uppskattning till dina anställda.",
      },

      companysection2: {
        companys2t1:
          "Vi är dedikerade till att hjälpa dina anställda och ge dem bästa möjliga service.",
        TOLEARNMORE: "FÖR MER INFORMATION,",
        CONTACTUS: "KONTAKTA OSS",
      },
    },
  },
  Aboutus: {
    title: " is a great way to show you care! ",
    title1: "Vi på ",
    title2: " bryr oss om dina nära och kära!",
    cardtttle:
      "Vi tror att dina nära och kära förtjänar den bästa hjälpen av högsta kvalitet!",
    cardp1:
      "DoCare erbjuder skräddarsydda tjänster för att möta behov av din familj, oavsett om de är små barn eller äldre föräldrar. Vi prioriterar säkerhet, hälsa och effektiv kommunikation.",
    ForSeniors: "För äldre",
    ForSeniorsp1:
      "För att hjälpa dina äldre matchar vi dem med en erfaren och engagerad hjälpare. Vi erbjuder våra tjänster både för personer som behöver extra hjälp med sina åldrande föräldrar eller äldre som vill ha anpassad omsorg.",
    ForFamilies: "För familjer",
    ForFamiliesp1:
      "Vi hjälper dig att lösa pusslet i det dagliga familjelivet, så att du kan bli en ännu bättre förälder, oavsett om du söker tillfälligt stöd eller långsiktig hjälp.",
    Becomepartof: "Joina ",
    DoCareteam: "DoCare-teamet!",
    cardp2:
      "DoCare erbjuder skräddarsydda lösningar för familjer med barnpassning och omsorg för äldre. Vårt mål är att bygga långsiktiga relationer med våra kunder genom att vara flexibla, prisvärda och dedikerade till att ge bästa stöd och hjälp. Som en del av DoCare-teamet kan du hjälpa oss att uppfylla våra kunders behov i varje skede av deras DoCare-resa!",
    Asa: "Som en",
    helper: " DoCare-hjälpare...",
    helper1:
      "- Du kommer att hjälpa äldre med enklare hushållssysslor, utomhusaktiviteter, sällskap, med mera...",
    helper2:
      "   - Du kommer att hjälpa familjer regelbundet eller vid behov med barnpassning, hämtning och lämning av barn på aktiviteter eller förskola, enklare hushållssysslor, med mera...",
    helper3: " – Du bestämmer när och var du vill jobba extra",
    helper4: " – Du har fyllt 16 år",
    helper5:
      " Om detta låter som dig, ansök nu för att anmäla ditt intresse för att joina vår DoCare-team.",
    Applynow: "Ansök nu",
    MeettheDoCareteam: "Välkommen till DoCare-teamet",
  },
  faq: {
    Frequently: "Vanliga frågor...",
    ForSeniors: " För Äldre",
    ForFamilies: "För Familjer",
    ForCompanies: "För Företag",
    ForCompaniesQ1: "Gäller Rutavdrag för DoCare-tjänster?",
    ForCompaniesQ1ans1:
      "Det är möjligt att tillämpa rutavdrag när du använder våra tjänster, dock rekommenderar vi att du kontrollerar ditt personliga maxbelopp för rutavdrag. På ",
    ForCompaniesQ1ans2: " Skatteverkets hemsida",
    ForCompaniesQ1ans3:
      "kan du få mer information om rutavdrag. För att säkerställa att du är korrekt fakturerad, vänligen informera oss om du har använt hela ditt rutavdragsbelopp.",
    ForCompaniesQ2: "Vad kan DoCare erbjuda till företag?",
    ForCompaniesQ2ans1:
      "DoCare har flera olika program som företag kan erbjuda sina anställda, inklusive personalförmåner, presentkort, samarbeten med DoCare och on-demand-program. Vänligen  ",
    ForCompaniesQ2ans2: "kontakta oss",
    ForCompaniesQ2ans3: "för mer information",
    ForCompaniesQ3:
      "Hur fungerar det när företag vill erbjuda DoCare-tjänster som personalförmån för anställda?",
    ForCompaniesQ3ans1:
      "När våra tjänster används som en personalförmån skickas fakturan med den anställdes uppgifter till arbetsgivaren. Arbetsgivaren drar sedan av den summan från den anställdes nettolön. Du kan använda rutavdrag på våra tjänster.",
    ForCompaniesQ4:
      "Hur kan en anställd använda DoCare-tjänster som en personalförmån? ",
    ForCompaniesQ4ans1:
      "En anställd måste bekräfta med sin arbetsgivare om kostnaden för våra tjänster kan dras av från lönen. Vänligen",
    ForCompaniesQ4ans2: "kontakta oss ",
    ForCompaniesQ4ans3: "för ytterligare information ",
    ForCompanies5: "Är DoCare-tjänster tillgängliga på kvällar eller helger?",
    ForCompaniesQ5ans1:
      "Ja, vi kan ordna hjälp för ditt företag oavsett när du behöver det. DoCare hjälpare finns tillgängliga för arbete dagtid, nattetid, helger och helgdagar.",
    ForCompaniesQ6: "Kan DoCare hjälpa företag under evenemang eller mässor?",
    ForCompaniesQ6ans1:
      "Ja, vi kan hjälpa till vid alla evenemang, främst med barnomsorg. Vänligen ",
    ForCompaniesQ6ans2: " kontakta oss ",
    ForCompaniesQ6ans3: "för mer information. ",
    senoirsfaq: [
      {
        question: "Kan jag välja min egen hjälpare?",
        answer: `DoCare matchar dig med en dedikerad hjälpare baserat på dina behov. Om du är missnöjd med hjälparen vi valt kan du helt enkelt begära en ersättare. Kundnöjdhet är mycket viktigt för oss och vi ser till att hjälparen vi har valt åt dig är omtyckt av dig och din familj. Dessutom, efter varje besök, måste var och en av våra hjälpare skicka in rapport/feedback så att vi kan vara proaktiva i att hjälpa våra kunder.`,
      },
      {
        question:
          "Vilka kontroller utförs innan DoCare-hjälpare utses till en kund?",
        answer: `Kundens säkerhet är mycket viktig för oss. Efter en video- och/eller personlig intervju genomförs en bakgrundskontroll av våra hjälpare, alltid inklusive ett giltigt utdrag ur belastningsregistret.
     `,
      },
      {
        question: `Vad är ett kostnadsfritt "introduktionsbesök"?`,
        answer: ` Syftet med introduktionsbesöket är att lära känna din hjälpare, så du behöver inte förbereda dig för det. Det rekommenderas att en familjemedlem deltar i detta första möte. Du kan gå igenom de uppgifter du behöver hjälp med, samt eventuella aktiviteter ni kan göra tillsammans. Vi kommer att kontakta dig för att följa upp besöket och planera framtida besök. Om något oväntat händer, till exempel en sjukdom, kan detta introduktionsbesök alltid bokas om.`,
      },
      {
        question: "Vad händer om jag måste boka om ett planerat besök?",
        answer: `Om du behöver boka om ditt besök, vänligen kontakta DoCare så snart som möjligt. Kontakta oss för att avtala en ny tid eller avbokning under tidsperioden som anges i din prisplan för att undvika en avbokningsavgift. Observera att ny tid för besöket beror på din hjälpares tillgänglighet.`,
      },
      {
        question: `Vad innebär att hjälpa "primär (anhörig) vårdare"?`,
        answer: `Det innebär att DoCare-hjälpare kan avlasta en familjemedlem som tar hand om en äldre person. Det är viktigt att notera att hjälparen får inte erbjuda hjälp av medicinska behov eller medicinering.`,
      },
      {
        question: `Vem står för kostnaderna för de aktiviteter som kostar (som museibesök, bio, fika etc)?`,
        answer: `Kunden står för de kostnader som är förknippade med aktiviteterna för både dig och din hjälpare. Observera att rutavdrag kan inte tillämpas på dessa utgifter.`,
      },
      {
        question: "Hur betalar jag för DoCare-tjänster?",
        answer: `En faktura kommer att skickas till dig via e-post i enlighet med din serviceplan. Du får inte ge kontanter till din hjälpare eftersom de inte ansvarar för att hantera betalningar`,
      },
      {
        question:
          "Vad händer om min hjälpare inte kan slutföra mitt planerade besök?",
        answer: `Det är möjligt att ditt besök måste skjutas upp på grund av oförutsedda händelser som sjukdom. Om detta händer kommer vi att kontakta dig först och om du fortfarande vill att besöket ska ske kommer vi att försöka hitta en ersättare.
    `,
      },
    ],
    familiesfaq: [
      {
        question: " Kan jag välja min egen nanny/barnvakt?",
        answer: `DoCare matchar dig med en dedikerad nanny/barnvakt baserat på dina behov. Om du är missnöjd med din nanny/barnvakt vi valt kan du helt enkelt begära en ersättare. Kundnöjdhet är mycket viktigt för oss och vi ser till att nannyn/barnvakten vi har valt åt dig är omtyckt av dig och din familj. Dessutom, efter varje besök, måste nannyn/barnvakten skicka in rapport/feedback så att vi kan vara proaktiva i att hjälpa våra kunder.`,
      },
      {
        question:
          "Vilka kontroller utförs innan en DoCare nanny/barnvakt utses till en familj?",
        answer: `Kundens säkerhet är mycket viktig för oss. Efter en video- och/eller personlig intervju genomförs en bakgrundskontroll av våra nannys/barnvakter, alltid inklusive ett giltigt utdrag ur belastningsregistret.`,
      },
      {
        question: `Vad är ett kostnadsfritt "introduktionsbesök"?`,
        answer: `Syftet med introduktionsbesöket är att lära känna din nanny/barnvakt och att gå igenom de uppgifter du behöver hjälp  med. Vi kommer att kontakta dig för att följa upp besöket och planera framtida besök. Om något oväntat händer, till exempel en sjukdom, kan detta introduktionsbesök alltid bokas om.`,
      },
      {
        question: `Är det möjligt att ha nanny/barnvakt endast för hämtning/lämning?`,
        answer: `Det är möjligt för DoCare nanny/barnvakt att hämta/lämna ditt barn på förskolan/skolan. Antalet besök per vecka är upp till dig, men varje besök måste vara minst 1,5 timme. Vår nanny/barnvakt kan också hjälpa till med att göra dina barn redo för förskola eller skola innan avlämning och hjälpa till med eventuella ytterligare uppgifter efter hämtning.`,
      },
      {
        question: `Är nannys/barnvakter tillgängliga på kvällar och/eller helger?`,
        answer: `Ja, vi kan ordna en nanny/barnvakt oavsett tider och dagar du önskar få hjälp. Våra nannys/barnvakter är tillgängliga för att jobba dagtid, nattetid, helger och helgdagar.`,
      },
      {
        question: `Kan jag få samma nanny/barnvakt varje gång?`,
        answer: `Ja, du får alltid samma nanny/barnvakt eftersom vi matchar dem med din familj utifrån dina behov. Detta är dock inte alltid möjligt för kunder som använder Pay as you go prisplan.`,
      },
      {
        question: "Hur betalar jag för DoCare-tjänster?",
        answer: `En faktura kommer att skickas till dig via e-post i enlighet med din serviceplan. Du får inte ge kontanter till din nanny/barnvakt eftersom de inte ansvarar för att hantera betalningar.`,
      },
      {
        question:
          "Kan jag få en nanny/barnvakt att hjälpa till med min nyfödda?",
        answer: `Ja, vi kan ordna med en nanny/barnvakt som tar hand om barn i alla åldrar, från nyfödda till de som är i skolåldern.`,
      },
    ],
  },
  Contactus: {
    contactus: "Kontakta oss",
    WithDoCare: "Med DoCare,",
    getting: " är det enkelt att komma igång...",
    assestl: "Om du har några frågor, kontakta oss så hjälper vi dig gärna!",
    Firstname: "Förnamn",
    Lastname: "Efternamn",
    contactby: "Hur vill du bli kontaktad",
    city: "Ange namn på staden där hjälpen behövs",
    Describe: "Beskriv vad du behöver hjälp med och hur ofta",
    submitalertmsg: "Din förfrågan har nu skickats!",
    SelectEnquiry: "Beskriv vad du behöver hjälp med och hur ofta",
    Sign: "Jag vill anmäla mig för DoCare nyheter",
    agree: "Jag samtycker till att mina uppgifter behandlas i enlighet med",
    policy: "integritetspolicyn",
    Submit: "Skicka",
    By: ["Via telefon", "Via e-post", "Båda"],
    Byphone: "Telefonnummer",
    Byemail: "Epost",
    cByemail: "Via e-post",
    cByphone: "Via telefon",
    Both: "Båda",
    Enquirytype: [
      "För äldre",
      "För familjer",
      "För företag",
      "Presentkort",
      "Värva en vän",
      "Övrig",
    ],
  },
  workwithus: {
    i: "Det innebär att vi kommer att titta på uppgifterna från belastningsregistret samt all information som finns i det svenska rättssystemet, vi kommer också att granska din kreditvärdering.",
    YesOrNo: [
      {
        id: "01",
        name: "Ja",
      },
      {
        id: "02",
        name: "Nej",
      },
    ],

    successmsg: "Din ansökan har skickats in!",
    alertmsg: "Fyll i alla obligatoriska fält innan du skickar din ansökan.",
    para: "Fyll i ansökan för att bli en del av DoCare-teamet. Vi kommer att kontakta dig efter att vi har granskat din ansökan.",
    mypersonalDetials: "Mina personuppgifter",
    myWorkExperience: "Min arbetslivserfarenhet",
    myEducation: "Min utbildning",
    myAvailability: "Min tillgänglighet",
    myLanguageSkills: "Mina språkkunskaper",
    otherInformation: "Övrig information",
    workWithUsPara:
      "DoCare ansvarar för att registrera de personuppgifter du lämnar i din ansökan. För mer information, se vår ",
    policy: "Integritetspolicy och användarvillkor",
    remainingPara:
      ". Genom att lämna in mina personuppgifter och ansökan till DoCare, bekräftar jag att:",
    tickboxOne:
      "Jag har läst och godkänner Integritetspolicy och användarvillkor",
    tickboxTwo:
      "Jag har lämnat fullständig och korrekt information i min ansökan, och jag förstår att om jag lämnar felaktig information kan min ansökan avvisas eller avslutas",
    tickboxThere:
      "Jag samtycker till att DoCare kan spara och bearbeta den information som lämnas och därefter samlas in, dela information om nya uppdrag och använda information för jobbrelaterade marknadsföringsändamål",
    submitButton: "Skicka",
    personalDetails: {
      areaofStudy: [
        {
          id: "01",
          name: "Heltid",
        },
        {
          id: "02",
          name: "Deltid",
        },
      ],
      firstName: "Förnamn",
      lastName: "Efternamn",
      address: "Adress",
      postCode: "Postnummer",
      areaName: "Ort",
      city: "Stad",
      contact: "Kontaktnummer",
      email: "E-post",
      dob: "Födelsedatum",
      dateFormate: "åååå/mm/dd",
      swadishBankAccnt: "Jag har ett svenskt bankkonto",
      example: "(till exempel, Stockholm)",
      select: "Välj",
      yes: "Ja",
      no: "Nej",
      preferedToWork: "Jag vill jobba:*",
      Seniors: "Med Äldre",
      ChildrenFamily: "Med barn/familjer",
      ChildrenSchool: "Inom skola/förskola",
      location: "Ange ditt föredragna arbetsområde",
      decription: "Beskriv kort varför du skulle vilja arbeta hos DoCare",
      hobbies: "Beskriv dina firitidsintresse/hobbies",
      currentDoing: "Berätta vad du sysslar med",
      student: "Jag är student",
      typeofstudy: "Välj studietakt",
      hourWeek: "Ange timmar per vecka",
      instituteName: "Ange skolans namn",
      upload: "Ladda upp ditt studiebevis",
      proof:
        "studiebeviset kan vara antingen ett antagningsbevis, ett CSN-kort eller något liknande",
      frelencer: "Jag är egen företagare/frilansare",
      refired: "Jag är pensionär",
      primaryOccupation:
        "Jag har en annan huvudsaklig sysselsättning på minst 50%",
      Specify: "Välj",
      SpecifyOthersInfo: "Ange",
    },
    workExperience: {
      workExperience: [
        {
          id: "01",
          name: "Sjuksköterska",
        },
        {
          id: "02",
          name: "Undersköterska",
        },
        {
          id: "03",
          name: "Vårdbiträde",
        },
        {
          id: "04",
          name: "Nanny",
        },
        {
          id: "05",
          name: "Barnomsorg/förskola",
        },
        {
          id: "06",
          name: "Förskolelärare",
        },
      ],
      add: "Lägg till",
      submit: "Spara",
      experience: "Jag har erfarenhet av att arbeta med barn",
      duration: "Varaktighet:",
      Roletitle: "Titel:",
      responsibilities: "Arbetsbeskrivning: (max 150 ord)",
      experience2: "Jag har erfarenhet av att arbeta med äldre människor",
      experience3: "Jag har studier inom vård/barnomsorg",
      experience4: "Jag har annan arbetslivserfarenhet",
    },
    education: {
      stateEducation: "Beskriv din utbildning:",
      NameInstite: "Ange namn på skolan",
      from: "Från",
      to: "Till",
    },
    availiablity: {
      select_hours_week: [
        {
          id: "01",
          name: "30+ timmar per vecka",
        },
        {
          id: "01",
          name: "15-30 timmar per vecka",
        },
        {
          id: "03",
          name: "2-15 timmar per vecka",
        },
        {
          id: "04",
          name: "Flexibel, öppen för alla av ovanstående alternativ",
        },
      ],
      availibilityofwork: [
        {
          id: "01",
          name: "Förmiddag",
        },
        {
          id: "02",
          name: "Eftermiddag",
        },
        {
          id: "03",
          name: "Kvällar",
        },
        {
          id: "04",
          name: "Helger",
        },
        {
          id: "05",
          name: "Flexibel, öppen för alla av ovanstående alternativ",
        },
        {
          id: "06",
          name: "Inte flexibelt– Mitt schema ändras varje vecka",
        },
      ],
      date: "Datum",
      workingTime: [
        {
          id: "01",
          name: "Så snart som möjligt",
        },
        {
          id: "01",
          name: "Ange datum",
        },
      ],
      item1: "Jag kan börja arbeta",
      item2: "Jag är tillgänglig för att arbeta",
      hourWeek: "Välj timmar/vecka",
      item3:
        "Jag kan förbinda mig att vara tillgänglig för arbete i minst 6 månader",
    },
    language: {
      swedish: "Svenska:",
      english: "Engelska:",
      other: "Annat språk:",
      Native: "Modersmål",
      Fluent: "Flytande",
      Proficient: "Mycket goda kunskaper",
      Intermediate: "Goda kunskaper",
      Beginner: "Grundläggande kunskaper",
      Cancel: "Stäng",
      Submit: "Spara",
    },
    othersInfo: {
      platforms: [
        {
          id: "01",
          name: "Arbetsförmedlingen",
        },
        {
          id: "02",
          name: "Marknadsföringsevenemang",
        },
        {
          id: "03",
          name: "Blev tipsad",
        },
        {
          id: "04",
          name: "Jobbannons",
        },
        {
          id: "05",
          name: "Instagram",
        },
        {
          id: "06",
          name: "Facebook",
        },
        {
          id: "07",
          name: "Linkedin",
        },
        {
          id: "08",
          name: "Google",
        },
        {
          id: "09",
          name: "Tiktok",
        },
      ],
      item1: "Jag har ett giltigt utdrag ur belastningsregister",
      clearancecertificate: "Ladda up filen*",
      item1No:
        "Innan du påbörjar din anställning hos DoCare måste du visa upp ett gilltigt utdrag ur belastningsregistret.  För mer information och för att beställa en kopia, besök",
      url: "https://polisen.se/tjanster-tillstand/belastningsregistret/skola-eller-forskola/",
      item1Nopart2:
        "Kom ihåg att maila oss en kopia på ditt utdrag ur belastningsregistret när du har fått det, då du inte kan börja jobba utan det.",
      item2: "Jag godkänner en kredit- och bakgrundskontroll ",
      item3: "Jag har tillstånd att arbeta i Sverige",
      Select: "Välj",
      EU: [
        {
          id: "01",
          name: "Jag är EU-medborgare",
        },
        {
          id: "02",
          name: "Jag är inte EU-medborgare",
        },
      ],

      EUCertificate: "Ladda upp fotosidan av ditt pass",
      nonEUCertificate:
        "Ladda upp en kopia av ditt uppehålls- och arbetstillståndskort",
      NoSweden:
        "För att arbeta i Sverige behöver du ett giltigt arbetstillstånd. För mer information, besök ",
      maigrationURL: "https://www.migrationsverket.se",
      item4: "Jag har ett giltigt körkort",
      item5: "Jag har pälsdjursallergi",
      item6:
        "Jag är villig att ta på mig ytterligare arbetsuppgifter vid arbete med Äldre om det behövs, som att hjälpa till med personlig hygien, duscha och hjälpa till att använda toaletten.",
      item7: "Hur fick du veta om DoCare?",
    },
  },
};

export default swedish;
