import React, { ReactElement } from "react";
import { Footer, PageWrapper, Movetop } from "../../../components";
import { FirstNewsContainer, SecondNewsWrapper } from "./styledComponents";
import { ImageBackgroundReducer, Title } from "../../styledComponents";
import { TextWrapper } from "../../Prices/seniors/styledcomponents";
import NewsCard from "./CardWrapper/cardwrapper";
import {
  ColumnWrapper,
  RowWrapper,
} from "../../Services/subSections/styledcomponents";

const News = (): ReactElement => {
  Movetop();
  return (
    <PageWrapper>
      <FirstNewsContainer>
        <ImageBackgroundReducer />
        <TextWrapper>
          <Title
            fontFamily="Playfair Display, serif"
            fontWeight={400}
            font="58px"
            color="#ffffff"
            fontStyle="normal"
          >
            News
          </Title>
        </TextWrapper>
      </FirstNewsContainer>
      <SecondNewsWrapper>
        <RowWrapper>
          <ColumnWrapper lg={6}>
            <NewsCard />
          </ColumnWrapper>
          <ColumnWrapper lg={6}>
            <NewsCard />
          </ColumnWrapper>
        </RowWrapper>
      </SecondNewsWrapper>
      <SecondNewsWrapper>
        <RowWrapper>
          <ColumnWrapper lg={6}>
            <NewsCard />
          </ColumnWrapper>
          <ColumnWrapper lg={6}>
            <NewsCard />
          </ColumnWrapper>
        </RowWrapper>
      </SecondNewsWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default News;
