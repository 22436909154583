import { Button, Col, FormControl, Row } from "react-bootstrap";
import styled from "styled-components";

export const FooterContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
`;
export const FooterFirstWrapper = styled(Row)`
  width: 70%;
`;
export const FooterSecondWrapper = styled(Row)`
  width: 40%;
  position: relative;
  z-index: 11;
`;

export const FooterColumn = styled(Col)`
  position: relative;
  background: #242864;
  padding: 4% 8.5%;
  width: 100%;
  span {
    cursor: pointer;
    &:hover {
      color: #ff4880;
      transition: ease 0.4s;
    }
  }
`;
export const FooterColumn1 = styled(Col)`
  background: #22255f;
  width: 100%;
`;

export const FooterColumnOneContainer = styled.div`
  margin: 7.5% 30px;
`;

export const FormInput = styled(FormControl)`
  border: unset;
  border-bottom: 1px solid #ffffff33;
  border-radius: 0;
  padding: 10px 50px 10px 0px;
  background: transparent;
  color: white;
  caret-color: white;
  .form-input {
    color: white;
  }
  &::placeholder {
    background: transparent;
    color: white;
    font-family: Lato, sans-serif;
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
  }
  &:focus {
    background: transparent;
    box-shadow: none;
    color: white;
    border-bottom: 1px solid #ffffff33;
  }
`;

export const FooterBottom = styled.footer`
  background: #1d2056;
  color: white;
  font-size: 15px;
  padding: 20px 0;
`;

export const FooterBottomContainer = styled.footer`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: "Lato", sans-serif;
  justify-content: space-between;
  padding: 0 60px;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .copy {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.2px;
  }
  a {
    color: white;
    font-size: 14px;
    &:hover {
      color: #ff4880;
    }
  }
`;

export const FooterColomnWrappper = styled(Col)`
  background: #242864;
  padding: 0 8.5%;
  padding-bottom: 3%;
  position: relative;
  width: 100%;
  span {
    cursor: pointer;
    &:hover {
      color: #ff4880;
      transition: ease 0.4s;
    }
  }
`;

export const IconWrapper = styled.div`
  a {
    text-decoration: none;
  }
  i {
    width: 30px;
    height: 30px;
    color: white;
    display: inline-flex;
    border-radius: 50%;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 30px;
    margin-right: 10px;
    &:hover {
      background-color: #ff4880;
      border: none;
      transition: all 0.5s ease;
      color: white;
    }
  }
`;

export const EmailButton = styled(Button)`
  background-color: #ff4880;
  padding: 10px;
  font-size: 13px;
  border: none;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
