import { ReactElement } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Contact,
  HomePage,
  PricesFamiles,
  PricesSeniors,
  WorkDeatils,
  PricesCompanies,
  FAQ,
  News,
  Aboutus,
  TeamMember,
} from "./pages";
import { Navbar } from "./components";
import styled, { createGlobalStyle } from "styled-components";
import PrivacyPolicy from "./pages/policies/privacy";

export const Body = styled.div`
  position: relative;
  top: 80px;
`;

export const GlobalStyle = createGlobalStyle` 
body {
    font-family: "Lato", sans-serif;
    font-family: "Playfair Display", serif;
}
a {
    text-decoration: none;
}
.accordion-button:not(.collapsed) {
    background: transparent;  
}
.react-datepicker__input-container {
  &::placeholder{

  font-family: "Playfair Display", serif;
  } 
}.jNXnIY .ida{
    width: 115%;
}
`;

const App = (): ReactElement => {
  const location = useLocation();

  const hideHeader = location.pathname === "/privacy-policy";

  return (
    <>
      <GlobalStyle />
      {!hideHeader && <Navbar />}

      <Body>
        <Routes>
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/workwithus"} element={<WorkDeatils />} />
          <Route path={"/contact"} element={<Contact />} />
          <Route path={"/pricesSeniors"} element={<PricesSeniors />} />
          <Route path={"/pricesFamilies"} element={<PricesFamiles />} />
          <Route path={"/pricesCompanies"} element={<PricesCompanies />} />
          <Route path={"/moreFaq"} element={<FAQ />} />
          <Route path={"/moreNews"} element={<News />} />
          <Route path={"/about-us"} element={<Aboutus />} />
          <Route path={"/team-member-details"} element={<TeamMember />} />
          <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
        </Routes>
      </Body>
    </>
  );
};

export default App;
