import styled from "styled-components";
import HeaderImage from "../../../assests/prices/seniors/banner.png";
import Slider from "react-slick";
import { Col, Row } from "react-bootstrap";
import BackgrounDImages from "../../../assests/prices/process-arrow.png";
import family_banner from "../../../assests/prices/families/banner.png";

export const FirstServiceSection = styled.section`
  background-image: url(${HeaderImage});
  width: 100%;
  height: 250px;
  position: relative;
  background-position: 10% 20%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
`;

export const FamiliesFirstServiceSection = styled.section`
  background-image: url(${family_banner});
  width: 100%;
  height: 250px;
  position: relative;
  background-position: 10% 20%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
`;

export const TextWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  padding: 67px 0 62px;
`;

export const CardSlider = styled(Slider)`
  height: auto;
  width: 100%;
  .slick-prev {
    left: 19px;
    height: 128px;
    width: 30px;
    background-color: #ff4880;
  }
  .slick-next {
    right: 20px;
    background-color: black;
    height: 128px;
    width: 30px;
    background-color: #ff4880;
  }
`;

//
//ImageViewer
//

export const ImageRow = styled(Row)`
  position: relative;
  &::before {
    content: url(${BackgrounDImages});
    position: absolute;
    width: 100%;
    height: 100%;
    top: 6%;
    left: -70px;
  }
`;

export const ImageColoumn = styled(Col)``;

export const ImageContainer = styled.div<any>`
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  &:hover {
    transform: translateY(10px);
    transition: all 0.3s ease;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImageBorder = styled.div<{
  borderRadius?: string;
  rotate?: string;
  poly?: string;
}>`
  display: inline-block;
  justify-content: center;
  transform: ${({ rotate }) => (rotate ? rotate : "rotate(-11deg)")};
  outline: 2px solid #dedede;
  background-color: #ffffff;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : null)};
  padding: 10px;
`;

export const PolyImageWarper = styled.div`
  position: relative;
  width: 266px;
  height: 266px;
  &:before {
    position: absolute;
    content: "";
    width: 266px;
    height: 266px;
    left: 0;
    top: 0;
    background-color: #dedede;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    z-index: 1;
    overflow: visible;
  }
  &::after {
    position: absolute;
    content: "";
    width: 262px;
    height: 262px;
    left: 2px;
    top: 3px;
    background-color: white;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    z-index: 2;
    overflow: visible;
  }
`;

export const PolyImage = styled.img`
  position: absolute;
  z-index: 3;
  width: 200px;
  height: 196px;
  left: 31px;
  object-fit: cover;
  object-position: -7px;
  top: 46px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
`;

export const TextParaWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  text-align: center;
`;

export const AdWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #f54881;
  color: white;
  padding: 10px;
  height: 150px;
  display: flex;
  flex-direction: column;
  width: 150px;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  @media (max-width: 850px) {
    position: relative;
    top: 0px;
    margin-bottom: 5px;
  }
`;

export const Adtext = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 195px;
  z-index: 1111;
  right: 0;
  background: #242864;
  width: 360px;
  padding: 10px;
  height: 130px;
  font-size: 14px;
  color: white;
  border-radius: 1%;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;
