import { configureStore } from "@reduxjs/toolkit";
import servicesReducer from "./services/reducers";
import languageToggle from "./Language/reducer";

const reducer = {
  services: servicesReducer,
  language: languageToggle,
};

const initialState = {};

export const store = configureStore({
  reducer: reducer,
  preloadedState: initialState,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
