import {
  CardWrapper,
  PriceWrapper,
  PriceTextWrapper,
  PriceNumberWrapper,
  Lists,
  CardBadge,
} from "./styledComponents";
import { Images, Title, TitleWrapper } from "../../pages/styledComponents";
import { OrderListWrapper } from "../../pages/Services/subSections/styledcomponents";
import { CardProps } from "./types";
import { StyledButton } from "..";
import { useNavigate } from "react-router";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../state/store";

const SeniorCardContainer = ({
  image,
  title,
  lists = [],
  timing,
  buttonName,
  perHour,
}: CardProps) => {
  const navigate = useNavigate();
  const { selectedLang } = useSelector(
    (state: RootState) => ({
      selectedLang: state.language.selectedLang,
    }),
    shallowEqual
  );
  return (
    <CardWrapper isMarginBottom margin="0 5px">
      <Images src={image} width="100%" height="auto" noRadius />
      {title === "VIP" && (
        <CardBadge>
          {selectedLang === "EN" ? "Popular!" : " Populärast!"}
        </CardBadge>
      )}
      <PriceWrapper>
        <PriceNumberWrapper>
          <span>
            {timing} <span className="symbol"> kr*</span>{" "}
          </span>
        </PriceNumberWrapper>
        <span className="text">{perHour}</span>
      </PriceWrapper>
      <PriceTextWrapper>
        <TitleWrapper>
          <Title
            font="25px"
            lineHeight="24px"
            fontWeight={400}
            fontFamily="'Playfair Display', serif"
            color="#242864"
          >
            {title}
          </Title>
        </TitleWrapper>
        <OrderListWrapper>
          {lists?.map((list, index) => {
            return (
              <div key={index}>
                <Lists noBorder={list?.border}>{list?.text1}</Lists>
                <Lists noBorder={list?.border}>{list?.text2}</Lists>
                <Lists noBorder={list?.border}>{list?.text3}</Lists>
                <Lists noBorder={list?.border}>{list?.text4}</Lists>
              </div>
            );
          })}
        </OrderListWrapper>
        <div
          style={{
            padding: "15px 0 40px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledButton
            onClick={() => {
              navigate("/contact");
            }}
          >
            {buttonName}
          </StyledButton>
        </div>
      </PriceTextWrapper>
    </CardWrapper>
  );
};

export default SeniorCardContainer;
