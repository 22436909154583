import { Images, Subtitles, Title } from "../../styledComponents";
import Families from "../../../assests/Home/families.jpg";
import Companies from "../../../assests/Home/companies.jpg";
import Seniors from "../../../assests/Home/seniors.jpg";
import {
  ColumnWrapper,
  ColumnWrapper1,
  OrderList,
  OrderListWrapper,
  RowWrapper,
  SectionWrapper,
  TextWrapper,
} from "./styledcomponents";
import { SectionContainers } from "../styledcomponents";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../state/store";
import { useNavigate } from "react-router";

export const SectionButtonWrapperOne = () => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  const { h1, h2, h3, h4, h5, h6, h7, h8, h9, h10 } = language.forseniors;
  const navigate = useNavigate();
  return (
    <SectionWrapper>
      <RowWrapper noMargin>
        <ColumnWrapper lg={6}>
          <Images src={Seniors} alt="" width={`100%`} height="auto" />
        </ColumnWrapper>
        <ColumnWrapper lg={6}>
          <TextWrapper>
            <Title font="36px" color="#242864">
              {h1}
            </Title>
            <Subtitles
              fontFamily="Lato, sans-serif"
              fontWeight={400}
              font="15px"
              color="#686976"
              lineHeight="26px"
              fontStyle="normal"
              className="mt-3"
            >
              {h2}
            </Subtitles>
          </TextWrapper>
          <RowWrapper noMargin padding={"0"}>
            <ColumnWrapper>
              <OrderListWrapper
                fontFamily="Lato, sans-serif"
                color="#242864"
                font="15px"
                fontWeight="600"
              >
                <OrderList>{h3}</OrderList>
                <OrderList>{h4}</OrderList>
                <OrderList>{h5}</OrderList>
              </OrderListWrapper>
            </ColumnWrapper>
            <ColumnWrapper lg={6}>
              <OrderListWrapper
                fontFamily="Lato, sans-serif"
                color="#242864"
                font="15px"
                fontWeight="600"
              >
                <OrderList>{h6}</OrderList>
                <OrderList>{h7}</OrderList>
                <OrderList>{h8}</OrderList>
              </OrderListWrapper>
            </ColumnWrapper>
          </RowWrapper>
        </ColumnWrapper>
      </RowWrapper>
      <SectionContainers>
        {h9}
        <br />
        <span
          style={{ color: "#4ca8e6", cursor: "pointer" }}
          onClick={() => {
            navigate("/pricesSeniors");
          }}
        >
          {h10}
        </span>
      </SectionContainers>
    </SectionWrapper>
  );
};

export const SectionButtonWrapperTwo = () => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  const { h9, h10 } = language.forseniors;
  const { h11, h12, h13, h14, h15, h16, h17, h18 } = language.forfamilies;
  const navigate = useNavigate();
  return (
    <SectionWrapper>
      <RowWrapper>
        <ColumnWrapper md={6}>
          <TextWrapper>
            <Title font="36px" color="#242864">
              {h11}
            </Title>
            <Subtitles
              fontFamily="Lato, sans-serif"
              fontWeight={400}
              font="15px"
              color="#686976"
              lineHeight="26px"
              fontStyle="normal"
              className="mt-3"
            >
              {h12}
            </Subtitles>
            <RowWrapper>
              <ColumnWrapper>
                <OrderListWrapper
                  fontFamily="Lato, sans-serif"
                  color="#242864"
                  font="15px"
                  fontWeight="600"
                  marginLeft="-23px"
                >
                  <OrderList> {h13}</OrderList>
                  <OrderList>{h14}</OrderList>
                  <OrderList style={{}}>{h16}</OrderList>
                </OrderListWrapper>
              </ColumnWrapper>
              <ColumnWrapper1>
                <OrderListWrapper
                  fontFamily="Lato, sans-serif"
                  color="#242864"
                  font="15px"
                  fontWeight="600"
                  marginLeft="-23px"
                >
                  <OrderList>{h15}</OrderList>
                  <OrderList>
                    {" "}
                    <div>—</div>
                    {""} {h18}
                  </OrderList>{" "}
                  <OrderList>
                    <div>—</div>
                    {""}
                    {h17}
                  </OrderList>
                </OrderListWrapper>
              </ColumnWrapper1>
            </RowWrapper>
          </TextWrapper>
        </ColumnWrapper>
        <ColumnWrapper1 md={6}>
          <Images src={Families} alt="" width={`98%`} height="auto" />
        </ColumnWrapper1>
      </RowWrapper>{" "}
      <SectionContainers>
        {h9}
        <br />
        <span
          style={{ color: "#4ca8e6", cursor: "pointer" }}
          onClick={() => {
            navigate("/pricesFamilies");
          }}
        >
          {h10}
        </span>
      </SectionContainers>
    </SectionWrapper>
  );
};
export const SectionButtonWrapperThree = () => {
  const {
    language: { language },
  } = useSelector(
    (state: RootState) => ({
      language: state.language,
    }),
    shallowEqual
  );
  const { h9, h10 } = language.forseniors;
  const { h21, h22, h23, h24, h25, h26 } = language.forcompanies;
  const navigate = useNavigate();
  return (
    <SectionWrapper>
      <RowWrapper>
        <ColumnWrapper lg={6}>
          <Images src={Companies} alt="" width={`100%`} height="auto" />
        </ColumnWrapper>
        <ColumnWrapper1 lg={6}>
          <TextWrapper>
            <Title font="36px" color="#242864">
              {h21}
            </Title>
            <Subtitles
              fontFamily="Lato, sans-serif"
              fontWeight={400}
              font="15px"
              color="#686976"
              lineHeight="26px"
              fontStyle="normal"
              className="mt-3"
            >
              {h22}
            </Subtitles>
            <RowWrapper noMargin>
              <ColumnWrapper>
                <OrderListWrapper
                  fontFamily="Lato, sans-serif"
                  color="#242864"
                  font="15px"
                  fontWeight="600"
                >
                  <OrderList>{h23}</OrderList>
                  <OrderList> {h24}</OrderList>
                </OrderListWrapper>
              </ColumnWrapper>
              <ColumnWrapper1>
                <OrderListWrapper
                  fontFamily="Lato, sans-serif"
                  color="#242864"
                  font="15px"
                  fontWeight="600"
                  paddingLeft="1px"
                >
                  <OrderList>{h25}</OrderList>

                  <OrderList> {h26}</OrderList>
                </OrderListWrapper>
              </ColumnWrapper1>
            </RowWrapper>
          </TextWrapper>
        </ColumnWrapper1>
      </RowWrapper>{" "}
      <SectionContainers>
        {h9}
        <br />
        <span
          style={{ color: "#4ca8e6", cursor: "pointer" }}
          onClick={() => {
            navigate("/pricesCompanies");
          }}
        >
          {h10}
        </span>
      </SectionContainers>
    </SectionWrapper>
  );
};
