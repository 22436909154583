import styled from "styled-components";
import { colors } from "../../const/theme";
import fonts from "../../const/fonts";
import { TextField } from "@mui/material";
import { Button } from "react-bootstrap";

export const WorkwithusBaner = styled.div`
  width: 100%;
  height: 225px;
  position: relative;
  background-color: #242864;

  @media (max-width: 600px) {
    padding-top: 50px;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 6px 12px 10px 10px;
`;

export const TitleCard = styled.div`
  padding-right: 10px;
  margin: 7px 0px 0px 0px;
  color: ${({ color }) => (color ? color : colors.darkBlue)};
  font-weight: 500;
  font-size: ${fonts.small}px;
  &.error {
    color: #ff0000; /* Red text color */
  }
`;

export const FileUploader = styled(TextField)`
  font-family: "Playfair Display", serif;
  font-size: 15px !important;
  color: #002c99 !important;
  height: 70px;

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    color: ${colors.darkBlue} !important;
    font-family: "Playfair Display", serif;
    height: 30px;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
  .css-1qxw4jt-MuiInputBase-root-MuiOutlinedInput-root {
    color: #002c99 !important;
    font-family: "Playfair Display", serif;
    border-radius: 8px;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    &:hover {
      border: none;
    }
  }
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 30px;
    color: red;
  }
`;

export const AdditionButton = styled(Button)`
  width: auto;
  height: 30px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
`;

export const RatingBoxWrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;

  position: relative;
  width: ${({ width }) => (width ? width : "50%")};
  margin-top: 1%;
  margin-left: 10%;
`;
