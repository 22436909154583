export const YesorNo = [
  {
    id: "01",
    name: "Yes",
  },
  {
    id: "02",
    name: "No",
  },
];

export const areaofStudy = [
  {
    id: "01",
    name: "Full Time",
  },
  {
    id: "02",
    name: "Part time",
  },
];

export const workExperience = [
  {
    id: "01",
    name: "Nurse",
  },
  {
    id: "02",
    name: "Assistant nurse",
  },
  {
    id: "03",
    name: "Elderly care assistant",
  },
  {
    id: "04",
    name: "Nanny",
  },
  {
    id: "05",
    name: "Child care/preschool",
  },
  {
    id: "06",
    name: "Preschool teacher",
  },
];

export const workingTime = [
  {
    id: "01",
    name: "As soon as possible",
  },
  {
    id: "01",
    name: "Specify date",
  },
];

export const workSweden = [
  {
    id: "01",
    name: "I am an EU citizen",
  },
  {
    id: "02",
    name: "I am a non-EU citizen",
  },
];

export const platforms = [
  {
    id: "01",
    name: "Arbetsförmedlingen",
  },
  {
    id: "02",
    name: "Marketing event",
  },
  {
    id: "03",
    name: "Word of mouth",
  },
  {
    id: "04",
    name: "Job posting",
  },
  {
    id: "05",
    name: "Instagram",
  },
  {
    id: "06",
    name: "Facebook",
  },
  {
    id: "07",
    name: "Linkedin",
  },
  {
    id: "08",
    name: "Google",
  },
  {
    id: "09",
    name: "Tiktok",
  },
];

export const availibilityofwork = [
  {
    id: "01",
    name: "Before noon",
  },
  {
    id: "02",
    name: "After noon",
  },
  {
    id: "03",
    name: "Evenings",
  },
  {
    id: "04",
    name: "Weekends",
  },
  {
    id: "05",
    name: "Flexible, open to any of the above alternatives",
  },
  {
    id: "06",
    name: "Not Flexible - My schedule changes every week",
  },
];

export const select_hours_week = [
  {
    id: "01",
    name: "30+ hours per week",
  },
  {
    id: "01",
    name: "15 -30 hours per week",
  },
  {
    id: "03",
    name: "2-15 hours per week",
  },
  {
    id: "04",
    name: "Flexible, open to any of the above alternatives",
  },
];

export const citizinType = [
  {
    id: "01",
    name: "I am an EU citizen",
  },
  {
    id: "02",
    name: "I am a non-EU citizen",
  },
];
