import React, { ReactElement } from "react";
import { FlexWrapper, DropdownWrapper, FormData } from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { updateWorkWithInformation } from "../../../state/services/actions";

const OtherDetials = (): ReactElement => {
  const { values, language } = useSelector(
    (state: RootState) => ({
      values: state.services.workWithDetails,
      language: state.language.language,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const {
    platforms,
    item1,
    item1No,
    url,
    item1Nopart2,
    item2,
    item3,
    NoSweden,
    maigrationURL,
    item4,
    item5,
    item6,
    item7,
  } = language.workwithus.othersInfo;

  const { SpecifyOthersInfo } = language.workwithus.personalDetails;

  const { YesOrNo, i } = language.workwithus;

  var query550 = window.matchMedia("(max-width: 550px)");

  console.log(query550?.matches, "query550");

  return (
    <FlexWrapper noMargin justifyContent="space-between">
      <DropdownWrapper width="45%">
        <FormData
          value={values?.i_have__police_clearance_certificate}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                i_have__police_clearance_certificate: value,
              })
            );
          }}
          label={item1}
          isRequired
          dropdownList={YesOrNo}
        />
        {values?.i_have__police_clearance_certificate === YesOrNo[1]?.name && (
          <div style={{ fontSize: "13px", fontWeight: 500 }} className="mt-3">
            {item1No}
            <a href={url}>{url}</a>
            {item1Nopart2}
          </div>
        )}
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.i_agree_to_credit_and_background_check}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                i_agree_to_credit_and_background_check: value,
              })
            );
          }}
          tooltip={i}
          label={item2}
          isRequired
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.right_to_work_Sweden}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                right_to_work_Sweden: value,
              })
            );
          }}
          label={item3}
          isRequired
          dropdownList={YesOrNo}
        />{" "}
        {values?.right_to_work_Sweden === YesOrNo[1]?.name && (
          <div style={{ fontSize: "13px", fontWeight: 500 }} className="mt-3">
            {NoSweden}
            <a href={maigrationURL}>{maigrationURL}</a>
          </div>
        )}
      </DropdownWrapper>{" "}
      <DropdownWrapper width="45%">
        <FormData
          value={values?.valid_driver_licence}
          onChange={(value: string) => {
            dispatch(
              updateWorkWithInformation({
                valid_driver_licence: value,
              })
            );
          }}
          label={item4}
          isRequired
          dropdownList={YesOrNo}
        />
      </DropdownWrapper>{" "}
      <>
        <DropdownWrapper width="45%">
          <FormData
            value={values?.allergic_to_animals}
            onChange={(value: string) => {
              dispatch(
                updateWorkWithInformation({
                  allergic_to_animals: value,
                })
              );
            }}
            label={item5}
            isRequired
            dropdownList={YesOrNo}
          />
        </DropdownWrapper>{" "}
        {values?.allergic_to_animals === YesOrNo[0]?.name && (
          <DropdownWrapper width="45%">
            <FormData
              value={values?.allergy_details}
              onChange={(value: string) => {
                dispatch(
                  updateWorkWithInformation({
                    allergy_details: value,
                  })
                );
              }}
              label={SpecifyOthersInfo}
              isRequired
            />
          </DropdownWrapper>
        )}
        <DropdownWrapper width="45%">
          <FormData
            value={values?.caregiving_duties}
            onChange={(value: string) => {
              dispatch(
                updateWorkWithInformation({
                  caregiving_duties: value,
                })
              );
            }}
            label={item6}
            dropdownList={YesOrNo}
          />
        </DropdownWrapper>{" "}
        <DropdownWrapper
          width="45%"
          style={query550?.matches ? { marginTop: "95px" } : { marginTop: "0" }}
        >
          <FormData
            value={values?.how_do_you_hear_about_us}
            onChange={(value: string) => {
              dispatch(
                updateWorkWithInformation({
                  how_do_you_hear_about_us: value,
                })
              );
            }}
            label={item7}
            isRequired
            dropdownList={platforms}
          />
        </DropdownWrapper>{" "}
      </>
    </FlexWrapper>
  );
};

export default OtherDetials;
