import React, { ReactElement } from "react";
import {
  FirstCardWrapper,
  FirstCardContainer,
  MemberCardSliderWrapper,
  SlideImageWrapper,
} from "./subcomponents";
import { Images, Subtitles, Title, TitleWrapper } from "../../styledComponents";
import { CardProsp } from "./types";
import { useNavigate } from "react-router";
import { GetTestimonialResponse } from "../../../state/services/types";

const AboutusCard = ({ icon, subTitle, title }: CardProsp): ReactElement => {
  return (
    <FirstCardWrapper>
      <FirstCardContainer>
        <div className="background"></div>
        <div className="icon">
          <i className={icon}></i>
        </div>
        <div style={{ width: "1500px" }}>
          <TitleWrapper paddingLeft="60px" margin="0">
            <Title font="24px" fontWeight={400} lineHeight="34px" color="#fff">
              {title}
            </Title>
            <Subtitles
              font="16px"
              fontWeight={400}
              fontFamily="Lato, sans-serif"
              color="#fff"
              letterSpacing="0.2px"
            >
              {subTitle}
            </Subtitles>
          </TitleWrapper>
        </div>
      </FirstCardContainer>
    </FirstCardWrapper>
  );
};

export default AboutusCard;

interface TeammemberProsp {
  name: string;
  role: string;
  imag: string;
  details: GetTestimonialResponse;
}

export const TeamMemberCard = ({
  name,
  role,
  imag,
  details,
}: TeammemberProsp): ReactElement => {
  const navigate = useNavigate();
  return (
    <MemberCardSliderWrapper>
      {" "}
      <div className="Borderss">
        <div className="Borders"></div>
      </div>
      <SlideImageWrapper>
        <Images
          src={`https://api.dohr.io/${imag}`}
          alt="team_member"
          width="200px"
          height="200px"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            maxWidth: "200px",
            maxHeight: "200px",
          }}
          noRadius
        />
      </SlideImageWrapper>
      <TitleWrapper>
        <Title
          font="22px"
          className="title_borders"
          fontWeight={500}
          lineHeight="30px"
          color="#242864"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/team-member-details", {
              state: {
                memberDetails: details,
              },
            });
          }}
        >
          {name}
        </Title>
        <Subtitles
          font="15px"
          fontWeight={400}
          color="#686976"
          lineHeight="26px"
        >
          {role}
        </Subtitles>
      </TitleWrapper>
    </MemberCardSliderWrapper>
  );
};
